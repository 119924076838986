/**=====================================
    product details pages css start
========================================**/
.sticky-details {
    position: sticky;
    top: 0px;
    left: 0;
}

.product-page-details {
    @media (max-width: 991px) {
        margin-top: 15px;
    }

    .main-title {
        text-transform: capitalize;
        font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.4;

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    .product-rating {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        margin-top: 10px;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .divider {
            color: $round-border;
        }

        .rating-list {
            color: #ffbc37;

            i {
                font-size: 18px;
            }
        }

        a {
            color: var(--theme-color);
            text-decoration: underline;
        }
    }

    .price-text {
        @media (max-width: 767px) {
            text-align: center;
        }

        h3 {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-block: 15px 4px;
            color: var(--theme-color);
            font-weight: 600;
            letter-spacing: 0.01em;
            font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 767px) {
                justify-content: center;
            }

            .discounted-price {
                font-weight: 500;
                padding: 5px calc(12px + 0.0025 * (100vw - 320px));
                // background-color: rgba(236, 137, 81, 0.11);
                border-radius: 0;
                color: var(--theme-color);
                display: inline-block;
                margin-bottom: 0;
                margin-left: 12px;
                font-size: 16px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--theme-color);
                    opacity: 0.11;
                }

                del {
                    font-size: 18px;
                }
            }
        }

        span {
            color: #aaa;
            display: block;
        }
    }

    .size-delivery-info {
        display: flex;
        align-items: center;
        font-size: 16px;
        border-block: 1px dashed $white-dark;
        padding-block: 12px;
        margin-block: 14px 20px;
        gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 767px) {
            justify-content: center;
        }

        a {
            color: #7d7d7d;
            position: relative;
            display: flex;
            align-items: center;

            span {
                position: relative;
                width: 1px;
                height: 26px;
                background-color: $white-dark;
                display: block;
                border-radius: 100%;
            }

            i {
                margin-right: 10px;
            }
        }
    }

    .product-offer {
        li {
            display: flex;
            gap: 10px;
            font-size: 14px;

            &:last-child {
                h5 {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }

            buy-button img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }

            h5 {
                font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        &.delivery-details {
            display: grid;
            gap: 10px;

            li {
                font-weight: 400;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: #626262;

                i {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: #626262;
                }
            }
        }
    }

    .description-text {
        color: #7d7d7d;
        line-height: 1.5;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 8px;
        margin-top: 12px;
    }

    .progress {
        background-color: #f6f6f6;
        height: 10px;
        border-radius: 14px;

        .progress-bar {
            background-image: linear-gradient(to left, #438600, #8fd747);
        }
    }

    .left-progressbar {
        margin-top: 13px;

        h6 {
            color: #7d7d7d;
        }
    }

    .buy-box {
        margin-top: 17px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 767px) {
            justify-content: center;
        }

        a {
            color: #4c4c4c;
        }
    }

    .bordered-box {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px dashed $round-border;
    }

    .dashed-border-box {
        padding: 22px 28px;
        border: 1px dashed $round-border;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        margin-top: 34px;

        h4 {
            margin-top: -30px;
            background-color: $white;
            padding-inline: 8px;
            width: max-content;
        }

        .payment-img {
            width: 300px;
        }
    }

    .shipping-info {
        display: grid;
        line-height: 1.5;
        color: #5f5f5f;
        font-size: 16px;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        padding-left: 20px;
        margin-bottom: -7px;

        @media (max-width: 425px) {
            display: grid;
            grid-template-columns: 1fr;
        }

        li {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            display: list-item;
            color: #7d7d7d;
        }
    }

    .qty-section {
        display: flex;
        align-items: center;
        gap: 10px;

        .qty-box {
            .input-group {
                background-color: #fafafa;
                padding: 6px 6px;
                overflow: hidden;
                border: 1px solid $white-dark;

                input {
                    background-color: #fafafa;
                    border: none;
                    padding: 6px 5px;
                    width: calc(40px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));
                }

                button {
                    padding: 5px calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
                    background-color: #fff !important;
                    box-shadow: 0 0 8px #e8e8e8;
                    border: none;
                }
            }
        }
    }

    .variation-box {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .sub-title {
            margin-bottom: 0;
        }
    }

    .wishlist-btn-group {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
            padding: 0;
            width: 48px;
            height: 48px;
            border: 1px solid $white-dark;
            background-color: #fafafa;
            transition: none;
            font-size: 16px;
            line-height: 1;
            color: #6a6a6a;
        }
    }

    .image-box {
        display: flex;
        align-items: center;
        gap: 10px;

        li {
            border: 1px solid $white-dark;
            padding: 3px;

            img {
                width: 65px;
                cursor: pointer;
                height: 65px;
                padding: 0;
                overflow: hidden;
                object-fit: cover;
            }

            &.active {
                border: 1px solid var(--theme-color);
            }
        }
    }

    .sub-title {
        font-weight: 600;
        margin-bottom: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        letter-spacing: 0.01em;
    }

    .quantity-variant {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #fafafa;
        padding: 6px 6px;
        overflow: hidden;
        border: 1px solid $white-dark;

        li {
            padding: 6px 10px;
            background-color: #fafafa;

            button {
                border: none;
                font-size: 14px;
                font-weight: 500;
                background-color: #fafafa;
            }

            &.active {
                background-color: $white;
                border: 1px solid var(--theme-color);

                button {
                    color: var(--theme-color);
                    background-color: $white;
                }
            }
        }

        &.round {
            li {
                span {
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    display: block;
                    cursor: pointer;
                }
            }
        }

        &.circle {
            li {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
        }

        &.radio {
            .digital-size {
                display: flex;
                align-items: center;

                .form-check {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    min-height: unset;
                    margin-bottom: 0;
                    padding: 0;

                    .form-check-input {
                        width: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
                        margin: 0px;
                        background-color: $white;
                        border: 1px solid $white-dark;
                        filter: unset;
                        border-radius: 100%;

                        &:focus {
                            border-color: transparent;
                            box-shadow: none;
                        }

                        &:checked {
                            position: relative;
                            background-color: $white;
                            border-color: var(--theme-color);
                            float: unset;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 10px;
                                height: 10px;
                                background-color: var(--theme-color);
                                border-radius: 100%;
                            }
                        }
                    }

                    .form-check-label {
                        font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }
        }

        &.select-dropdown {
            &.form-select {
                display: inline-flex;
                width: 150px !important;
            }
        }
    }

    .product-buttons {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            justify-content: center;
        }

        @media (max-width: 576px) {
            justify-content: center;
            flex-wrap: wrap;
        }

        .btn {
            padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            &.btn-sm {
                padding: 13px 18px;
            }

            &.buy-button {
                &:hover {
                    color: $dark-font;
                    background-color: $white;
                    border: 1px solid (--theme-color);
                }
            }

            &.disabled {
                color: $white;

                .ring-animation {
                    animation: none;
                }
            }
        }

        .btn-animation {
            i {
                font-size: 15px;
            }
        }
    }

    .trending-text {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        img {
            width: 30px;
        }

        h5 {
            margin-bottom: 0;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.4;
        }

        @media (max-width: 767px) {
            justify-content: center;
        }
    }

    .top-title-panel {
        display: flex;
        justify-content: space-between;
    }

    &.detail-box {
        padding: 20px;
        border-radius: 8px;
        background-color: $top-header;

        .progress {
            background-color: $white;
        }

        .quantity-variant {
            background-color: white !important;

            li {
                background-color: white !important;

                button {
                    background-color: $white;
                }

                &.active {
                    background-color: #f8f8f8 !important;
                    box-shadow: none;

                    button {
                        background-color: #f8f8f8 !important;
                    }
                }
            }
        }

        .qty-section {
            .qty-box {
                .input-group {
                    background-color: $white;

                    input {
                        background-color: $white;
                    }

                    button {
                        background-color: #f8f8f8 !important;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .product-accordion {
        .accordion-item {
            border: none;
            background-color: transparent;

            +.accordion-item {
                margin-top: 15px;
            }

            .accordion-header {
                margin-bottom: 0;

                .accordion-button {
                    z-index: 0;
                    box-shadow: unset;
                    line-height: 1.3;
                    color: #232323;
                    text-align: center;
                    padding: 12px 18px;
                    text-decoration: unset;
                    text-transform: capitalize;
                    border: 1px solid $white-dark;
                    font-weight: 500;
                    background-color: $top-header;
                    font-size: calc(15px + 3 * (100vw - 320px) / 1600);
                    margin: 0;
                    border-radius: 0;

                    &::after {
                        content: "\ea4e";
                        font-family: remixicon;
                        font-style: normal;
                        -webkit-font-smoothing: antialiased;
                        width: unset;
                        height: unset;
                        background: unset;
                        font-size: 22px;
                        line-height: 1;
                    }
                }
            }

            .accordion-body {
                padding: 15px 0 0;

                p {
                    font-size: 14px;
                    line-height: 1.7;
                    font-weight: 400;
                    color: #7d7d7d;
                }

                .bordered-box {
                    .delivery-details {
                        display: grid;
                        gap: 10px;

                        li {
                            font-weight: 400;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            color: #626262;
                        }
                    }
                }
            }
        }
    }

    &.product-form-box {
        position: sticky;
        top: 20px;
        left: 0;
        text-align: center;
        border: 1px solid $white-dark;
        padding: 20px;

        .product-description {
            .qty-box {
                margin-bottom: 5px;

                .input-group {
                    justify-content: center;
                    width: 100%;
                }
            }
        }

        .product-buttons {
            margin-bottom: 0;
        }

        .timer {
            margin-bottom: 10px;
            text-align: left;
        }
    }
}

.product_image_4 {
    position: sticky;
    top: 120px;
    left: 0;

    >div {
        &:nth-last-child(-n + 2) {
            margin-top: 25px;
        }
    }
}

.secondary-color {
    background-color: grey !important;
}

.blue-color {
    background-color: blue !important;
}

.purple-color {
    background-color: purple !important;
}

.image-grid {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    gap: 10px;
    margin-top: 30px;

    >div {
        &:first-child {
            grid-row: 1 / 3;

            img {
                height: 100%;
            }
        }
    }
}

.product-label {
    background-color: #fdf2ec;
    position: absolute;
    top: 15px;
    z-index: 1;
    left: 15px;
    padding: 5px 12px;
    color: var(--theme-color);
    font-weight: 500;
    font-size: 15px;
}

.sticky-top-custom {
    position: sticky !important;
    top: 30px;
    z-index: 1;
}

.disabled {
    overflow: hidden;
    position: relative;
    user-select: none;
    cursor: default;
    opacity: 0.6 !important;
    pointer-events: none;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        left: 0;
        background-color: #ff7272;
        width: 100%;
        height: 1px;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
        z-index: 1;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: 0;
        background-color: #ff7272;
        width: 100%;
        height: 1px;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
        z-index: 1;
    }
}

//Clock config
.clock {
    border: 2px solid #212529;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: $white;
    position: relative;
    box-sizing: border-box;

    &:before {
        //Minutes
        border-radius: 3px;
        content: "";
        position: absolute;
        transform-origin: 1px 1px;
        transform: translateX(-1px);
        top: (20px / 2) - 3px;
        left: (20px / 2) - 3px;
        margin: 0 0 0 0;
        height: 2px;
        width: (20px / 2.5);
        background: #212529;
        animation-name: clock;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: backward;
        animation-timing-function: linear;
    }

    &:after {
        //Hours
        border-radius: 3px;
        content: "";
        position: absolute;
        transform-origin: 1px 1px;
        transform: translateX(-1px);
        top: (20px / 2) - 3px;
        left: (20px / 2) - 3px;
        margin: 0 0 0 0;
        height: 2px;
        width: (20px / 3.8);
        background: #212529;
        animation-name: clock;
        animation-duration: 2s * 12;
        animation-iteration-count: infinite;
        animation-direction: backward;
        animation-timing-function: linear;
    }
}

.product-slider-image {
    background-color: $top-header;
    border: 1px solid #eee;
}

@keyframes clock {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// product style
// .basic-product {
//     position: relative;
//     transition: all 0.3s ease-in-out;

//     &.sold-out {
//         .img-wrapper {
//             * {
//                 filter: grayscale(100);
//                 pointer-events: none;
//             }
//             .trending-label {
//                 filter: none;
//                 background-color: #d02f47;
//             }
//         }
//     }

//     .progress-section {
//         margin-top: 10px;
//         .progress {
//             margin-bottom: 10px;
//         }
//     }

//     .price {
//         font-size: 18px;
//         color: $dark-font;
//         font-weight: 500;
//         margin-bottom: 0;
//         transition: all 0.3s ease-in-out;
//         gap: 8px;
//         display: flex;
//         align-items: center;
//         .discounted-price {
//             font-weight: 500;
//             border-radius: 5px;
//             color: var(--theme-color);
//             display: inline-block;
//             margin-bottom: 0;
//             font-size: 16px;
//         }
//     }

//     .product-title {
//         line-height: 1;
//         transition: all 0.3s ease-in-out;
//         text-transform: capitalize;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow: hidden;
//         font-size: 18px;
//         color: $dark-font;
//         font-weight: 500;
//         display: block;
//         margin-bottom: 3px;
//     }

//     .img-wrapper {
//         position: relative;
//         overflow: hidden;
//         z-index: 0;
//         background-color: $top-header;
//         .addto-cart-bottom {
//             position: absolute;
//             bottom: -54px;
//             left: 50%;
//             width: calc(80% - 40px);
//             background-color:$white;
//             border: none;
//             padding: 9px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             gap: 10px;
//             font-size: 16px;
//             font-weight: 500;
//             border-radius: 34px;
//             box-shadow: 0px 8px 12px 0px rgba(155, 155, 155, 0.26);
//             transition: all 0.3s ease-in-out;
//             transform: translateX(-50%);
//         }
//         .quick-option {
//             position: absolute;
//             left: 50%;
//             top: 50%;
//             width: 30px;
//             height: 30px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border-radius: 100%;
//             transform: translate(-50%, -50%) scale(0);
//             background-color:$white;
//             color: var(--theme-color);
//             transition: all 0.3s ease-in-out;
//             z-index: 1;
//         }
//         .trending-label {
//             position: absolute;
//             top: 10px;
//             left: 10px;
//             background-color: var(--theme-color);
//             color: $white;
//             padding: 4px 10px;
//             border-radius: 20px;
//             font-weight: 500;
//             margin-bottom: 0;
//             font-size: 12px;
//             z-index: 1;
//             &.level2 {
//                 top: 44px;
//                 background-color: $white;
//                 color: var(--theme-color);
//             }
//         }
//         .rotate-label {
//             position: absolute;
//             top: 30px;
//             left: 0;
//             background-color: var(--theme-color);
//             color: $white;
//             padding: 11px 6px;
//             border-radius: 0;
//             font-weight: 600;
//             margin-bottom: 0;
//             font-size: 12px;
//             writing-mode: tb;
//             transform: rotate(180deg);
//         }
//         .rating-label {
//             position: absolute;
//             bottom: 10px;
//             left: 10px;
//             background-color:$white;
//             padding: 5px 10px;
//             display: flex;
//             align-items: center;
//             gap: 5px;
//             color: #595959;
//             font-weight: 500;
//             border-radius: 4px;
//             i {
//                 color: #ffa200;
//                 margin-top: -2px;
//             }
//         }
//         .unit-label {
//             margin-bottom: 0;
//             background-color:$white;
//             padding: 4px 14px;
//             position: absolute;
//             bottom: 0;
//             font-weight: 400;
//             border-radius: 0 5px 0 0;
//             font-size: 14px;
//         }
//     }

//     .brand-w-color {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin-bottom: 5px;
//         * {
//             margin-bottom: 0 !important;
//         }
//     }

//     .cart-info {
//         position: absolute;
//         bottom: 12px;
//         margin: 0 auto;
//         display: flex;
//         flex-direction: column;
//         right: 12px;
//         justify-content: center;
//         opacity: 0;
//         transition: all 0.3s ease-in-out;
//         gap: 8px;

//         .active {
//             .ri-shopping-cart-line {
//                 color: var(--theme-color);
//                 &::before {
//                     content: "\f11f";
//                 }
//             }
//             .ri-heart-line {
//                 &::before {
//                     content: "\ee0e";
//                 }
//             }
//         }

//         button {
//             background: none;
//             box-shadow: none;
//             border: none;
//             padding: 0;
//         }

//         button,
//         a {
//             width: 35px;
//             height: 34px;
//             border-radius: 100%;
//             background-color:$white;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border: 1px solid $round-border;
//         }

//         i {
//             color: #848484;
//             font-size: 18px;
//             display: block;

//             &:hover {
//                 color: var(--theme-color);
//             }
//         }
//     }

//     .product-detail {
//         margin-top: 15px;

//         .rating {
//             i {
//                 &:nth-child(-n + 4) {
//                     color: $star-yellow;
//                 }

//                 &:last-child {
//                     color: $round-border;
//                 }
//             }
//         }
//     }

//     .ribbon {
//         position: absolute;
//         left: -5px;
//         top: -5px;
//         z-index: 1;
//         overflow: hidden;
//         width: 75px;
//         height: 75px;
//         text-align: right;
//         transition: all 0.3s ease-in-out;
//         span {
//             font-size: 12px;
//             font-weight: bold;
//             color: $white;
//             text-transform: capitalize;
//             text-align: center;
//             line-height: 25px;
//             transform: rotate(-45deg);
//             width: 105px;
//             display: block;
//             background: var(--theme-color);
//             position: absolute;
//             top: 19px;
//             left: -21px;
//         }
//     }

//     .ribbon-round {
//         aspect-ratio: 1;
//         display: grid;
//         place-content: center;
//         text-align: center;
//         z-index: 0;
//         width: fit-content;
//         position: absolute;
//         z-index: 1;
//         top: 10px;
//         left: 10px;
//         padding: 10px;
//         font-size: 14px;
//         font-weight: 700;
//         color:$white;
//         &:after {
//             content: "";
//             position: absolute;
//             z-index: -1;
//             inset: 0;
//             background: radial-gradient(35% 35%, rgba(0, 0, 0, 0) 96%, rgba(0, 0, 0, 0) 97% 99%, rgba(0, 0, 0, 0))
//                 var(--theme-color);
//             clip-path: polygon(
//                 100% 50%,
//                 88.04% 62.36%,
//                 90.45% 79.39%,
//                 73.51% 82.36%,
//                 65.45% 97.55%,
//                 50% 90%,
//                 34.55% 97.55%,
//                 26.49% 82.36%,
//                 9.55% 79.39%,
//                 11.96% 62.36%,
//                 0% 50%,
//                 11.96% 37.64%,
//                 9.55% 20.61%,
//                 26.49% 17.64%,
//                 34.55% 2.45%,
//                 50% 10%,
//                 65.45% 2.45%,
//                 73.51% 17.64%,
//                 90.45% 20.61%,
//                 88.04% 37.64%
//             );
//             animation: animName 20s linear infinite;
//         }
//     }

//     .ribbon-outer {
//         font-size: 14px;
//         font-weight: 500;
//         color: $white;
//     }
//     .ribbon-outer {
//         --f: 0.5em;
//         --r: 0.8em;

//         position: absolute;
//         top: 12px;
//         left: calc(-1 * var(--f));
//         padding-inline: 10px;
//         line-height: 1.8;
//         background: var(--theme-color);
//         border-bottom: var(--f) solid #0005;
//         border-right: var(--r) solid #0000;
//         clip-path: polygon(
//             0 0,
//             0 calc(100% - var(--f)),
//             var(--f) 100%,
//             var(--f) calc(100% - var(--f)),
//             100% calc(100% - var(--f)),
//             calc(100% - var(--r)) calc(50% - var(--f) / 2),
//             100% 0
//         );
//         z-index: 1;
//     }

//     .add-cart-btn {
//         width: 100%;
//         display: block;
//         padding: 10px 10px;
//         text-align: center;
//         border: none;
//         color: #888;
//         font-size: 16px;
//         font-weight: 500;
//         border-radius: 6px;
//         margin-top: 10px;
//         background-color: #f6f6f6;
//     }

//     .rating-w-count {
//         display: flex;
//         align-items: center;
//         gap: 4px;
//         margin-bottom: 8px;
//     }

//     .addtocart_btn {
//         width: max-content;
//         margin: 0 auto;
//         position: relative;

//         .add-button {
//             background-color: $white;
//             color: #000;
//             text-align: center;
//             font-size: 16px;
//             width: max-content;
//             padding: 9px 49px;
//             transition: all 0.3s ease-in-out;
//             cursor: pointer;
//             border: 1px solid #ec8951;
//             border-radius: 6px;
//             margin-top: 15px;
//             color: #ec8951;
//         }

//         .qty-box {
//             position: absolute;
//             bottom: 0;
//             display: none;
//             width: 100%;

//             .input-group {
//                 padding: 4px;
//                 display: flex;
//                 flex-wrap: unset;
//                 align-items: center;
//                 border-radius: 6px;
//                 border: 1px solid var(--theme-color);
//                 background-color:$white;
//                 .form-control {
//                     width: 100%;
//                     border: none;
//                     background-color: transparent;
//                     padding-block: 0;

//                     &:focus {
//                         border-color: $grey3;
//                         box-shadow: none;
//                     }
//                 }

//                 button {
//                     background: rgba(0, 0, 0, 0);
//                     height: 100%;
//                     padding: 10px;
//                     border: 1px solid $white;
//                     border-radius: 4px !important;
//                     background-color: $top-header;
//                 }
//             }

//             &.open {
//                 display: block;
//             }
//         }
//     }

//     .product-action {
//         border-top: 1px solid $white-dark;
//         padding-top: 10px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin-top: 10px;
//         a {
//             font-size: 20px;
//             color: var(--theme-color);
//         }
//         button {
//             border: none;
//             display: flex;
//             align-items: center;
//             gap: 5px;
//             padding: 5px 14px;
//             color: $dark-font;
//             background-color: $top-header;
//             transition: all 0.3s ease-in-out;
//             font-weight: 500;
//         }
//     }

//     &:hover {
//         .cart-info {
//             opacity: 1;
//             transition: all 0.3s ease-in-out;

//             button {
//                 animation: fadeInRight 300ms ease-in-out;
//             }

//             a {
//                 &:nth-child(2) {
//                     animation: fadeInRight 500ms ease-in-out;
//                 }

//                 &:nth-child(3) {
//                     animation: fadeInRight 700ms ease-in-out;
//                 }

//                 &:nth-child(4) {
//                     animation: fadeInRight 1000ms ease-in-out;
//                 }
//             }
//         }

//         .product-action {
//             button {
//                 color: var(--theme-color);
//             }
//         }

//         .img-wrapper {
//             .quick-option {
//                 transform: translate(-50%, -50%) scale(1);
//             }
//             .addto-cart-bottom {
//                 bottom: 16px;
//             }
//         }
//     }

//     &.theme-product-1 {
//         border: 1px solid $white-dark;
//         overflow: hidden;
//         padding: 10px;
//         .product-detail {
//             margin-top: 0;
//             padding: 0;
//             .offer-panel {
//                 padding: 10px 0 0;
//                 margin-top: 0;
//                 color: $dark-font;
//                 font-size: 16px;
//                 display: flex;
//                 align-items: center;
//                 gap: 15px;
//                 white-space: nowrap;
//                 position: relative;
//                 transition: 0.4s ease;
//                 li {
//                     + li {
//                         opacity: 0;
//                         visibility: hidden;
//                     }
//                 }
//                 .offer-icon {
//                     color: #ec8951;
//                     animation: animName 2s linear infinite;
//                     display: inline-block;
//                 }
//             }
//             > div {
//                 padding: 15px 0;
//                 border-bottom: 1px solid $white-dark;
//             }
//         }
//         .cart-info {
//             top: 10px;
//             bottom: unset;
//             opacity: 1;
//             right: 10px;

//             button,
//             a {
//                 width: 32px;
//                 height: 32px;
//                 border: none;
//                 i {
//                     color: var(--theme-color);
//                     font-size: 17px;
//                 }
//             }

//             button {
//                 opacity: 0;
//             }

//             a {
//                 opacity: 0;
//                 &.wishlist-icon {
//                     opacity: 1;
//                 }
//             }
//         }
//         &:hover {
//             .product-detail {
//                 .offer-panel {
//                     animation: marquee-discount 8s linear infinite;
//                     li {
//                         + li {
//                             opacity: 1;
//                             visibility: visible;
//                         }
//                     }
//                 }
//             }
//             .cart-info {
//                 .wishlist-icon {
//                     animation: none;
//                 }

//                 button {
//                     animation: fadeInDown 500ms ease-in-out;
//                     opacity: 1;
//                 }

//                 a {
//                     opacity: 1;

//                     &:nth-child(3) {
//                         animation: fadeInDown 700ms ease-in-out;
//                     }

//                     &:nth-child(4) {
//                         animation: fadeInDown 1000ms ease-in-out;
//                     }
//                 }
//             }
//         }
//     }

//     &.theme-product-2 {
//         margin: 5px 0;
//         padding: 18px;
//         box-shadow: 0px 0px 2px 2px rgba(194, 194, 194, 0.16);
//         border-radius: 15px;
//         background-color:$white;
//         position: relative;

//         .product-detail {
//             margin-bottom: 5px;
//             .details {
//                 li {
//                     font-size: 14px;
//                     font-weight: 400;
//                     color: #777;
//                     line-height: 24px;

//                     + li {
//                         margin-left: 12px;
//                     }
//                 }
//             }

//             .add-wish {
//                 position: absolute;
//                 right: 18px;
//                 top: 18px;
//                 background-color: #f1f1f1;
//                 border-radius: 100%;
//                 width: 28px;
//                 height: 28px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;

//                 a {
//                     line-height: 0;

//                     i {
//                         color: var(--theme-color);
//                     }
//                 }
//             }
//         }

//         .bottom-detail {
//             display: flex;
//             align-items: center;
//             margin-top: 12px;
//             padding-bottom: 6px;

//             .btn {
//                 margin-left: auto;
//             }

//             .color-variant {
//                 margin-bottom: 10px;
//             }
//         }

//         .cart-detail {
//             margin-top: 10px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border-top: 1px solid $white-dark;
//             padding-top: 12px;
//             margin-bottom: -5px;
//             font-size: 16px;

//             li {
//                 padding: 0 10px;

//                 a,
//                 button {
//                     color: rgba(black, 0.5);
//                     text-transform: capitalize;
//                     display: flex;
//                     align-items: center;
//                 }

//                 i {
//                     margin-right: 6px;
//                 }

//                 button {
//                     background-color: transparent;
//                     border: none;
//                     padding: 0;
//                 }

//                 + li {
//                     border-left: 1px solid $white-dark;
//                 }
//             }
//         }

//         .img-wrapper {
//             position: relative;
//             text-align: center;
//             img {
//                 height: 170px;
//                 object-fit: contain;
//                 padding: 10px;
//             }

//             &:after {
//                 content: "";
//                 position: absolute;
//                 left: 0;
//                 top: 0;
//                 width: 100%;
//                 height: 100%;
//                 background-color: rgba(white, 0.77);
//                 opacity: 0;
//                 transition: all 0.3s ease-in-out;
//             }

//             .quick-view-part {
//                 position: absolute;
//                 left: 50%;
//                 top: 50%;
//                 transform: translate(-50%, -50%) scale(0);
//                 background-color: var(--theme-color);
//                 border-radius: 100%;
//                 width: 28px;
//                 height: 28px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 z-index: 1;
//                 box-shadow: 0px 1px 2px #a9a9a9;
//                 transition: all 0.3s ease-in-out;

//                 a {
//                     line-height: 0;
//                     color:$white;

//                     svg {
//                         width: 16px;
//                         height: 16px;
//                     }
//                 }
//             }
//         }

//         &:hover {
//             .img-wrapper {
//                 &:after {
//                     opacity: 1;
//                     transition: all 0.3s ease-in-out;
//                 }

//                 .quick-view-part {
//                     transform: translate(-50%, -50%) scale(1);
//                     transition: all 0.3s ease-in-out;
//                 }
//             }
//         }
//     }

//     &.theme-product-3 {
//         padding: 12px;
//         border: 1px solid $white-dark;
//         border-radius: 8px;
//         .product-title {
//             margin-bottom: 6px;
//         }
//         .cart-info {
//             top: 10px;
//             bottom: unset;
//             opacity: 1;
//             right: 10px;

//             button,
//             a {
//                 width: 32px;
//                 height: 32px;
//                 border: none;
//                 i {
//                     color: var(--theme-color);
//                     font-size: 17px;
//                 }
//             }

//             button {
//                 opacity: 0;
//             }

//             a {
//                 opacity: 0;
//                 &.wishlist-icon {
//                     opacity: 1;
//                 }
//             }
//         }
//         &:hover {
//             .cart-info {
//                 .wishlist-icon {
//                     animation: none;
//                 }

//                 a {
//                     opacity: 1;

//                     &:nth-child(2) {
//                         animation: fadeInDown 700ms ease-in-out;
//                     }

//                     &:nth-child(3) {
//                         animation: fadeInDown 1000ms ease-in-out;
//                     }
//                 }
//             }
//         }
//     }

//     &.theme-product-4 {
//         .color-panel {
//             position: absolute;
//             bottom: 10px;
//             left: 10px;
//         }
//         .price {
//             .discounted-price {
//                 font-weight: 500;
//                 padding: 5px 6px;
//                 background-color: rgba(236, 137, 81, 0.1);
//                 border-radius: 5px;
//                 color: var(--theme-color);
//                 display: inline-block;
//                 margin-bottom: 0;
//                 margin-left: 0;
//                 font-size: 14px;
//             }
//         }
//         .cart-info {
//             top: 10px;
//             bottom: unset;
//             opacity: 1;
//             right: 10px;

//             button,
//             a {
//                 width: 32px;
//                 height: 32px;
//                 border: none;
//                 i {
//                     color: var(--theme-color);
//                     font-size: 17px;
//                 }
//             }

//             button {
//                 opacity: 0;
//             }

//             a {
//                 opacity: 0;
//                 &.wishlist-icon {
//                     opacity: 1;
//                 }
//             }
//         }
//         &:hover {
//             .cart-info {
//                 .wishlist-icon {
//                     animation: none;
//                 }

//                 button {
//                     animation: fadeInDown 500ms ease-in-out;
//                     opacity: 1;
//                 }

//                 a {
//                     opacity: 1;

//                     &:nth-child(3) {
//                         animation: fadeInDown 700ms ease-in-out;
//                     }

//                     &:nth-child(4) {
//                         animation: fadeInDown 1000ms ease-in-out;
//                     }
//                 }
//             }
//         }
//     }

//     &.theme-product-5 {
//         .img-wrapper {
//             border-radius: 0;
//         }
//         .cart-info {
//             background-color:$white;
//             right: -40px;
//             bottom: unset;
//             top: 0;
//             gap: 0;
//             padding: 4px;
//             button,
//             a {
//                 border: none;
//                 border-radius: 0;
//             }
//         }
//         &:hover {
//             .cart-info {
//                 right: 0;
//                 button,
//                 a {
//                     animation: none;
//                 }
//             }
//         }
//     }

//     &.theme-product-6 {
//         padding: 12px;
//         border: 1px solid $white-dark;
//         border-radius: 6px;
//         .product-detail {
//             * {
//                 text-align: center;
//                 justify-content: center;
//             }
//         }
//         .img-wrapper {
//             background-color: $top-header;
//         }
//         .product-title {
//             margin-bottom: 10px;
//             font-size: 16px;
//         }
//         .cart-info {
//             flex-direction: row;
//             left: 50%;
//             right: unset;
//             transform: translate(-50%, -50%) scale(0.7);
//             bottom: unset;
//             top: 50%;
//             background-color:$white;
//             border-radius: 5px;
//             gap: 0;
//             padding: 2px 5px;
//             button,
//             a {
//                 border: none;
//             }
//             a {
//                 position: relative;
//                 + a {
//                     &::after {
//                         content: "";
//                         position: absolute;
//                         width: 1px;
//                         height: 54%;
//                         top: 50%;
//                         left: 0;
//                         background-color: $white-dark;
//                         transform: translateY(-50%);
//                     }
//                 }
//             }
//         }
//         &:hover {
//             .cart-info {
//                 transform: translate(-50%, -50%) scale(1);
//                 a {
//                     animation: none;
//                 }
//             }
//         }
//     }

//     &.theme-product-7 {
//         background-color:$white;
//         border-radius: 6px;
//         box-shadow: 0 0 4px $round-border;
//         .product-detail {
//             margin-top: 0;
//             padding: 14px;
//             position: relative;
//             padding-top: 25px;
//         }
//         .img-wrapper {
//             border-radius: 6px 6px 0 0;
//             .bg-size {
//                 transition: all 0.3s ease-in-out;
//             }
//             &::before {
//                 position: absolute;
//                 left: 0;
//                 top: 0;
//                 width: 100%;
//                 height: 100%;
//                 background-color: rgba(black, 0.2);
//                 z-index: 1;
//             }
//         }
//         .price {
//             display: inline-block;
//             position: absolute;
//             top: -18px;
//             background-color: #ec8951;
//             padding: 7px 9px;
//             color:$white;
//             border-radius: 5px;
//             font-size: 16px;
//         }
//         &:hover {
//             .img-wrapper {
//                 .bg-size {
//                     transform: scale(1.2);
//                 }
//                 &::before {
//                     content: "";
//                 }
//             }
//         }
//     }

//     &.theme-product-8 {
//         background-color:$white;
//         border-radius: 6px;
//         box-shadow: 0 0 4px $round-border;
//         .product-title {
//             margin-bottom: 12px;
//         }
//         .add-round-btn {
//             position: absolute;
//             right: 15px;
//             bottom: 15px;
//             padding: 9px 15px;
//             background-color: var(--theme-color);
//             color:$white;
//             border-radius: 100%;
//             width: 38px;
//             height: 38px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border: none;
//             font-size: 18px;
//         }
//         .product-detail {
//             margin-top: 0;
//             padding: 14px;
//             position: relative;
//         }
//         .img-wrapper {
//             border-radius: 6px 6px 0 0;
//             .bg-size {
//                 transition: all 0.3s ease-in-out;
//             }
//         }
//         .cart-info {
//             top: 10px;
//             bottom: unset;
//             opacity: 1;
//             right: 10px;

//             button,
//             a {
//                 width: 32px;
//                 height: 32px;
//                 border: none;
//                 i {
//                     color: var(--theme-color);
//                     font-size: 17px;
//                 }
//             }

//             button {
//                 opacity: 0;
//             }

//             a {
//                 opacity: 0;
//                 &.wishlist-icon {
//                     opacity: 1;
//                 }
//             }
//         }
//         &:hover {
//             .cart-info {
//                 .wishlist-icon {
//                     animation: none;
//                 }

//                 a {
//                     opacity: 1;

//                     &:nth-child(2) {
//                         animation: fadeInDown 700ms ease-in-out;
//                     }

//                     &:nth-child(3) {
//                         animation: fadeInDown 1000ms ease-in-out;
//                     }
//                 }
//             }
//             .img-wrapper {
//                 .bg-size {
//                     transform: scale(1.2);
//                 }
//             }
//         }
//     }

//     &.theme-product-9 {
//         background-color: $white;
//         border-radius: 6px;
//         box-shadow: 0 0 4px $round-border;
//         .img-wrapper {
//             border-radius: 6px 6px 0 0;
//         }
//         .product-detail {
//             margin-top: 0;
//             padding: 15px;
//         }
//         .bottom-details {
//             display: flex;
//             justify-content: space-between;
//             align-items: flex-end;
//             margin-top: 10px;
//         }
//         .rating-label {
//             display: flex;
//             align-items: center;
//             gap: 6px;
//             span {
//                 color: #aaa;
//             }
//             > div {
//                 background-color: var(--theme-color);
//                 display: flex;
//                 align-items: center;
//                 gap: 4px;
//                 padding: 1px 10px;
//                 border-radius: 13px;
//                 color:$white;
//                 font-size: 14px;
//                 span {
//                     color:$white;
//                 }
//             }
//         }
//         .discount-value {
//             padding-top: 10px;
//             border-top: 1px solid $white-dark;
//             margin-top: 14px;
//             color: $dark-font;
//             font-size: 16px;
//             .offer-icon {
//                 color: #767676;
//                 animation: animName 2s linear infinite;
//                 display: inline-block;
//             }
//         }
//         .price-vertical {
//             text-align: right;
//             h4 {
//                 margin-bottom: 0;
//                 font-weight: 500;
//             }
//         }
//         .cart-info {
//             top: 10px;
//             bottom: unset;
//             opacity: 1;
//             right: 10px;

//             button,
//             a {
//                 width: 32px;
//                 height: 32px;
//                 border: none;
//                 i {
//                     color: var(--theme-color);
//                     font-size: 17px;
//                 }
//             }

//             button {
//                 opacity: 0;
//             }

//             a {
//                 opacity: 0;
//                 &.wishlist-icon {
//                     opacity: 1;
//                 }
//             }
//         }
//         &:hover {
//             .cart-info {
//                 .wishlist-icon {
//                     animation: none;
//                 }

//                 button {
//                     animation: fadeInDown 500ms ease-in-out;
//                     opacity: 1;
//                 }

//                 a {
//                     opacity: 1;

//                     &:nth-child(3) {
//                         animation: fadeInDown 700ms ease-in-out;
//                     }

//                     &:nth-child(4) {
//                         animation: fadeInDown 1000ms ease-in-out;
//                     }
//                 }
//             }
//         }
//     }

//     &.theme-product-10 {
//         .cart-info {
//             bottom: unset;
//             top: 12px;
//         }
//         &:hover {
//             .cart-info {
//                 a {
//                     &:first-child {
//                         animation: fadeInRight 300ms ease-in-out;
//                     }
//                 }
//             }
//         }
//     }

//     &.theme-product-11 {
//         padding: 10px;
//         border-radius: 10px;
//         overflow: hidden;
//         border: 1px solid $round-border;
//         .img-wrapper {
//             .bg-size {
//                 transition: all 0.3s ease-in-out;
//             }
//         }
//         .product-detail {
//             text-align: center;
//             overflow: hidden;
//             margin-top: 10px;
//             * {
//                 justify-content: center;
//             }
//         }
//         .cart-info {
//             top: 12px;
//             bottom: unset;
//         }
//         .abs-product {
//             position: absolute;
//             bottom: -62%;
//             width: calc(100% - 20px);
//             background-color:$white;
//             padding: 10px;
//             transition: all 0.3s ease-in-out;
//             * {
//                 justify-content: center;
//             }
//         }
//         &:hover {
//             box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
//             border-color: transparent;
//             .img-wrapper {
//                 .bg-size {
//                     transform: scale(1.2);
//                 }
//             }
//             .cart-info {
//                 a {
//                     &:first-child {
//                         animation: fadeInRight 300ms ease-in-out;
//                     }
//                 }
//             }
//             .abs-product {
//                 bottom: 0;
//             }
//         }
//     }
// }

.color-panel {
    display: flex;
    align-items: center;
    gap: 6px;

    &.coverflow {
        ul {
            li {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                border: 2px solid$white;

                +li {
                    margin-left: -13px;
                }
            }
        }
    }

    &.color-lg {
        ul {
            gap: 7px;
        }

        li {
            width: 20px;
            height: 20px;
        }
    }

    ul {
        display: flex;
        align-items: center;
        gap: 4px;

        li {
            width: 15px;
            height: 15px;
            border-radius: 100%;
        }
    }

    span {
        font-size: 13px;
    }
}

@keyframes animName {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.general-variant {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    &.color {
        li {
            border-radius: 100%;
            width: 22px;
            height: 22px;
        }
    }

    &.image {
        li {
            width: 45px;
            height: 45px;
            background-color: $top-header;

            button {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &.rectangle {
        li {
            width: auto;
            height: auto;
            padding: 6px 8px;
            font-size: 12px;

            button {
                font-weight: 500;
            }
        }
    }

    &.thumbnail {
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        left: 10px;
        gap: 10px;
        margin: 0;

        li {
            border-radius: 100%;
            overflow: hidden;
            border: 1px solid$white;

            button,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.active {
                border-color: var(--theme-color);
            }
        }
    }

    li {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $top-header;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        button {
            outline: none;
            border: none;
            background: transparent;
            padding: 0;
        }
    }
}

@keyframes marquee-discount {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

.tab-product,
.product-full-tab {
    padding-top: 30px;

    .nav-material {
        &.nav-tabs {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            gap: calc(8px + (18 - 8) * ((100vw - 320px) / (1920 - 320)));
            overflow: auto;
            background-color: $top-header;
            border: 1px solid $white-dark;

            &.tab-style {
                .nav-link {
                    padding: 8px 10px;
                }
            }

            .nav-item {
                .nav-link {
                    color: $dark-font;
                    box-shadow: none;
                    text-align: center;
                    padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
                    background-color: $white;
                    text-transform: capitalize;
                    border: 1px solid #eee;
                    font-weight: 500;
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    border-radius: 0;
                }
            }

            .nav-link.active {
                color: white;
                background-color: var(--theme-color);
                font-weight: 600;
                border: 1px solid var(--theme-color);

                &~.material-border {
                    transition: all 0.3s ease;
                    opacity: 1;
                }
            }
        }
    }

    .theme-form {
        label {
            font-weight: 500;
            color: $dark-font;
        }

        input {
            border-color: $round-border;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: left;
        }

        .btn-solid,
        .btn-outline {
            margin: 0 auto;
        }

        textarea {
            border-color: $round-border;
            font-size: 15px;
            padding: 17px 25px;
            margin-bottom: 15px;
            height: inherit;
        }
    }

    .tab-content.nav-material {
        padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        border: 1px solid $white-dark;
        border-top: none;

        p {
            margin-bottom: 0;
            line-height: 1.5;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        &.vertical-tab {
            margin-top: 15px;
        }
    }

    .product-tab-description {
        .part {
            margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            p {
                +p {
                    margin-top: 4px;
                }
            }

            .inner-title {
                font-weight: 600;
                text-transform: capitalize;
                margin-bottom: 8px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .title {
        padding-right: 45px;
        color: var(--theme-color);
        padding-bottom: 20px;
    }

    .theme-slider {
        .slick-arrow {
            top: -45px;
            height: auto;

            :before {
                color: $black;
                font-size: 18px;
            }
        }
    }

    .product-box {
        position: relative;
        margin: 5px;

        &:hover {
            box-shadow: 0 0 12px 0 $round-border;

            .lbl-1 {
                opacity: 1;
                transition: all 0.3s ease;
            }

            .lbl-2 {
                opacity: 1;
                animation: flipInY 1000ms ease-in-out;
            }

            .color-variant li {
                opacity: 1 !important;
                animation: fadeInUp 500ms ease-in-out;
            }
        }

        .img-block {
            min-height: unset;
        }

        .cart-info {
            position: absolute;
            padding: 10px 0;
            top: 25%;
            right: 15px;
            width: 40px;
            margin-right: 0;

            i {
                padding-right: 0;
            }

            a,
            button {
                color: $font-color;
                transition: all 0.3s ease;
                background-color: $white;
                height: 35px;
                width: 35px;
                margin: 7px 0;
                border-radius: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 0;
                box-shadow: 0 0 12px 0 $round-border;

                :hover {
                    transition: all 0.3s ease;
                    color: var(--theme-color);
                }
            }
        }

        .lbl-1 {
            background-color: var(--theme-color);
            padding: 2px 20px 2px 10px;
            display: inline-block;
            text-align: center;
            color: $white;
            position: absolute;
            left: 0;
            top: 15px;
            font-size: 14px;
            line-height: 1.5;
            opacity: 0;

            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                height: 0;
                border-top: 12px solid var(--theme-color);
                border-bottom: 13px solid var(--theme-color);
                border-right: 7px solid $white;
            }
        }

        .lbl-2 {
            font-size: 14px;
            top: 15px;
            position: absolute;
            right: 10px;
            color: #333333;
            font-weight: 600;
            text-transform: capitalize;
            opacity: 0;
        }

        a {
            color: #0072bb;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 1px;
        }

        .color-variant {
            position: absolute;
            top: -35px;
            width: 100%;
        }

        .slick-slide img {
            display: block;
        }

        .product-details {
            position: relative;

            .color-variant {
                position: absolute;
                top: -35px;
                width: 100%;

                li {
                    opacity: 0;
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    border-radius: 100%;
                    margin: 0 3px;
                    transition: all 0.3s ease;
                    cursor: pointer;
                }
            }

            h6 {
                color: #333333;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: capitalize;
            }

            .price {
                padding-bottom: 10px;
                font-size: 16px;
                color: var(--theme-color);
            }
        }
    }
}

.question-answer {
    li {
        display: block;
        margin-bottom: 25px;
        padding-bottom: 19px;
        border-bottom: 1px solid $white-dark;

        .question-box {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 15px;

            h5 {
                border: 1px solid $white-dark;
                background-color: $top-header;
                text-transform: capitalize;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                width: auto;
                height: 30px;
                font-weight: 600;
                padding-inline: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
            }

            .link-dislike-box {
                display: flex;
                align-items: center;
                gap: 13px;
                margin-left: auto;

                li {
                    margin: 0;
                    padding: 0;
                    border: unset;

                    a {
                        span {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            color: rgba(#4a5568, 0.671);

                            i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .answer-box {
            display: flex;
            gap: 15px;

            h5 {
                border: 1px solid transparent;
                background-color: var(--theme-color);
                color: $white;
                text-transform: capitalize;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                width: auto;
                font-weight: 600;
                padding-inline: 7px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
                margin-top: 3px;
            }

            p {
                margin: 0;
                width: calc(100% - 15px - 30px);
                line-height: 1.5;
                color: #4a5568;
                letter-spacing: 0.6px;
            }
        }

        h6 {
            margin: 0;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            letter-spacing: 0.02px;
            color: $dark-font;
            line-height: 1.5;

        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }

        .report-text {
            text-align: right;
            font-size: 13px;
            color: rgba(#4a5568, 0.6);
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 4px;
            justify-content: flex-end;
            margin-top: 7px;

            i {
                font-size: 15px;
            }
        }
    }
}

.post-question-box {
    margin-bottom: 32px;

    h4 {
        color: $dark-font;
        font-weight: 500;
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;
        line-height: 1.4;

        a {
            width: auto;
            display: inline-block;
            font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            border: none;
            border-radius: 0;
            background-color: $top-header;
            color: $dark-font;
            padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.question-answer-modal {
    h3 {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.2;
        margin: 0;
        color: $dark-font;
    }

    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: var(--theme-color);
        opacity: 1;
        background-image: none;
        border-radius: 0;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        margin: 0;
        padding: 3px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-wrapper {
        background-color: $top-header;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        gap: 11px;
        border: 1px solid $white-dark;
        margin-bottom: 20px;

        .product-image {
            width: 80px;
            height: 80px;
            background-color: $white;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .product-content {
            .name {
                margin-bottom: 0;
                font-weight: 500;
                line-height: 1.4;
            }

            .price-number {
                margin-block: 3px 0;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

.product-review-form {
    .product-wrapper {
        display: flex;
        align-items: center;
        padding: 17px 23px;
        gap: 14px;
        margin-bottom: 15px;
        background-color: $top-header;
        border: 1px solid $white-dark;

        .product-image {
            width: 80px;
            border-radius: 0;
            overflow: hidden;
            background-color: $white;
        }

        .product-content {
            .name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
                line-height: 1.1;
            }

            .product-review-rating {
                display: flex;
                align-items: center;
                gap: 6px;

                label {
                    color: rgb(119, 119, 119);
                    font-weight: 400;
                }

                .price-number {
                    color: #777;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    margin: 5px 0 0;
                    line-height: 1.3;
                }

                .rating-number {
                    line-height: 1;
                    margin-left: 8px;
                    color: rgba(27, 27, 27, 0.6);
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    i {
                        margin-top: -1px;
                    }
                }

                .product-rating {
                    .rating {
                        li {
                            line-height: 1;
                        }
                    }

                    span {
                        i {
                            font-size: 20px;
                            color: #ffa200;
                        }
                    }
                }
            }
        }
    }

    .product-rating {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .price-number {
            display: flex;
            align-items: center;
            gap: 3px;
            line-height: 1.2;
            font-weight: 500;
            margin-bottom: 0;
            color: #777;
        }

        span {
            i {
                font-size: 20px;
                color: #ffa200;
            }
        }
    }

    .review-box {
        +.review-box {
            margin-top: 21px;
        }

        .product-review-rating {
            display: flex;
            align-items: center;
            gap: 9px;

            .product-rating {
                .rating {
                    li {
                        line-height: 1;

                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.review-title-2 {
    border-top: 1px solid $white-dark;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

    h4 {
        font-weight: 600;
        text-transform: none;
        letter-spacing: unset;
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 4px;
    }

    p {
        letter-spacing: unset;
        font-weight: 400;
        line-height: 1.4;
    }

    button {
        text-transform: capitalize;
        padding: calc(11px + 2 * (100vw - 320px) / 1600) calc(11px + 12 * (100vw - 320px) / 1600);
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        background-color: var(--theme-color);
        color: $white;
        margin-top: 17px;
        border-radius: 0;
        border: 1px solid transparent;

        &:hover,
        &:first-child:active {
            border-color: var(--theme-color);
            color: var(--theme-color);
            background-color: transparent;
        }
    }
}

.review-people {
    border-left: 1px solid #eee;
    padding-left: 23px;
    max-height: 400px;
    height: 100%;
    overflow: auto;

    @media (max-width: 1199px) {
        padding-left: unset;
        padding-top: 23px;
        border-left: unset;
        border-top: 1px solid #eee;
        margin-top: 23px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba($color: #555, $alpha: 0.14);
    }

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($color: #555, $alpha: 0.3);
    }

    @media (max-width: 991px) {
        border: none;
        padding: 0;
        margin-top: 25px;
    }

    .review-list {
        display: flex;
        flex-wrap: wrap;
        gap: 17px;

        li {
            display: block;
            width: 100%;

            .people-box {
                display: flex;
                gap: 15px;
                width: 100%;
                padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
                background-color: $top-header;
                border: 1px solid $white-dark;

                @media (max-width: 480px) {
                    display: block;
                }

                &:hover {
                    .reply {
                        a {
                            opacity: 1;
                        }
                    }
                }

                .people-image {
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease-in-out;

                        @media (max-width: 575px) {
                            border-radius: 8px;
                        }
                    }
                }

                .people-text {
                    .user-round {
                        width: 70px;
                        height: 70px;
                        object-fit: contain;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 34px;
                        border-radius: 0;
                        margin: 0 auto;

                        h4 {
                            font-size: 41px;
                            font-weight: 600;
                            color: #232323;
                            margin-bottom: 0;
                        }
                    }
                }

                .name-user {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 70px;
                    height: 70px;
                    background-color: #ececec;
                    border-radius: 10px;
                    color: #4a5568;

                    h3 {
                        font-size: 37px;
                        font-weight: 600;
                    }
                }

                .people-comment {
                    width: calc(100% - 70px - 15px);

                    .people-name {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 8px;

                        @media (max-width: 400px) {
                            display: block;
                        }

                        .name {
                            white-space: nowrap;
                            display: block;
                            font-weight: 600;
                            font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
                            color: #232323;

                            &:hover {
                                color: #232323;
                            }
                        }

                        h6 {
                            margin: 0;
                            font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
                            margin-right: auto;
                        }

                        .date-time {
                            width: 100%;
                            margin-top: 4px;

                            h6 {
                                font-size: 13px;
                                color: #777;
                                margin-block: 4px 6px;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                display: -webkit-box;
                                overflow: hidden;
                            }
                        }

                        .product-rating {
                            display: flex;
                            align-items: center;

                            @media (max-width: 400px) {
                                margin-top: 3px;
                            }
                        }
                    }

                    .name {
                        white-space: nowrap;
                        display: block;
                        font-weight: 600;
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                        color: #656565;

                        &:hover {
                            color: var(--theme-color);
                        }
                    }

                    @media (max-width: 480px) {
                        padding: 0;
                        width: 100%;
                        margin-top: 16px;
                    }

                    .date-time {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin: 0;

                        h6 {
                            font-size: 13px;
                            color: #777;
                            margin: 0;
                        }
                    }

                    .product-rating {
                        .star-rating {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            width: unset;

                            .ri-star-fill {
                                color: #ffa200;
                            }

                            .ri-star-line {
                                color: #ffa200;
                            }
                        }
                    }
                }

                .reply {
                    margin-top: 6px;
                    line-height: 1.6;
                    color: #4a5568;
                    position: relative;

                    p {
                        margin: 0;
                        line-height: 1.6;
                        letter-spacing: unset;
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));

                        @media (max-width: 480px) {
                            width: 100%;
                        }

                        @media (max-width: 400px) {
                            margin-top: 7px;
                        }
                    }
                }
            }
        }
    }
}

.single-product-tables {
    margin-top: 10px;
    display: block;

    table {
        width: 30%;

        tr {
            height: 35px;
            color: #6d6d6d;
            font-size: 16px;
            letter-spacing: 0.01em;

            td:nth-child(2) {
                font-weight: 500;
                color: black;
            }
        }
    }

    &.detail-section {
        margin-top: 0;

        table {
            width: 55%;
        }
    }
}

.product-rating-box {
    .base-rating {
        i {
            font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
            color: #ffb321;
        }
    }

    .rating-number {
        font-weight: 700;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: calc(32px + 10*(100vw - 320px) / 1600);
    }

    .rating-count {
        font-weight: 400;
        margin: 0;
        margin-top: calc(2px + 4*(100vw - 320px) / 1600);
        font-size: calc(14px + 2*(100vw - 320px) / 1600);
        color: #777;
        text-transform: unset;
        letter-spacing: unset;
    }

    .progressbar-list {
        li {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .rating-box {
        .product-rating span {
            font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
        }

        h4 {
            font-weight: 400;
            margin: 0;
            margin-top: calc(2px + (6 - 2) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: #777;
            text-transform: unset;
            letter-spacing: unset;
        }
    }

    .product-main-rating {
        margin-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: 20px;
        border-bottom: 1px solid $white-dark;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #232323;
            letter-spacing: unset;
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 700;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: calc(32px + (42 - 32) * ((100vw - 320px) / (1920 - 320)));

            i {
                font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
                font-weight: normal;
                color: #ffb321;
            }
        }

        h5 {
            line-height: 1.4;
            font-weight: 400;
            color: #4a5568;
            font-size: 17px;
        }
    }

    .product-rating-list {
        display: grid;
        gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)));

        li {
            &:nth-child(4) {
                .rating-product {
                    .progress {
                        .progress-bar {
                            background-color: #ffa53b;
                        }
                    }
                }
            }

            &:last-child {
                .rating-product {
                    .progress {
                        .progress-bar {
                            background-color: #ff4f4f;
                        }
                    }
                }
            }

            .rating-product {
                display: flex;
                gap: 12px;
                align-items: center;

                h5 {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 600;
                    margin: 0;

                    i {
                        font-size: 15px;
                        font-weight: normal;
                    }
                }

                .progress {
                    width: calc(100% - 12px - 28px - 15px);
                    height: 9px;
                    border-radius: 100px;

                    .progress-bar {
                        border-radius: 100px;
                    }
                }

                .total {
                    white-space: nowrap;
                    color: rgba(#4a5568, 0.878);
                    font-size: 16px;
                    font-weight: 400;
                    width: 15px;
                    display: block;
                }
            }
        }
    }
}

.details-sidebar-accordion {
    border: 1px solid $white-dark;

    .accordion-item {
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 0;
        border: none;

        .accordion-header {
            border-radius: 0;

            .accordion-button {
                color: $dark-font;
                padding-inline: 0;
                box-shadow: unset;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                background-color: #f9f9f9;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }

                &.collapsed {
                    padding-bottom: 0;
                }

                &:not(.collapsed) {
                    font-weight: 600;
                }

                &::after {
                    content: "\ea4e";
                    font-family: remixicon;
                    font-style: normal;
                    -webkit-font-smoothing: antialiased;
                    width: auto;
                    height: auto;
                    font-size: 24px;
                    background: unset;
                    font-weight: 100;
                    line-height: 1;
                }
            }
        }

        .accordion-body {
            padding: 0;
            background-color: #f9f9f9;
            max-height: 160px;
            overflow: hidden auto;

            &::-webkit-scrollbar {
                width: 4px;
                height: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--theme-color);
            }

            &::-webkit-scrollbar-track {
                background: #eaeaea;
            }

            .collection-listing {
                li {
                    width: 100%;
                    margin-bottom: 10px;

                    .form-check {
                        display: flex;
                        min-height: unset;
                        align-items: center;
                        gap: 10px;
                        padding: 0;

                        .form-check-input {
                            position: relative;
                            width: 20px;
                            height: 20px;
                            background-color: $white;
                            float: unset;
                            margin: 0;
                            cursor: pointer;
                            position: relative;
                            border: unset;
                            filter: unset;

                            &:focus {
                                border-color: #dee2e6;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                width: 21px;
                                height: 21px;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                left: 50%;
                                background-color: $white;
                                border: 2px solid #ececec;
                                cursor: pointer;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                width: 12px;
                                height: 6px;
                                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                                transform: rotate(-45deg) scale(0);
                                left: 4px;
                                top: 6px;
                                z-index: 1;
                                border: 2px solid var(--theme-color);
                                border-top-style: none;
                                border-right-style: none;
                            }

                            &:checked {
                                &::before {
                                    transform: rotate(-45deg) scale(1);
                                }

                                ~.form-check-label {
                                    color: var(--theme-color);
                                }
                            }
                        }

                        .form-check-label {
                            margin-bottom: 0;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            font-weight: 500;
                            line-height: 1;
                            transition: all 0.1s ease-in-out;
                            position: relative;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            color: #777;

                            .star-rating {
                                .ri-star-fill {
                                    color: #ffa200;
                                }

                                .ri-star-line {
                                    color: #ffa200;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.price-body {
                height: auto;
                padding-bottom: 0;
            }
        }
    }

    &.top-filter-accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    background-color: #f9f9f9;
                }
            }

            .accordion-body {
                background-color: #f9f9f9;
            }
        }
    }
}

.theme-card {
    &.product-card {
        margin-top: 25px;

        .card-title {
            font-weight: 600;
            margin-bottom: 15px;
            border-bottom: 1px solid $white-dark;
        }

        .media-list {
            .media {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                .product-sm {
                    width: 95px;
                    height: 95px;
                    object-fit: cover;
                    background-color: $top-header;
                    border: 1px solid $white-dark;
                    margin: 0;
                }

                .media-body {
                    .rating {
                        display: flex;
                        align-items: center;

                        i {
                            font-size: 16px;
                            padding: 0;
                            color: #ffa200;
                        }
                    }

                    a {
                        h6 {
                            font-weight: 500;
                            font-size: 16px;
                            color: #777;
                        }
                    }

                    h4 {
                        font-weight: 600;
                        font-size: 18px;
                        color: $dark-font;
                    }
                }
            }
        }
    }
}

.product-service {
    margin-top: 25px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid $white-dark;

    .media {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $white-dark;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        svg {
            height: 40px;
            width: 40px;
            fill: var(--theme-color);

            path {
                fill: var(--theme-color);
            }
        }

        .media-body {
            padding-left: 10px;

            h4 {
                margin-bottom: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));

                font-size: 16px;
                font-weight: 600;
            }

            p {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 400;
                margin-bottom: 0;
                text-transform: capitalize;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.border-product {
    padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-top: 1px dashed $white-dark;

    .bundle {
        margin-top: 10px;

        .bundle-image-box {
            border-radius: 8px;
            overflow: hidden;

            .bundle-box {
                display: flex;
                align-items: center;
                gap: 15px;
                border: 1px solid $white-dark;
                padding: 12px 12px;
                background-color: $top-header;
                position: relative;
                text-align: left;

                +.bundle-box {
                    border-top: 1px solid $white-dark;
                }

                .form-check {
                    min-height: unset;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: 12px;
                    right: 12px;

                    .form-check-input {
                        float: unset;
                        margin: 0;
                        filter: unset;
                    }
                }

                .bundle-image {
                    width: 70px;
                    height: 70px;
                    background-color: $white;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .bundle-content {
                    width: calc(100% - 15px - 70px);

                    h3 {
                        font-weight: 500;
                        margin: 0;
                        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                        letter-spacing: 1.4px;
                        margin-top: 9px;
                        color: var(--theme-color);
                    }

                    .product-page-details {
                        position: unset;
                        inset: unset;
                    }

                    select {
                        margin-left: 0;
                        font-size: 14px;
                        font-weight: 500;
                        padding-block: 6px;
                        color: $grey;
                        border: none;
                        margin-top: calc(6px + (11 - 6) * ((100vw - 320px) / (1920 - 320)));
                        text-transform: capitalize;
                        max-width: max-content;
                    }

                    h4 {
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: unset;
                        text-transform: unset;
                        margin-bottom: 0;
                        white-space: nowrap;
                        width: 86%;
                        color: $dark-top;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .content-list {
                        display: grid;
                        list-style-type: disc;
                        padding-left: 1.1rem;
                        gap: 4px;
                        margin-top: 16px;

                        li {
                            display: list-item;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .bundle-title {
            margin-top: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.3;
            color: $dark-font;
            text-transform: capitalize;
            font-weight: 600;
            margin-bottom: 5px;
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        .total-price {
            font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            color: var(--theme-color);
        }

        .bundle-btn {
            text-transform: capitalize;
            padding: calc(9px + (13 - 9) * ((100vw - 320px) / (1920 - 320))) calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
        }

        .bundle_detail {
            margin-top: 15px;

            .price_product {
                color: $dark-font;
                padding-left: 3px;
                font-weight: 600;
            }
        }
    }
}

.compare-btn-box {
    position: fixed;
    bottom: 50px;
    right: 90px;
    z-index: 10;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    .compare-btn {
        position: relative;
        inset: unset;
        display: block;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px;
        color: $white;
        background-color: var(--theme-color);
    }
}

.notification-wrap {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    top: -1999px;
    transition: all 1s ease-in-out;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);

    .notification {
        border-radius: 2px;
        background-color: #d1e7dd;
        // border: 1px solid #bfddcf;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;

        h5 {
            font-size: 14px;
            color: #0d462c !important;
            font-weight: 500;
            margin-bottom: 0;
        }

        i {
            font-size: 20px;
            font-weight: 500;
            color: #0d462c !important;
        }
    }

    .progress-bar {
        height: 5px;
        background-color: #d1e7dd;
        border-color: #d1e7dd;

        .progress {
            border-radius: 0%;
            background-color: #0d462ca2;
        }
    }

    &.show {
        top: 15px;
    }
}
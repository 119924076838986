/**=====================
    Dark css
==========================**/
body {
    &.dark {
        background-color: $dark-body;
        transition: all 0.3s ease;
        color: $white-5;
    }
}

.dark {
    &.rtl {
        .tracking-panel {
            ul {
                li {
                    &.active {
                        &::after {
                            border-right-color: $dark-border;
                        }
                    }

                    &::after {
                        border-right-color: $dark-top;
                    }

                    &::before {
                        border-right-color: $dark-body;
                    }
                }
            }
        }
    }

    h1 {
        color: $white;
    }

    h2 {
        color: $white-1;
    }

    h3 {
        color: $white-2;
    }

    h4 {
        color: $white-3;
    }

    h5 {
        color: $white-4;
    }

    h6 {
        color: $white-5;
    }

    p {
        color: $white-5;
    }

    li {
        color: $white-5;
    }

    a {
        color: $dark-link;
    }

    .no-data-added {
        background-color: $dark-border;
        background-color: $dark-body;
    }

    .btn-animation {
        &:before {
            background-color: $dark-top;
        }
    }

    .btn-outline {
        color: var(--theme-color);
    }

    .brand-section {
        .brand-box {
            h2 {
                border-color: $dark-border;
                background-color: $dark-top;
            }
        }
    }

    .dropdown-item {

        &:hover,
        &:focus {
            color: $white;
            background-color: $dark-body;
        }
    }

    .form-control {
        &::placeholder {
            color: rgb(117, 117, 117);
        }
    }

    .search-suggestion-box {
        box-shadow: 0 0 8px #6060602b;
        border-color: $dark-border;
        background-color: $dark-body;

        .not-found-box {
            h5 {
                color: #a7a7a7;

                span {
                    color: $round-border;
                }
            }
        }

        ul {
            >li {
                .suggestion-category {
                    a {
                        color: $white !important;
                    }

                    p {
                        color: $dark-link;

                        +p {
                            &::before {
                                color: rgba(206, 206, 206, 0.3);
                            }
                        }
                    }
                }
            }
        }

        .recent-search-section {
            .filter-row {
                >a {
                    background-color: $dark-top;
                    color: $white !important;

                    &:hover {
                        background-color: var(--theme-color);
                        color: $white !important;
                    }
                }
            }
        }
    }

    .modal {
        .btn-close {
            span {
                color: $white;
            }
        }
    }

    .form-select {
        background-color: $dark-body;
        border-color: $dark-border;
        background-image: url(../images/form-select-icon.svg);
        background-size: 11px 12px;
    }

    .border-bottom-grey,
    .border-top-grey {
        border-color: $dark-border;
    }

    .quick-view-modal {
        .modal-body {
            .view-main-slider {
                .slick-arrow {
                    color: #ddd;
                    background-color: $dark-top;
                    box-shadow: 0 0 8px rgba(64, 64, 64, 0.6);
                    border-color: $dark-border;

                    &::before {
                        color: #ddd;
                    }
                }
            }

            .view-thumbnail-slider {
                .slider-image {
                    background-color: $dark-top;
                    border-color: $dark-border;
                }
            }

            .right-sidebar-modal {
                .qty-box {
                    .input-group {
                        background-color: $dark-top;
                        border-color: $dark-border;

                        input {
                            background-color: $dark-top;
                        }

                        .btn {
                            background-color: $dark-body;

                            i {
                                color: #ddd;
                            }
                        }
                    }
                }

                .name {
                    color: #cfd4da;
                }

                .description-text {
                    color: #ddd;
                }

                .price-text {
                    span {
                        color: #cfd4da;
                    }

                    h3 {
                        .discounted-price {
                            color: var(--theme-color);
                        }
                    }
                }

                .buy-box {
                    a {
                        color: #ddd;
                    }
                }

                .product-rating {
                    .divider {
                        color: $dark-border;
                    }
                }
            }

            .quantity-variant {
                background-color: $dark-top;
                border-color: $dark-border;

                li {
                    background-color: transparent;

                    &.active {
                        background-color: $dark-body;

                        button {
                            background-color: $dark-body;
                        }
                    }

                    button {
                        background-color: $dark-top;
                    }
                }
            }
        }
    }

    .share-modal {
        .modal-body {
            .product-social {
                .nav-link {
                    background-color: $dark-top;
                    border-color: $dark-border;
                    color: #ddd;

                    &.active {
                        background-color: var(--theme-color);
                        color: $white;
                        border-color: transparent;
                    }
                }
            }
        }
    }

    .tab-border {
        border-color: $dark-border;
    }

    .product-page-details {
        .buy-box {
            a {
                color: #ddd;
            }
        }

        .price-text {
            .discounted-price {
                color: var(--theme-color);
            }

            span,
            del {
                color: #ddd;
            }
        }

        .product-offer {
            &.delivery-details {
                li {
                    i {
                        color: #ddd;
                    }
                }
            }
        }

        .product-accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        color: #ddd;
                        border-color: $dark-border;
                        background-color: $dark-top;
                    }
                }

                .accordion-body {
                    p {
                        color: #ddd;
                    }

                    .bordered-box {
                        .delivery-details {
                            li {
                                color: #ddd;
                            }
                        }
                    }
                }
            }
        }

        .product-buttons {
            .btn {
                &.disabled {
                    border-color: var(--theme-color);
                }
            }
        }

        .quantity-variant {
            &.radio {
                .digital-size {
                    .form-check {
                        .form-check-input {
                            border-color: $dark-border;
                            background-color: $dark-top;
                        }
                    }
                }
            }
        }
    }

    &.digital-download {
        header {
            &.header-5 {
                &.overlay-style {
                    &.sticky {
                        background-color: $dark-top;

                        .main-menu {
                            .menu-right {
                                .icon-nav {
                                    i {
                                        color: $white;
                                    }
                                }
                            }
                        }

                        .navbar-nav {
                            >li {
                                >a {
                                    color: $white;
                                }
                            }
                        }

                        .navbar {
                            &.navbar-expand-xl {
                                .navbar-nav {
                                    .nav-item {
                                        &.dropdown {
                                            >a {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .skeleton-basic-product {
        .img-wrapper {
            .product-image-box {
                background: linear-gradient(90deg, rgba(64, 64, 64, 0.85) 8%, #404040 18%, rgba(64, 64, 64, 0.85) 33%);
            }
        }

        .product-detail {

            .product-title,
            h6,
            .price {
                background: linear-gradient(90deg, rgba(64, 64, 64, 0.85) 8%, #404040 18%, rgba(64, 64, 64, 0.85) 33%);
            }
        }
    }

    .service-style-border {
        .service-block {
            .media-body {
                border-color: $dark-border;
            }
        }
    }

    .footer-background {

        .light-layout,
        .sub-footer {
            background-color: transparent;
        }
    }

    .bg_cls {
        background-color: $dark-top;
    }

    .white-bg {
        background-color: $dark-body !important;
    }

    .grey-bg {
        background-color: $dark-top;
    }

    .border-top {
        border-color: $dark-border !important;
    }

    .bg-light {
        background-color: $dark-top !important;
    }

    .product-details-box {
        .theme-card {
            .offer-slider {
                >div {
                    .media {
                        &:last-child {
                            img {
                                background-color: $dark-top;
                                border-color: $dark-border;
                            }
                        }
                    }
                }
            }
        }
    }

    .blog-section {
        .blog-details {
            p {
                color: $white-5;
            }
        }

        .review-box {
            background-color: $dark-body;

            .slick-prev {
                &:after {
                    background-color: #404040;
                }
            }

            .review-content {
                h6 {
                    color: $white-5;
                }
            }
        }
    }

    .page-link {
        background-color: $dark-body;
        transition: all 0.3s ease;
        border-color: $dark-border;
    }

    .breadcrumb-section {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .light-layout {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .form-control {
        color: $white-5;
        background-color: $dark-body;
        transition: all 0.3s ease;
        border: 1px solid $dark-border;
    }

    mark,
    .mark {
        background-color: $dark-top;
        color: $white;
    }

    .product-review-form {
        .product-rating {
            .price-number {
                color: #ddd;
            }
        }

        .product-wrapper {
            background-color: $dark-top;
            border-color: $dark-border;

            .product-image {
                background-color: $dark-body;
            }

            .product-content {
                .product-review-rating {
                    .price-number {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .btn-solid {
        color: $white !important;

        &:hover {
            color: var(--theme-color) !important;
            background-color: transparent;
        }
    }

    .variation-box {
        .radio {
            .form-check {
                .form-check-input {
                    background-color: $dark-body;
                    border-color: $dark-border;

                    &:checked {
                        background-color: var(--theme-color);
                        border-color: var(--theme-color);
                    }
                }
            }
        }
    }

    .quantity-variant,
    .image-box {
        li {
            background-color: $dark-top;
            border-color: $dark-border;
        }
    }

    .quantity-variant {
        background-color: $dark-top;
        border-color: $dark-border;

        li {
            background-color: $dark-top;
            border-color: transparent;

            &.active {
                background-color: $dark-body;
                box-shadow: unset;
                border-color: var(--theme-color);

                button {
                    background-color: #2b2b2b !important;
                    color: var(--theme-color);
                }
            }

            button {
                color: $round-border;
            }
        }

        &.color {
            li {
                border-color: transparent;

                &.active {
                    border-color: var(--theme-color);
                }
            }
        }

        &.circle {
            li {
                border-color: transparent;
            }
        }
    }

    select {
        color: $white-5;
    }

    option {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }

    .demo-section {
        .demo-effects {
            >div {
                .demo-text {
                    span {
                        color: $white;
                    }
                }
            }
        }
    }

    .setting_box_body {
        background-color: $dark-body;
    }

    .setting-box {
        background-color: $dark-body;

        ::-webkit-scrollbar-track {
            background: #3c3c3c;
        }

        .setting-section {
            border-color: #3c3c3c;
        }

        .demo-effects {
            >div {
                .layout-container {
                    border-color: #3c3c3c;
                }
            }
        }

        .accordion-button {
            color: $round-border;
            border-bottom-color: #3c3c3c;

            &::after {
                color: $round-border;
            }

            .setting-description-text {
                .setting-title-content {
                    color: $white;
                }
            }
        }

        .sidebar-back {
            color: $white;
            background-color: rgba(35, 35, 35, 0.8);
        }

        .close-icon {
            background-color: $dark-body;
            border-color: #3c3c3c;
        }

        .setting-inner-title {
            a {
                color: $white;
            }
        }

        .form-switch {
            .form-check-input {
                border-color: #3c3c3c;
            }
        }

        .setting-contant {
            &::-webkit-scrollbar-thumb {
                background: var(--theme-color);
            }
        }

        .setting-title {
            border-color: $dark-border;
            background-color: $dark-top;

            h4 {
                color: $round-border;
            }

            .close-icon {
                i {
                    color: #ddd;
                }
            }
        }
    }

    .top-header {
        .right-nav-about {
            .right-nav-list {
                .theme-form-select {
                    .dropdown-menu {
                        border-color: $dark-border;
                        background-color: $dark-top;

                        &:hover,
                        &:focus {
                            color: $dark-link;
                        }
                    }
                }
            }
        }
    }

    .blog-left {
        .blog-details {
            h4 {
                color: $white-3;
            }

            h6 {
                color: $white-5;
            }
        }
    }

    header {
        background-color: $dark-body;
        transition: all 0.3s ease;

        .offcanvas {
            background-color: $dark-body;

            .offcanvas-header {
                background-color: $dark-top;
            }
        }

        .search-full {
            .input-group {
                border-color: $dark-border;
            }
        }

        .navbar {
            &.navbar-expand-xl {
                .navbar-nav {
                    .nav-item {
                        &.dropdown {
                            >a {
                                &:hover {
                                    color: var(--theme-color);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.header-5 {
            .pixelstrap {

                a,
                &:hover,
                &:active {
                    color: $white-5;
                }
            }

            .main-menu {
                .menu-right {
                    .icon-nav {
                        li {
                            i {
                                color: #cfd4da;
                            }
                        }
                    }
                }
            }

            .top-header {
                .header-dropdown {
                    >li {
                        i {
                            color: #cfd4da !important;
                        }
                    }
                }
            }

            &.left-sidebar-header {
                background-color: $dark-body;

                .top-header {
                    .header-dropdown {
                        >li {
                            img {
                                filter: invert(1);
                            }
                        }
                    }
                }

                .form_search {
                    box-shadow: 0 0 0 1px #545454;
                }

                .onhover-div {
                    >div {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }

        &.header-tools {
            background-color: transparent;
            transition: all 0.3s ease;

            &.sticky {
                background-color: $dark-body;
            }

            .main-menu {
                .menu-right {
                    .icon-nav {
                        i {
                            color: $dark-link;
                        }
                    }
                }
            }

            .top-header {
                .header-dropdown {
                    li {
                        i {
                            color: $dark-link;
                        }
                    }
                }
            }

            .logo-menu-part {
                >.container {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }

            &.header-style {
                &.top-relative {
                    background-color: $dark-body;
                }
            }
        }

        &.left-header {
            .top-header {
                background-color: $dark-body;
                transition: all 0.3s ease;

                .header-contact {
                    li {
                        color: #ddd;
                    }
                }

                .header-dropdown {
                    li {
                        i {
                            color: #ddd;
                        }
                    }
                }
            }

            .main-menu {
                .menu-right {
                    .header-dropdown {
                        >li {
                            img {
                                filter: invert(100);
                            }
                        }
                    }
                }
            }

            .onhover-div {
                >div {
                    img {
                        filter: invert(100);
                    }
                }
            }

            .sidenav {
                nav {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }

                .left-sidebar_center {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }

                .pixelstrap {
                    >li {
                        >a {
                            background-color: $dark-top;
                            transition: all 0.3s ease;
                        }
                    }
                }
            }

            &.left-header-sm {
                .sidenav {
                    .left-sidebar_center {
                        .pixelstrap {
                            >li {
                                border-color: $dark-border;

                                >a {
                                    img {
                                        background-color: transparent;
                                        filter: invert(1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.header-style {
            .top-header {
                .header-dropdown {
                    >li {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }

            .onhover-div {
                >div {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }

        &.left-header {
            &.left-header-relative {
                .pixelstrap {
                    >li {
                        >a {
                            color: $white;
                            background-color: $dark-body;
                        }
                    }
                }
            }

            .main-menu {
                .brand-logo {
                    border-color: $dark-border;
                }
            }

            .sidenav {
                .leftside_social {
                    border-color: $dark-border;
                }
            }
        }

        &.header-metro {
            .metro {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }

            .top-header {
                .header-contact {
                    li {
                        color: $header-font;
                    }
                }

                .header-dropdown {
                    li {
                        color: $header-font;

                        a {
                            i {
                                color: $header-font;
                            }
                        }
                    }
                }
            }
        }

        &.header-christmas {
            background-color: #c1272d;
        }
    }

    .search-modal {
        .modal-body {
            .search-category {
                li {
                    color: #ddd;
                }
            }

            .search-input-box {
                i {
                    border-color: $dark-border;
                    color: #ddd;
                }

                .form-control {
                    border-color: $dark-border;
                }
            }
        }
    }

    .offcanvas {
        &.cart-offcanvas {
            background-color: $dark-body;

            .offcanvas-header {
                border-color: $dark-border;

                h3 {
                    color: #eaedef;
                }

                .btn-close {
                    background-color: $dark-top;
                    border-color: $dark-border;

                    i {
                        color: #cecece;
                    }
                }
            }

            .offcanvas-body {
                .pre-text-box {
                    border-color: $dark-border;

                    .progress {
                        background-color: $dark-top;
                    }
                }

                .cart-product {
                    li {
                        +li {
                            border-color: $dark-border;
                        }
                    }

                    .media {
                        img {
                            background-color: $dark-top;
                            border-color: $dark-border;
                        }

                        .media-body {
                            .qty-box {
                                background-color: $dark-top;
                                border-color: $dark-border;

                                .input-group {
                                    button {
                                        i {
                                            color: #cecece;
                                        }
                                    }
                                }
                            }

                            a {
                                h4 {
                                    color: #e7eaec;
                                }
                            }

                            .quantity {
                                color: #e7eaec;
                            }

                            .close-circle {
                                .close_button {
                                    background-color: $dark-top;
                                    border-color: $dark-border;

                                    i {
                                        color: #cecece;
                                    }
                                }
                            }
                        }
                    }
                }

                .cart-media {
                    &::-webkit-scrollbar-thumb {
                        background: $dark-border;
                    }

                    &::-webkit-scrollbar-track {
                        background: $dark-top;
                    }
                }

                .cart_total {
                    border-color: $dark-border;
                    background-color: $dark-top;

                    li {
                        +li {
                            border-color: $dark-border;
                        }

                        .total {
                            h5 {
                                color: #ddd;
                            }
                        }
                    }
                }
            }
        }
    }

    .slider-nav {
        .slider-image {
            border-color: $dark-border;
            background-color: $dark-top;
        }
    }

    .checkbox_animated {
        &::after {
            background-color: $dark-top;
            border-color: $dark-border;
        }
    }

    .border-product {
        border-color: $dark-border;

        .product-title {
            color: $white;
        }
    }

    .review-people {
        &::-webkit-scrollbar-thumb {
            background-color: #888;
        }

        &::-webkit-scrollbar {
            background-color: $dark-border;
        }

        .review-list {
            li {
                .people-box {
                    background-color: #262626;
                    border-color: $dark-border;

                    .people-comment {
                        .people-name {
                            .name {
                                color: $white;
                            }
                        }
                    }

                    .people-text {
                        .user-round {
                            background-color: $dark-body;

                            h4 {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .review-title-2,
    .review-people {
        border-color: $dark-border;
    }

    .product-rating-box {
        .product-main-rating {
            border-color: $dark-border;
        }

        .rating-count {
            color: #ddd;
        }

        .product-rating-list {
            li {
                .rating-product {
                    .progress {
                        background-color: $dark-border;
                    }

                    .total {
                        color: rgba(221, 221, 221, 0.78);
                    }
                }

                .rating-product {
                    h5 {
                        i {
                            color: $round-border;
                        }
                    }
                }
            }
        }

        .rating-box {
            h4 {
                color: $round-border;
            }
        }
    }

    .outer-border {
        border-color: $dark-border;
    }

    .tracking-panel {
        .panel-content {
            .icon {
                img {
                    filter: invert(1) brightness(100);
                }
            }
        }

        ul {
            li {
                background-color: $dark-top;

                &.active {
                    background-color: $dark-border;

                    &::after {
                        border-left-color: $dark-border;
                    }
                }

                &::after {
                    border-left-color: $dark-top;
                }

                &:before {
                    border-left-color: $dark-body;
                }
            }
        }
    }

    .ckeditor-content {
        p {
            color: #cfd4da !important;
        }
    }

    .post-question-box {
        h4 {
            a {
                background-color: #262626;
                color: $round-border;
            }
        }
    }

    .tab-product {
        &.product-details-contain {
            .nav-tabs {
                border-color: $dark-border;
                background-color: #262626;

                .nav-item {
                    .nav-link {
                        border-color: $dark-border;
                        background-color: $dark-body;

                        &.active {
                            color: var(--theme-color);
                            font-weight: 600;
                            background-color: rgba(236, 137, 81, 0.07);
                            border-color: rgba(236, 137, 81, 0.5882352941);
                        }
                    }
                }
            }
        }
    }

    .tab-product,
    .product-full-tab {
        .nav-material {
            &.nav-tabs {
                background-color: $dark-top;
                border-color: $dark-border;

                .nav-item {
                    .nav-link {
                        background-color: $dark-body;
                        border-color: $dark-border;

                        &.active {
                            background-color: var(--theme-color);
                            color: $white;
                        }
                    }
                }
            }
        }

        .tab-content {
            &.nav-material {
                border-color: $dark-border;
            }
        }
    }

    .clock {
        border-color: $round-border;

        &::after,
        &::before {
            background-color: $round-border;
        }
    }

    .pwd-page {
        .theme-form {
            input {
                border-color: $dark-border;
            }
        }
    }

    .login-page {
        .theme-card {
            border-color: $dark-border;
            background-color: $dark-top;
            // box-shadow: 0 0 2px 2px rgba(black, 0.1);

            .theme-form {
                input {
                    border-color: $dark-border;
                }

                label {
                    color: $dark-span;
                }
            }
        }

        .authentication-right {

            h6,
            p {
                color: $white-5;
            }
        }
    }

    .delivery-sec {
        a {
            color: var(--theme-color);
        }
    }

    .tracking-page {
        .tacking-table {
            border-color: #404040;

            tr {
                th {
                    border-color: #404040;
                    color: #ddd;
                    background-color: #232323;
                }

                td {
                    border-color: #404040;

                    h6 {
                        color: #ddd;
                    }
                }
            }
        }

        .summary-details {
            .details-box {
                border-color: #404040;
                background-color: #232323;
            }

            .tracking-total {
                li {
                    &:last-child {
                        border-color: #404040;
                    }
                }
            }

            .order-title {
                border-color: #404040;
                color: #fff;
            }

            .customer-detail {
                h4 {
                    color: #ddd;
                }
            }
        }
    }

    .order-success-sec {
        background-color: #232323;
        border-color: #404040;

        .order-detail {
            li {
                color: #ddd;
            }
        }
    }

    .product-order {
        .product-order-table {
            border-color: #404040;

            thead {
                tr {
                    th {
                        background-color: #232323;
                        color: #fff;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        color: #ddd;
                        border-color: #404040;
                    }
                }
            }

            tfoot {
                tr {
                    &:last-child {
                        border-color: #404040;
                        color: #fff;
                    }

                    td {
                        border-color: #404040;
                        color: #ddd;

                        &:nth-child(2) {
                            color: $white;
                            border-color: #404040;
                        }
                    }
                }
            }
        }
    }

    .theme-modal-2 {
        .modal-header {
            h3 {
                color: $white;
            }
        }

        .modal-body {
            .form-box {
                .form-select {
                    border-color: #404040;
                }

                .custom-select {
                    .select2-dropdown {
                        border-color: $dark-border;

                        .select2-search {
                            .select2-search__field {
                                color: $white;
                            }
                        }
                    }

                    .select2 {
                        .selection {
                            border-color: $dark-border;
                        }
                    }

                    .select2-selection {
                        .select2-selection__rendered {
                            color: $round-border;
                        }

                        &.select2-selection--single {
                            border-color: $dark-border;
                            background-color: $dark-top;

                            .select2-selection__placeholder {
                                color: $round-border;
                            }

                            .select2-selection__arrow {
                                &::before {
                                    color: $round-border;
                                }
                            }
                        }
                    }

                    .select2-results {
                        .select2-results__options {
                            .select2-results__option--highlighted {
                                background-color: var(--theme-color);
                            }

                            .select2-results__option {
                                background-color: $dark-body;
                                color: $round-border;
                            }
                        }
                    }
                }

                .form-label {
                    color: $round-border;
                }

                .form-control {
                    border-color: $dark-border;
                    background-color: $dark-body;
                }
            }
        }
    }

    .review-modal {
        .modal-body {
            .product-wrapper {
                background-color: $dark-top;
                border-color: $dark-border;

                .product-image {
                    background-color: $dark-body;
                }

                .product-content {
                    .product-review-rating {
                        label {
                            color: $round-border;
                        }

                        .product-rating {
                            .rating-number {
                                color: rgba(221, 221, 221, 0.6);
                            }
                        }
                    }
                }
            }

            .form-box {
                .form-label {
                    color: $round-border;
                }

                .form-control {
                    border-color: $dark-border;
                }
            }

            .review-box {
                .product-review-rating {
                    label {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .sticky-bottom-cart {
        .product-image {
            img {
                background-color: #262626;
            }
        }

        .cart-content {
            .middle-value {
                .qty-box {
                    input {
                        background-color: $dark-top;
                        border-color: $dark-border;
                    }

                    .input-group {
                        span {
                            button {
                                background: #232323 !important;
                                border-color: $dark-border;
                            }
                        }
                    }
                }
            }
        }
    }

    .bundle {
        .bundle-title {
            color: $white;
        }

        .bundle-image-box {
            .bundle-box {
                border-color: $dark-border;
                background-color: $dark-top;

                .bundle-content {
                    h4 {
                        color: $round-border;
                    }

                    select {
                        color: $round-border;
                        background-image: url(../../svg/dropdown.svg);
                    }
                }

                .bundle-image {
                    background-color: $dark-body;
                }

                .checkbox_animated {
                    &::after {
                        background-color: $dark-body;
                        border-color: $dark-border;
                    }
                }
            }
        }
    }

    .product-page-details {
        .compare-box {
            a {
                color: $dark-link;
            }
        }

        .progress {
            background-color: $dark-top;
        }

        .left-progressbar {
            h6 {
                color: $round-border;
            }
        }

        .product-offer {
            li {
                color: $round-border;
            }
        }

        .dashed-border-box {
            border-color: $dark-border;

            h4 {
                background-color: $dark-body;
            }
        }

        .shipping-info {
            li {
                color: #cfd4da;
            }
        }

        .bordered-box {
            border-color: $dark-border;
        }

        .qty-section {
            .qty-box {
                .input-group {
                    background-color: $dark-top;
                    border-color: $dark-border;

                    button {
                        background-color: $dark-body !important;
                        box-shadow: unset;
                    }

                    input {
                        background-color: $dark-top;
                    }
                }
            }
        }

        .description-text {
            color: $round-border;
        }

        .price-text {
            span {
                &.text-dark {
                    color: $white !important;
                }
            }
        }

        .product-rating {
            .divider {
                color: $dark-border;
            }
        }

        .rating,
        .product-rating {
            span {
                color: #ff9800;
            }
        }

        .size-delivery-info {
            border-color: $dark-border;

            a {
                color: $round-border;
            }
        }

        .quantity-variant {
            li {
                background-color: transparent;

                &.active {
                    border-color: var(--theme-color);
                    background-color: $dark-body;
                }

                button {
                    background-color: transparent;
                }
            }
        }

        .select-dropdown {
            background-color: $dark-top;
            background-image: url("../images/form-select-icon.svg");
        }

        .timer {
            p {
                span {
                    color: $white;
                }
            }
        }

        &.product-form-box {
            border-color: $dark-border;
        }
    }

    .four-image-sec {
        background-color: $dark-top;
    }

    .phone-field {
        .custom-select {
            .select2 {
                .selection {
                    border-color: $dark-border;

                    .select2-selection {
                        .select2-selection__rendered {
                            color: $round-border;
                        }
                    }
                }
            }

            .select2-dropdown {
                .select2-search {
                    .select2-search__field {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .form-box {

        .form-label,
        label {
            color: $round-border;
        }

        .custom-select {
            .select2-results {
                .select2-results__options {
                    &::-webkit-scrollbar {
                        background-color: $dark-border;
                    }

                    .select2-results__option {
                        background-color: $dark-top;
                    }
                }
            }

            .select2-dropdown {
                border-color: $dark-border;
                background-color: $dark-top;

                .select2-search {
                    .select2-search__field {
                        border-color: $dark-border !important;
                        background: $dark-body !important;
                    }
                }
            }
        }
    }

    .auth-modal {
        .create {
            a {
                color: $white;
            }
        }

        .divider {
            &::before {
                border-color: $dark-border;
            }

            span {
                background-color: $dark-body;
                color: $round-border;
            }
        }

        .modal-content {
            background-color: transparent;

            .right-content {
                background-color: $dark-body;

                .modal-back {
                    color: $round-border;
                }
            }
        }

        .auth-form-box {
            .auth-box {
                .form-control {
                    border-color: $dark-border;
                }

                .form-label {
                    color: $round-border;
                }
            }

            .forgot {
                color: $round-border;
            }
        }

        .auth-title {
            h3 {
                color: $white;
            }
        }
    }

    .header-compact {
        .main-menu {
            .menu-right {
                .icon-nav {
                    li {
                        &.mobile-cart {
                            >div {
                                img {
                                    filter: invert(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .wishlist-section,
    .cart-section {
        tbody {
            .table-price {
                h2 {
                    del {
                        color: #ddd;
                    }
                }
            }

            .qty-box {
                .input-group {
                    button {
                        border-color: $dark-border;

                        i {
                            columns: #ddd;
                        }
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    color: #ddd;
                }
            }
        }
    }

    .header-style-1 {
        .border-top-cls {
            border-color: $dark-border;
        }

        .navbar {
            .nav-link {
                color: $dark-link;
            }
        }
    }

    .vector-category {
        .category-slide {

            .slick-prev,
            .slick-next {
                color: #ddd;
                background-color: $dark-top;
                box-shadow: 0 0 8px rgba(64, 64, 64, 0.6);
                border-color: $dark-border;

                &::before {
                    color: #ddd;
                }
            }
        }
    }

    .special-product-section {
        .title2 {
            h4 {
                color: #777;
            }

            h2 {
                color: #222;
            }
        }
    }

    &.header-style-light {
        .header-style-5 {
            .bottom-part {
                .category-menu {
                    .toggle-sidebar {
                        color: $round-border;
                        background-color: $dark-top;
                        border-color: $dark-border;

                        h5 {
                            color: $white-5;
                        }
                    }
                }
            }
        }
    }

    .header-style-5 {
        .bottom-part {
            background-color: $dark-top;

            &.bottom-light {
                border-color: $dark-border;
                background-color: transparent;

                .pixelstrap {
                    >li {
                        >a {
                            color: $white-5;

                            &:hover,
                            &:focus {
                                color: $white-5;
                            }
                        }
                    }
                }
            }
        }

        &.color-style {
            background-color: $dark-body;

            .form_search {
                &.ajax-search {
                    .typeahead {
                        background-color: #232323 !important;
                    }
                }
            }

            .bottom-part {
                .pixelstrap {
                    &.sm-vertical {
                        >li {
                            >a {
                                color: $dark-link;

                                &:hover,
                                &:focus,
                                &:active {
                                    color: $dark-link;
                                }
                            }
                        }
                    }
                }
            }

            &.style-classic {
                .bottom-part {
                    .pixelstrap {
                        >li {
                            >a {
                                color: $dark-link;

                                &:hover,
                                &:focus,
                                &:active {
                                    color: $dark-link;
                                }
                            }
                        }
                    }

                    .container {
                        background-color: $dark-top;
                    }
                }
            }
        }

        &.style-light {
            .bottom-part {
                .category-menu {
                    .toggle-sidebar {
                        color: #ddd;
                        background-color: $dark-top;
                        border-color: $dark-border;

                        h5 {
                            color: #ddd;
                        }
                    }
                }
            }
        }
    }

    .bg-theme {
        background-color: $dark-top;
    }

    .marketplace-sidebar {
        &.sidenav.fixed-sidebar {
            .sm-vertical {
                background-color: $dark-body;
                border-color: $dark-border;
            }
        }

        &.sidenav {
            .sm-vertical {
                background-color: $dark-top;
            }

            ul {
                li {
                    a {
                        background-color: $dark-top;
                        color: $white;
                    }
                }
            }
        }
    }

    .svg-icon-menu {
        &.wo-bg {
            .pixelstrap {
                >li {
                    >a {
                        svg {
                            fill: $white;
                        }
                    }
                }
            }
        }

        .pixelstrap {
            >li {
                >a {
                    img {
                        background-color: $dark-body;
                        border-color: $dark-border;
                    }
                }
            }
        }
    }

    .ajax-search {
        .typeahead {
            background-color: $dark-body !important;
        }

        .tt-menu {
            background-color: $dark-body;
        }

        .description-section {
            h4 {
                color: $white-3;
            }
        }
    }

    .deal-product-space {
        background-color: $dark-top;
    }

    &.section-white {
        section {
            background-color: $dark-body;
        }
    }

    .search-section {
        select {
            &.form-control {
                background-color: $dark-body;
            }
        }
    }

    .vegetables-category {
        .category-boxes {
            background-color: $dark-top;

            h4 {
                color: #e7eaec;
            }
        }
    }

    .product-vertical {
        .full-box {
            .theme-card {
                .offer-slider {
                    background-color: $dark-body;
                }
            }
        }
    }

    .top-header {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .banner-timer {
        .banner-text {
            h2 {
                color: #222;
            }
        }
    }

    .sub-footer {
        background-color: $dark-body;
        transition: all 0.3s ease;

        &.black-subfooter {
            p {
                color: #ddd;
            }
        }
    }

    .border-section {
        border-color: $dark-border;
    }

    .pixelstrap {

        a,
        &:hover,
        &:active {
            color: #cecece;
        }
    }

    .sm-vertical {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .sidenav {
        nav {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }

        .sidebar-back {
            color: $white-1;
            border-color: $dark-border;
        }
    }

    .deal-section {
        .sidenav {
            .pixelstrap {
                li {
                    .clothing-menu {
                        li {
                            a {
                                background-color: #2b2b2b;
                            }
                        }
                    }
                }
            }
        }
    }

    .pixelstrap {
        ul {
            background: $dark-body;
            box-shadow: none;

            a {
                color: $white-3;

                &:hover,
                &:focus,
                &:active,
                &.highlighted {
                    color: $white-3;
                }
            }
        }

        .link-section {
            h5 {
                color: $white;
            }
        }

        .mega {
            ul {
                background: $dark-body;
            }
        }
    }

    .pixelstrap {

        .home-menu,
        .feature-menu,
        .category-menu,
        .full-mega-menu,
        .clothing-menu {
            box-shadow: 0 0 1px 0 $dark-body;
        }
    }

    .onhover-div {
        >div {
            img {
                filter: brightness(100);
            }
        }

        .show-div {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }

    .error-section {
        h1 {
            color: #e6e6e6;
        }
    }

    .breadcrumb-section {
        box-shadow: unset;

        .breadcrumb {
            a {
                color: $grey-about;
            }
        }

        .breadcrumb-item {
            box-shadow: unset;

            +.breadcrumb-item {
                &::before {
                    color: $white;
                }
            }

            &.active {
                color: $white-3;
            }
        }
    }

    .main-menu {
        .menu-left {
            .navbar {
                i {
                    color: $grey-about;
                }
            }
        }

        .menu-right {
            .icon-nav {
                li {
                    i {
                        color: #cecece;
                    }
                }

                .onhover-div {
                    .empty-cart-box {
                        i {
                            background-color: $dark-top;
                        }
                    }

                    .show-div {
                        &.setting {
                            h6 {
                                color: $white-5;
                            }

                            ul {
                                li {
                                    a {
                                        color: $white-5;
                                    }
                                }
                            }
                        }

                        &.shopping-cart {
                            li {
                                border-color: $dark-border;

                                .media {
                                    .media-body {
                                        .qty-box {
                                            .input-group {
                                                span {
                                                    button {
                                                        i {
                                                            color: $round-border;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        h4 {
                                            color: $white-3;

                                            span {
                                                color: $white-4;
                                            }
                                        }
                                    }
                                }

                                .form-control {
                                    border-color: $dark-border !important;
                                }

                                .close-circle {
                                    i {
                                        &:hover {
                                            color: $white;
                                        }
                                    }
                                }

                                .total {
                                    border-color: $dark-border;

                                    h5 {
                                        color: $white-4;
                                    }
                                }

                                .buttons {
                                    a {
                                        color: $white;

                                        &:hover {
                                            color: var(--theme-color);
                                        }
                                    }
                                }
                            }

                            .cart_product {
                                &::-webkit-scrollbar-track {
                                    background: $dark-border;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .compare-fix {

        a,
        h5 {
            color: $white;
        }
    }

    .footer-theme {
        .sub-title {
            li {
                color: $white-5;

                a {
                    color: $white-5;
                }
            }

            h4 {
                color: $white-3;
            }

            .contact-list {
                i {
                    color: #ddd;
                }
            }
        }
    }

    .footer-social {
        i {
            color: $white-5;
        }
    }

    .footer-light {
        .subscribe {
            border-color: $dark-border;
        }
    }

    .border-b {
        border-color: $dark-border;
    }

    .footer-theme2 {
        .contact-details {
            li {
                color: $white-5;

                a {
                    color: $white-3;
                }
            }
        }

        .subscribe-block {
            border-color: $dark-border;
        }

        .footer-link,
        .footer-link-b {
            a {
                color: $dark-link;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    .top-header {
        .header-contact {
            li {
                color: $white-5;
            }
        }

        .header-dropdown {
            li {
                color: $white-5;

                a {
                    i {
                        color: $white-5 !important;
                    }
                }
            }

            .onhover-dropdown {
                .onhover-show-div {
                    li {
                        a {
                            color: $dark-link;
                        }
                    }
                }
            }
        }

        &.top-header-dark {
            .header-dropdown {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }

            .header-contact {
                li {
                    i {
                        color: $dark-link;
                    }
                }
            }
        }

        &.top-header-dark2 {
            .header-dropdown {
                li {
                    a {
                        color: $white-5;
                    }
                }
            }

            .header-contact {
                li {
                    i {
                        color: $white-5;
                    }
                }
            }
        }

        &.top-header-dark3 {
            .header-dropdown {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }

            .header-contact {
                li {
                    i {
                        color: $dark-link;
                    }
                }
            }
        }
    }

    .onhover-dropdown {
        .onhover-show-div {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }

    .testimonial {
        background-color: $dark-top;
        transition: all 0.3s ease;

        .testimonial-slider {
            .media {
                img {
                    border-color: $dark-border;
                }
            }
        }
    }

    .team {
        img {
            border-color: #232323;
        }

        h6 {
            background-color: #232323;
        }

        h4 {
            background-color: #232323;
            color: var(--theme-color);
        }
    }

    .service-block {
        +.service-block {
            border-color: $dark-border;
        }
    }

    .pixelstrap {
        &.light-font-menu {
            li {
                >a {
                    color: $white-1;
                }
            }
        }
    }

    .about-section {
        .service-block1 {
            background-color: $dark-top;
        }
    }

    .theme-tab {

        .tab-title,
        .tab-title2 {
            a {
                color: $dark-link;
            }

            .current {
                a {
                    color: var(--theme-color);
                }
            }
        }

        .tab-title2 {
            &:after {
                border-color: $dark-border;
            }

            .current {
                background-color: $dark-body;
                transition: all 0.3s ease;

                a {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    .product-box {
        .cart-info {
            &.bg-color-cls {
                button {
                    background-color: $dark-body;
                }

                a {
                    i {
                        background-color: $dark-body;
                    }
                }
            }
        }
    }

    .product-box,
    .product-wrap {

        .product-detail,
        .product-info {
            h4 {
                color: $white-3;
            }
        }
    }

    .theme-card {
        &.product-card {
            .card-title {
                border-color: $dark-border;
            }

            .media-list {
                .media {
                    .product-sm {
                        background-color: $dark-top;
                        border-color: $dark-border;
                    }

                    .media-body {
                        h4 {
                            color: $white;
                        }

                        a {
                            h6 {
                                color: #ddd;
                            }
                        }
                    }
                }
            }
        }

        .offer-slider {
            .media {
                .media-body {
                    h4 {
                        color: $white-3;
                    }

                    .rating {
                        i {
                            &:last-child {
                                color: #777;
                            }
                        }
                    }
                }
            }
        }

        h5 {
            &.title-border {
                border-color: $dark-border;
            }
        }

        &.card-border {
            border-color: $dark-border;
        }
    }

    .dark-layout {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }

    .sub-footer {
        &.darker-subfooter {
            background-color: $dark-top;
            transition: all 0.3s ease;

            p {
                color: $white-5;
            }
        }
    }

    .compare-page {
        .table-wrapper {
            .table {
                color: $white-1;
                border-color: $dark-border;

                thead {
                    .th-compare {
                        td {
                            background: $dark-top;
                            border-color: $dark-border;
                            color: #fff;
                        }

                        th {
                            border-color: $dark-border;

                            .remove-compare {
                                color: $white-5;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        th {
                            border-color: $dark-border;
                            color: #fff;
                            background: $dark-top;
                        }

                        td {
                            border-color: $dark-border;
                            color: #ddd;
                        }

                        .product_price {
                            color: #ddd;
                        }

                        p {
                            color: $white-5;
                        }
                    }
                }
            }
        }
    }

    .lookbook {
        .lookbook-block {
            .lookbook-dot {
                &:before {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }

                .dot-showbox {
                    .dot-info {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }

    .sitemap_page {
        ul {
            li {
                a {
                    color: $dark-link;

                    &:hover {
                        color: var(--theme-color);
                    }
                }
            }

            ul {
                li {
                    a {
                        color: $white-4;
                    }
                }
            }
        }

        .row {
            >div {
                &:nth-child(odd) {
                    background-color: $dark-top;
                }
            }
        }
    }

    .compare-padding {
        .compare-table {
            border-color: #404040;

            tr {
                &:nth-child(even) {
                    background-color: #232323;
                }

                td {
                    border-color: #404040;
                    color: #fff;

                    .img-section {
                        a {
                            color: #fff;
                        }
                    }

                    .compare-rating {
                        span {
                            color: #ddd;
                        }
                    }

                    .close-btn {
                        color: #fff;
                        border-color: #404040;
                        background-color: #232323;
                    }

                    &.title-detail {
                        border-color: #404040;
                    }
                }
            }
        }
    }

    .review-page {
        .comnt-sec {
            li {
                a {
                    color: $dark-link;
                }
            }
        }
    }

    .blog-detail-page {
        .blog-detail {
            h3 {
                color: $white;
            }

            .post-social {
                li {
                    color: $round-border;

                    +li {
                        border-color: $dark-border;
                    }
                }
            }
        }

        .comment-section {
            li {
                border-color: #404040;

                h6 {
                    color: #fff;

                    span {
                        color: #ddd;
                    }
                }
            }
        }
    }

    .blog-page {
        .blog-box {
            .blog-contain {
                .blog-label {
                    >span {
                        color: $round-border;

                        +span {
                            border-color: $dark-border;
                        }
                    }
                }

                a {
                    h3 {
                        color: $white;
                    }
                }
            }
        }

        .blog-list {
            border-color: $dark-border;

            .blog-contain {
                p {
                    color: $round-border;
                }

                .blog-label {
                    >span {
                        color: $round-border;

                        +span {
                            border-color: $dark-border;
                        }
                    }
                }

                a {
                    h3 {
                        color: $white;
                    }
                }
            }
        }

        .blog-media {
            .blog-right {
                h4 {
                    color: $white-3;
                }

                ul {
                    color: $white-5;

                    li {
                        +li {
                            border-color: $dark-border;
                        }
                    }
                }
            }
        }

        .blog-sidebar {
            .categories {
                li {
                    a {
                        span {
                            color: $round-border;
                        }
                    }
                }
            }

            .theme-card {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border-color: $dark-border;

                .tags {
                    li {
                        background-color: $dark-body;
                        border-color: $dark-border;

                        &:hover {
                            background-color: var(--theme-color);
                            border-color: var(--theme-color);

                            a {
                                color: $white;
                            }
                        }

                        a {
                            color: $round-border;
                        }
                    }
                }

                .recent-blog {
                    li {
                        .blog-box {
                            .blog-content {
                                h6 {
                                    color: $round-border;
                                }
                            }
                        }
                    }
                }
            }

            .left-skelton-box {
                background-color: $dark-top;

                ul {
                    li {
                        background-color: #1d1d22;
                        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(44, 44, 44, 0.5), rgba(255, 255, 255, 0));
                    }
                }
            }

            h4 {
                border-color: $dark-border;
                color: $white-3;
            }

            h6 {
                color: $white-5;
            }

            p {
                color: $white-5;
            }
        }
    }

    .form_search {
        background-color: $dark-top;
        box-shadow: none;
    }

    .img-category {
        &:hover {
            .img-sec {
                border-color: var(--theme-color);
            }
        }

        .img-sec {
            background-color: $dark-top;
            border-color: $dark-border;
        }

        h4 {
            a {
                color: $round-border;
            }
        }
    }

    .vector-category {
        .container {
            background-color: $dark-body;
            box-shadow: 0 0 8px #0a0a0a;
        }

        .category-slide {
            .img-category {
                .img-sec {
                    background-color: $dark-top;

                    img {
                        filter: invert(0.6);
                    }
                }
            }
        }
    }

    .cart-section,
    .wishlist-section {
        .cart-table {
            thead {
                th {
                    color: $white-1;
                }
            }
        }

        tbody {
            tr {
                td {
                    border-color: $dark-border !important;

                    a {
                        color: $dark-link;
                    }

                    h2 {
                        color: $white-1;
                    }
                }
            }
        }
    }

    .table {
        tbody {
            +tbody {
                border-color: $dark-border;
            }
        }

        thead {
            th {
                border-color: $dark-border !important;
            }
        }
    }

    .top-banner-wrapper {
        .top-banner-content {
            h4 {
                color: $white-3;
            }

            h5 {
                color: $white-4;
            }

            p {
                color: $white-5;
            }
        }
    }

    .top-filter {
        ::-webkit-scrollbar-track {
            background: $dark-border;
        }
    }

    .category-box {
        background-color: $dark-top;
        border-color: $dark-border;

        &.active {
            background-color: var(--theme-color);
            border-color: transparent;
        }
    }

    .irs-line {
        background: $dark-top;
    }

    .category-shop-section {
        .nav {
            background-color: $dark-top;

            .sidebar-back {
                border-color: $dark-border;
            }

            .nav-link {
                color: #ddd;

                &.active {
                    color: var(--theme-color2);
                }
            }
        }
    }

    .popular-search-section {
        border-color: $dark-border;

        ul {
            li {
                &::after {
                    background-color: $dark-border;
                }

                a {
                    color: #ddd;
                }
            }
        }
    }

    .product-style-5 {
        &.product-box {
            background-color: $dark-top;

            h5 {
                color: #ddd;
            }

            h6 {
                color: $white;
            }
        }
    }

    .category-slider-section {
        .product-category-slider {
            .category-box {
                background-color: $dark-top;
                border-color: $dark-border;

                &:hover {
                    background-color: var(--theme-color);
                    border-color: var(--theme-color);

                    h5 {
                        color: $white;
                    }
                }

                img {
                    filter: invert(1) brightness(100);
                }

                h5 {
                    color: #ddd;
                }
            }
        }
    }

    .collection-collapse-block {
        .collection-accordion {
            background-color: transparent;

            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        background-color: $dark-top;
                        color: #ddd;
                    }
                }

                .accordion-body {
                    background-color: $dark-top;

                    &::-webkit-scrollbar-track {
                        background: $dark-body;
                    }

                    .collection-listing {
                        li {
                            .form-check {
                                .form-check-input {
                                    &::after {
                                        background-color: $dark-body;
                                        border-color: $dark-border;
                                    }
                                }

                                .form-check-label {
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modern-box {
        background-color: $dark-top;
    }

    .collection-product-wrapper {
        .product-top-filter {
            border-color: $dark-border;
            background-color: $dark-top;

            .product-filter-content,
            .popup-filter {
                .form-select {
                    color: #ddd;
                    background-color: $dark-body;
                    border-color: $dark-border;
                }

                .search-count,
                .sidebar-popup {
                    border-color: $dark-border;
                    background-color: $dark-body;
                }

                .product-page-per-view,
                .product-page-filter {
                    .select2 {
                        .selection {
                            .select2-selection {
                                background: $dark-body;
                                border-color: $dark-border !important;
                            }
                        }

                        .select2-container--default {
                            .select2-dropdown {
                                border-color: $dark-border;
                                background: $dark-body;
                            }

                            .select2-results {
                                .select2-results__options {
                                    &::-webkit-scrollbar {
                                        background-color: $dark-border;
                                    }

                                    .select2-results__option {
                                        color: $round-border;
                                    }
                                }
                            }
                        }
                    }
                }

                .collection-grid-view {
                    border-color: $dark-border;
                }

                .product-page-per-view {
                    select {
                        border-color: $dark-border;
                    }
                }

                .collection-grid-view {
                    ul {
                        li {
                            background-color: $dark-body;
                            border-color: $dark-border;

                            &.active {
                                background-color: var(--theme-color);
                            }
                        }
                    }
                }
            }

            .popup-filter {
                ::-webkit-scrollbar-track {
                    background: $dark-body;
                }

                .sidebar-popup {
                    a {
                        background: $dark-body;
                        border-color: $dark-border;
                        color: $dark-link;
                    }
                }

                .open-popup {
                    border-color: $dark-border;
                    box-shadow: none;
                }
            }

            &.sidebar-top-filter {
                .collection-top-filter {
                    .top-filter-card-body {
                        background-color: $dark-top;
                        border-color: $dark-border;
                    }
                }
            }
        }
    }

    .product-slick,
    .rtl-product-slick,
    .product-right-slick,
    .rtl-product-right-slick {

        .slick-next,
        .slick-prev {
            &:before {
                color: #ddd;
                background-color: $dark-top;
                box-shadow: 0 0 8px rgba(64, 64, 64, 0.6);
                border-color: $dark-border;
            }
        }
    }

    .product-pagination {
        border-color: $dark-border;

        .pagination {
            li {
                &.active {
                    a {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                        border-color: $dark-border;
                    }
                }

                a {
                    border-color: $dark-border;
                    background-color: $dark-top;
                    color: $round-border;
                }
            }
        }

        .product-search-count-bottom {
            border-color: $dark-border;

            h5 {
                color: $white-4;
            }
        }
    }

    .portfolio-section {
        #form1 {
            border-color: #404040;
            background-color: #232323;
        }

        .isotopeSelector {
            .overlay {
                border-color: #404040;
            }
        }

        .filter-button {
            color: #fff;
            border-color: #404040;
            background-color: #2b2b2b;

            &.active {
                color: var(--theme-color);
                background-color: rgba(236, 137, 81, 0.071);
                border-color: rgba(236, 137, 81, 0.588);
            }
        }

        &.metro-section {
            .product-box {
                .product-detail {
                    background-color: $dark-body;
                    transition: all 0.3s ease;

                    h4 {
                        color: $white-3;
                    }

                    h6 {
                        color: $white-5;
                    }
                }
            }
        }
    }

    .collection-filter-block {
        border-color: $dark-border;
        background-color: $dark-top;
        transition: all 0.3s ease;

        .accordion-item {
            +.accordion-item {
                border-color: $dark-border;
            }

            .accordion-button {
                color: $white;

                &:not(.collapsed) {
                    color: $white;
                }
            }

            .accordion-body {
                .search-not-found-box {
                    background-color: $dark-body;
                }

                .search-box {
                    &::after {
                        border-color: $dark-border;
                    }

                    .form-control {
                        border-color: $dark-border;
                    }
                }

                .collection-filter-checkbox {
                    .form-check-input {
                        &::after {
                            background-color: $dark-body;
                            border-color: $dark-border;
                        }
                    }

                    .form-check-label {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .custom-sidebar-height {
        &::-webkit-scrollbar {
            background-color: $dark-body;
        }
    }

    .load-more-sec {
        a {
            border-color: $dark-border;
        }
    }

    .coupon-modal {
        .coupon-box {
            background-color: $dark-top;

            .coupon-content {
                p {
                    color: rgba(221, 221, 221, 0.678);
                }

                .coupon-apply {
                    border-color: $dark-border;

                    h6 {
                        color: $white;
                    }
                }
            }
        }
    }

    .checkout-page {
        .checkout-title {
            h3 {
                color: $white-2;
            }
        }

        .coupon-content {
            .coupon-apply {
                border-color: $dark-border;

                h6 {
                    color: $round-border;
                }
            }
        }

        .checkout-form {
            .form-group {
                .field-label {
                    color: $white-5;
                }
            }

            input {

                &[type="text"],
                &[type="email"],
                &[type="password"],
                &[type="tel"],
                &[type="number"],
                &[type="url"] {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }

            select,
            textarea {
                border-color: $dark-border;
            }

            .checkout-details {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border: 1px solid $dark-border;

                .coupon-box {
                    border-color: $dark-border;
                    background-color: $dark-body;
                }

                .order-box {
                    .title-box {
                        h4 {
                            color: $white;
                        }
                    }
                }

                .promo-code-box {
                    h5 {
                        color: $round-border;
                    }

                    .form-control {
                        border-color: $dark-border;
                    }
                }
            }
        }
    }

    .wishlist-section {
        tbody {
            tr {
                td {
                    .icon-box {
                        a {
                            border-color: $dark-border;
                            background-color: $dark-top;
                        }
                    }
                }
            }
        }
    }

    .coupon-box {
        background: $dark-top;

        .coupon-content {
            p {
                color: $round-border;
            }

            .coupon-apply {
                border-color: $dark-border;

                h6 {
                    color: $white;
                }
            }
        }
    }

    .intl-tel-input {
        .flag-box {
            margin-left: 6px;
            margin-right: unset;
        }
    }

    .checkout-section-2 {
        .left-sidebar-checkout {
            .checkout-detail-box {
                >ul {
                    >li {
                        .checkout-box {
                            background-color: $dark-top;
                            border-color: $dark-border;

                            .checkout-title {
                                border-color: $dark-border;
                            }

                            .checkout-detail {
                                .delivery-address-box {
                                    background-color: $dark-body;

                                    .form-check-input {
                                        &::before {
                                            background-color: #232323;
                                            border-color: #404040;
                                        }

                                        &:checked {
                                            &::before {
                                                border-color: var(--theme-color);
                                            }
                                        }
                                    }

                                    .form-check-label {
                                        .address {
                                            color: #fff;

                                            span {
                                                color: #fff;
                                            }
                                        }
                                    }

                                    >div {
                                        .form-check {
                                            .form-check-input {
                                                &::before {
                                                    background-color: $dark-top;
                                                    border-color: $dark-border;
                                                }
                                            }
                                        }

                                        .delivery-address-detail {

                                            h6,
                                            p {
                                                color: $round-border;

                                                span {
                                                    color: $round-border;
                                                }
                                            }
                                        }
                                    }
                                }

                                .delivery-option,
                                .payment-option {
                                    background-color: $dark-body;

                                    .delivery-category,
                                    .payment-category {
                                        .form-check {
                                            .form-check-input {
                                                &::before {
                                                    background-color: $dark-top;
                                                    border-color: $dark-border;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .checkout-right-box {
            .checkout-details {
                .order-box {
                    .title-box {
                        border-color: #404040;
                        color: #ddd;
                    }
                }
            }
        }
    }

    .order-box {
        .title-box {
            color: $white-2;
            border-color: $dark-border;
        }

        .qty {
            border-color: $dark-border;

            li {
                color: $white-5;

                .cart-image {
                    background-color: $dark-body;
                }

                span {
                    color: $white-4;
                }
            }
        }

        .sub-total {
            border-color: $dark-border;

            li {
                color: $white-5;

                .check-it {
                    &::after {
                        background-color: #2b2b2b;
                        border-color: #404040;
                    }
                }

                h4 {
                    color: #ddd;
                }
            }

            .shopping-option {
                label {
                    color: $dark-span;
                }
            }
        }

        .total {
            border-color: $dark-border;

            li {
                color: $white-5;
            }
        }

        .box-loader {
            .loader-wrapper {
                background-color: $dark-body;
            }
        }
    }

    .collection {
        .collection-block {
            .collection-content {
                h4 {
                    color: $white-3;
                }

                h3 {
                    color: $white-2;
                }
            }
        }
    }

    .table {

        th,
        td {
            border-color: $dark-border;
        }
    }

    .dashboard {
        .box-head {
            h2 {
                color: $white-1;
            }
        }

        .box {
            .box-title {
                border-color: $dark-border;

                h3 {
                    color: $white-2;
                }
            }
        }
    }

    .white-bg {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }

    .card {
        background-color: $dark-body;
        transition: all 0.3s ease;

        .card-header {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }

        .card-body {
            .notification-list {
                h4 {
                    color: $white;
                }

                h5 {
                    color: #ddd;
                }

                li {
                    border-color: $dark-border;
                    background-color: $dark-body;
                }
            }
        }
    }

    .cart-section,
    .wishlist-section {
        .cart-table {
            border-color: $dark-border;
        }

        thead {
            th {
                background-color: $dark-top;
            }
        }

        tbody {
            tr {
                td {
                    background-color: transparent;
                }
            }

            .remove-btn {
                border-color: $dark-border;
                background-color: $dark-top;
            }
        }

        .qty-box {
            .input-group {
                .form-control {
                    border-color: $dark-border;
                }
            }
        }

        tfoot {
            tr {
                td {
                    background-color: transparent;

                    &:first-child {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .compare-section {
        .compare-box {
            border-color: $dark-border;
        }

        .compare-part {
            .close-btn {
                background-color: $dark-top;
                color: $round-border;
                border-color: $dark-border;
            }

            .detail-part {
                .title-detail {
                    border-color: $dark-border;
                }
            }

            .img-section {
                border-color: $dark-border;

                a {
                    h5 {
                        color: $white;
                    }
                }
            }

            .detail-part {
                .title-detail {
                    background-color: $dark-top;
                }
            }
        }
    }

    .faq-section {
        .theme-accordion {
            .accordion-item {
                border-color: $dark-border;

                .accordion-header {
                    background-color: #232323;

                    .accordion-button {
                        color: #fff;
                        background-color: #232323;

                        &:not(.collapsed) {
                            color: var(--theme-color);
                        }
                    }
                }

                .accordion-body {
                    p {
                        color: #ddd;
                    }
                }
            }
        }
    }

    &.box-layout-body {
        .box-layout-header {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }

        .box-layout {
            &.bg-image {
                background-color: $dark-top;
                transition: all 0.3s ease;
                background-image: none;
                box-shadow: none;
            }
        }

        .light-layout {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }

        .sub-footer {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }

        .brand-slider-box {
            .logo-block {
                background-color: $dark-body;
            }
        }

        .general-variant {
            .active {
                border-color: $dark-border;
            }
        }

        .basic-product {
            .img-wrapper {
                background-color: $dark-body;
            }

            .cart-info {

                a,
                button {
                    background-color: $dark-top;
                }
            }
        }
    }

    .bg-title {
        &.wo-bg {
            .theme-tab {
                .bg-title-part {
                    border-color: $dark-border;

                    .title-border {
                        color: $white;
                    }
                }

                .tab-title {
                    a {
                        color: $white-5;
                    }
                }
            }
        }
    }

    .full-box {
        .theme-card {
            .offer-slider {
                .product-box2 {
                    +.product-box2 {
                        border-top: none;
                    }
                }
            }
        }
    }

    .center-slider {
        border-color: $dark-border;
    }

    .bg-block {
        background-color: $dark-top;
        transition: all 0.3s ease;
        background-image: none;
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    background-color: $dark-body;
                    transition: all 0.3s ease;

                    .product-box2 {
                        background-color: $dark-body;

                        .media {
                            .media-body {
                                h4 {
                                    color: $white-3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .flower-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;
        background-image: none;
    }

    header.full-scroll-menu {
        background-color: transparent;
        transition: all 0.3s ease;
    }

    &.tools-bg {
        .basic-product {
            background-color: $dark-top;

            .img-wrapper {
                background-color: $dark-body;

                .rating-label {
                    background-color: $dark-top;
                }
            }

            .cart-info {

                a,
                button {
                    background-color: $dark-top;
                }
            }
        }
    }

    .category-m {
        &.w-bg {
            .category-wrapper {
                background-color: $dark-body;
            }
        }

        .category-wrapper {
            h4 {
                a {
                    color: $dark-link;
                }
            }
        }
    }

    .category-block {
        &:hover {
            .category-image {
                &.svg-image {
                    background-color: var(--theme-color);
                }
            }
        }

        .category-image {
            border-color: $dark-border;

            &.svg-image {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }
    }

    .white-layout {
        background-color: #232323;
    }

    .footer-theme2 {
        &.footer-border {
            border-color: $dark-border;

            >div {

                &:first-child,
                &:last-child {
                    background-color: #232323;
                }
            }
        }

        &.section-light {

            .footer-social i,
            .social-white i {
                color: #ddd;
            }

            .footer-block {
                .subscribe-white {
                    border-color: $dark-border;
                }

                h4 {
                    color: $white-3;
                }

                .contact-details {
                    li {
                        a {
                            color: $white-3;
                        }
                    }
                }
            }
        }

        .footer-link {
            &.link-white {
                h4 {
                    color: $white-3;
                }
            }
        }
    }

    .absolute-banner {
        .absolute-bg {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }

    footer {
        .dark-layout {
            background-color: $dark-top;
        }

        &.pet-layout-footer {
            .white-layout {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }

            .sub-footer {
                &.black-subfooter {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }

            .footer-social,
            .social-white {
                i {
                    color: #ddd;
                }
            }
        }
    }

    .logo-block {
        img {
            filter: invert(100);

            &:hover {
                filter: invert(100);
            }
        }
    }

    .bg-white {
        background-color: $dark-body !important;
        transition: all 0.3s ease;
    }

    .product-box,
    .product-wrap {
        .img-wrapper {
            .cart-box {
                background-color: $dark-body;
                transition: all 0.3s ease;
                box-shadow: none;
            }
        }
    }

    .tab-bg {
        &.tab-grey-bg {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }

    .blog.blog_box {
        .blog-details {
            p {
                color: $white-5;
            }

            a {
                p {
                    color: $white-5;
                }
            }

            .read-cls {
                color: $dark-link;
            }
        }
    }

    .btn-close {
        color: $white;
    }

    .sticky-bottom-cart {
        // background-color: $dark-body;

        .cart-content {
            background-color: $dark-top;
        }

        .selection-section {
            .form-control {
                background-color: $dark-body;
                border-color: $dark-border;
                background-image: url("../images/form-select-icon.svg");
            }
        }

        .product-image {
            .content {
                h6 {
                    color: $white-5;
                }
            }
        }
    }

    .recently-purchase {
        background: $dark-body;
        box-shadow: 0 0 7px 0px #7c7c7c47;

        img {
            background-color: #252525;
        }

        .close-popup {
            color: $white;
        }
    }

    .product-slider-image,
    .sticky-product-height {
        background-color: $dark-top;
        border-color: $dark-border;
    }

    .product-social {
        li {
            background-color: $dark-top;
            border-color: $dark-border;

            a {
                color: $white;
            }
        }
    }

    .product-right {
        .product-title {
            color: $white-5;
        }

        .border-product {
            border-color: $dark-border;
        }

        .product-icon {
            .product-social {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }

            .wishlist-btn {
                color: $dark-link;

                i {
                    border-color: $dark-border;
                }
            }
        }

        &.product-form-box {
            border-color: $dark-border;

            .timer {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }

        .timer {
            background-color: $dark-top;
            transition: all 0.3s ease;

            p {
                color: $white-5;
            }
        }

        .size-box {
            ul {
                li {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;

                    &.active {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                    }

                    a {
                        color: $dark-link;
                    }
                }
            }
        }

        h4 {
            del {
                color: $dark-span;
            }
        }
    }

    .timer {
        span {
            .timer-cal {
                color: $dark-span;
            }
        }
    }

    .qty-box {
        .input-group {
            span {
                button {
                    background: $dark-body !important;
                    border-color: $dark-border;
                }
            }

            button {
                i {
                    color: #ddd;
                }
            }
        }
    }

    .nav-tabs {
        border-color: $dark-border;

        .nav-link {
            &.active {
                background-color: $dark-body;
                transition: all 0.3s ease;
            }
        }
    }

    .tab-product {
        .nav-material {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        color: $dark-link;
                    }
                }

                .nav-link {
                    &.active {
                        color: var(--theme-color);
                    }
                }
            }
        }
    }

    .tab-product {
        .theme-form {

            input,
            textarea {
                border-color: $dark-border;
            }
        }
    }

    .product-related {
        h2 {
            border-color: $dark-border;
        }
    }

    .product-accordion {
        .btn-link {
            color: $white-3;
        }

        .card-header {
            border-color: rgba($dark-border, 0.125);
        }

        .accordion {
            .accordion-item {
                background-color: transparent;

                .accordion-header {
                    .accordion-button {
                        color: $round-border;
                        border-color: $dark-border;
                        background-color: $dark-top;
                    }
                }

                .accordion-body {
                    p {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .bundle {
        .bundle_detail {
            .price_product {
                color: $white;
            }
        }
    }

    .product-detail-label {
        li {
            &.soldout {
                color: $dark-link;
            }

            &.featured {
                background-color: $dark-body;
            }
        }
    }

    .modal-content {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }

    button {
        &.close {
            color: $white;
        }
    }

    .modal-header {
        border-color: $dark-border;
    }

    .collection-filter-block {
        .product-service {
            .media {
                border-color: $dark-border;
            }
        }

        .accordion-item {
            .accordion-body {
                .shop-category-list {
                    >li {
                        >a {
                            color: $round-border;
                        }
                    }
                }
            }
        }
    }

    .category-border {
        background-color: $dark-border;
        transition: all 0.3s ease;

        div {
            .category-banner {
                .category-box {
                    background-color: $dark-top;
                    border-color: $dark-border;

                    h2 {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }

    .background {
        background-color: $dark-border;
        transition: all 0.3s ease;

        .contain-bg {
            background-color: $dark-body;
            transition: all 0.3s ease;

            h4 {
                color: $white-3;
            }

            &:hover {
                h4 {
                    color: var(--theme-color);
                }
            }
        }
    }

    .blog-bg {
        background-color: $dark-border;
        transition: all 0.3s ease;
    }

    .sub-footer {
        &.black-subfooter {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }

    &.tools-bg {
        background-color: $dark-body;
        transition: all 0.3s ease;

        section {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }
    }

    .category-tools {
        .category-m {
            .category-wrapper {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }
    }

    .category-m {
        .category-wrapper {
            border-color: $dark-border;

            h4 {
                color: $white-3;
            }

            .category-link {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
        }
    }

    .tools-brand {
        .row {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }

    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                background-color: $dark-top;
                transition: all 0.3s ease;
                box-shadow: none;

                h4 {
                    color: $white-3;
                }
            }
        }
    }

    .absolute-product {
        .product-box {
            background-color: $dark-top;
            transition: all 0.3s ease;

            .product-detail {
                .cart-bottom {
                    border-color: $dark-border;

                    i {
                        color: $dark-link;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .seller-grid-box {
        background-color: $dark-top;
        border-color: $dark-border;

        .grid-contain {
            .seller-contact-details {
                ul {
                    li {
                        i {
                            color: $round-border;
                        }

                        h5 {
                            color: $round-border;

                            a {
                                color: $round-border;
                            }
                        }
                    }
                }
            }

            .seller-category {
                .product-image {
                    li {
                        background: $dark-body;
                        color: $round-border;
                    }
                }

                .btn {
                    background-color: #2b2b2b !important;
                }
            }
        }

        .grid-image {
            .image {
                background-color: $dark-body;
            }

            .contain-name {
                h3 {
                    color: $white;
                }
            }
        }
    }

    .service-block1 {
        background-color: $dark-top;
        border-color: $dark-border;

        +.service-block1 {
            border-color: $dark-border;
        }

        .service-svg {
            background-color: $dark-body;
        }
    }

    .box-product {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        box-shadow: none;
                        border-color: $dark-border;
                        background-color: $dark-top;

                        .media {
                            background-color: $dark-top;
                            transition: all 0.3s ease;
                        }

                        .cart-bottom {
                            border-color: $dark-border;
                        }
                    }
                }
            }
        }
    }

    .pets-box {
        .product-box {
            .img-wrapper {
                .cart-info {
                    i {
                        background-color: $dark-body;
                    }
                }
            }
        }
    }

    .tab-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .darken-layout {
        background-color: $dark-top;
        transition: all 0.3s ease;

        p {
            color: $white-5;
        }

        .sub-title {
            .contact-list {
                li {
                    color: $white-5;
                }

                i {
                    color: $white-5;
                }
            }
        }

        .footer-social {
            i {
                color: $white-5;
            }
        }
    }

    .sub-footer {
        &.dark-subfooter {
            p {
                color: $white-5;
            }
        }
    }

    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {

                    a,
                    p {
                        color: $dark-link;
                    }
                }
            }
        }
    }

    .footer-title {
        border-color: $dark-border;
    }

    .category-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;

        .contain-block {
            background-color: $dark-body;
            transition: all 0.3s ease;

            h2 {
                color: $white-1;
            }

            h6 {
                span {
                    color: $dark-span;
                }
            }
        }
    }

    .service_slide {
        .service-home {
            .row {
                >div {
                    &:nth-child(even) {
                        .service-block1 {
                            background-color: rgba(43, 43, 43, 0.85);
                        }
                    }
                }
            }

            .service-block1 {
                background-color: rgba($dark-top, 0.9);
                transition: all 0.3s ease;

                &:nth-child(even) {
                    background-color: rgba($dark-top, 0.7);
                    transition: all 0.3s ease;
                }

                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    .bg-grey {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }

    .detail-cannabis {
        .detail_section {
            >div {
                background-color: $dark-body;
                transition: all 0.3s ease;
                box-shadow: none;
            }

            svg {
                fill: $white;
            }
        }
    }

    .demo-card {
        &.theme-card {
            background-color: $dark-top;
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-image: linear-gradient(135deg,
                            var(--theme-color) 5.77%,
                            $dark-top 5.77%,
                            $dark-top 25%,
                            $white-1 25%,
                            $white-1 30.77%,
                            $dark-top 30.77%,
                            $dark-top 50%,
                            var(--theme-color) 50%,
                            var(--theme-color) 55.77%,
                            $dark-top 55.77%,
                            $dark-top 75%,
                            $white-1 75%,
                            $white-1 80.77%,
                            $dark-top 80.77%,
                            $dark-top 100%);

                    .modal-bg {
                        background-color: $dark-body;
                        transition: all 0.3s ease;

                        .age-content {
                            h4 {
                                color: $white-3;
                            }
                        }

                        .btn-close {
                            span {
                                color: $white;
                            }
                        }

                        .offer-content {
                            h2 {
                                color: $white-1;
                            }
                        }
                    }
                }
            }
        }

        &.demo-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        background-color: $dark-body;
                        transition: all 0.3s ease;

                        .demo-section {
                            .demo-effects {
                                >div {
                                    .layout-container {
                                        box-shadow: none;
                                    }

                                    .demo-text {
                                        h4 {
                                            color: $white-3;
                                        }

                                        .demo-btn {
                                            .btn {
                                                border-color: $dark-border;
                                                background-color: $dark-body;
                                                transition: all 0.3s ease;
                                                color: $dark-link;
                                            }
                                        }
                                    }
                                }
                            }

                            .title-text {
                                h3 {
                                    color: $white-2;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            &.addtocart {
                                #upsell_product {
                                    .product-box {
                                        .product-detail {
                                            h6 {
                                                a {
                                                    color: $dark-link;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.exit-modal {
            .media {
                .media-body {
                    h5 {
                        color: $white-4;
                    }
                }
            }
        }
    }

    #quick-view {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    .effect-cls {

        &:before,
        &:after {
            filter: invert(0.83);
        }
    }

    .contact-page {
        .contact-form {
            background-color: $dark-top;
            border-color: $dark-border;

            .form-box {
                label {
                    color: $round-border;
                }
            }
        }

        .contact-right {
            ul {
                li {
                    border-color: $dark-border;
                    background-color: $dark-top;

                    .contact-icon {
                        i {
                            background-color: $dark-body;
                        }
                    }

                    .media-body {
                        h6 {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .setting-box {
        .setting-title {
            h4 {
                color: $white;
            }
        }
    }

    .vendor-profile {
        .profile-left {
            background-color: $dark-top;
            border-color: $dark-border;

            .profile-image {
                h3 {
                    color: $white-3;
                }

                a {
                    background-color: $dark-body;
                }

                img {
                    background-color: $dark-body;
                }
            }

            .profile-detail {
                border-color: $dark-border;
            }

            .vendor-contact {
                border-color: $dark-border;

                .vendor-details {
                    li {

                        i,
                        h5 {
                            color: $round-border;

                            a {
                                color: $round-border;
                            }
                        }
                    }
                }

                h6 {
                    color: $white-5;
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                background-color: $dark-top;
            }
        }

        .product-pagination {
            background-color: $dark-top;
        }

        .page-link {
            background-color: $dark-top;
        }

        .profile-title {
            h3 {
                color: $white-2;
            }

            h4 {
                color: $white-3;
            }
        }

        .collection-filter-block {
            border-color: transparent;
            background-color: $dark-top;
        }
    }

    .become-vendor {
        background-color: $dark-top;

        .step-bg {
            .step-box {
                background-color: $dark-body;
                border-color: $dark-border;

                .steps {
                    background-color: $dark-top;
                }
            }
        }
    }

    .user-dashboard-section {
        .wallet-point-box {
            border-color: $dark-border;
            background-color: $dark-top;

            .total-detail {
                .point-ratio {
                    background-color: $dark-body;

                    .counter {
                        color: $white;
                    }
                }
            }
        }

        .welcome-msg {
            h4 {
                color: $white;
            }
        }

        .faq-content {
            .box-loader {
                .loader-wrapper {
                    background-color: $dark-top;
                    border-color: $dark-border;
                }
            }
        }
    }

    .notification-list {
        li {
            border-color: $dark-border;
            background-color: $dark-body;

            &.unread {
                border: 1px solid var(--theme-color);

                h5 {
                    color: $round-border;
                }
            }

            h5 {
                color: #dee2e6;
            }
        }
    }

    .dashboard-section {
        .title-header {
            h5 {
                i {
                    color: $white;
                }
            }
        }

        .wallet-table {
            .table-responsive {
                border-color: $dark-border;
            }

            .order-table {
                td {
                    h6 {
                        color: $white;
                    }
                }

                tr {
                    a {
                        color: $round-border;
                    }
                }

                td,
                th {
                    border-color: $dark-border;
                }
            }
        }

        .wallet-point-box {
            .total-image {
                background-color: $dark-body;
            }

            .total-detail {
                .total-box {
                    h5 {
                        color: #eaedef;
                    }

                    h3 {
                        color: $white;
                    }
                }
            }
        }

        .top-sec-2 {
            border-color: $dark-border;
        }

        .themeform-auth {
            .form-label {
                color: $round-border;
            }

            .form-control {
                border-color: $dark-border;
            }
        }

        .top-sec {
            h3 {
                color: $white;
            }
        }

        .counter-section {
            background-color: $dark-top;
            border-color: $dark-border;
        }

        .dashboard-sidebar {
            background-color: $dark-top;
            border-color: $dark-border;

            .profile-top {
                border-color: $dark-border;

                .profile-image {
                    .user-round {
                        background-color: $dark-body;
                    }

                    .user-icon {
                        background-color: $dark-border;
                        border-color: rgba(119, 119, 119, 0.36);
                    }
                }

                .profile-detail {
                    border-color: $dark-border;

                    h6 {
                        color: $round-border;
                    }
                }
            }

            .faq-tab {
                .nav-tabs {
                    background-color: $dark-top;
                }
            }
        }

        .address-book-section {
            .select-box {
                .address-box {
                    background-color: $dark-body;

                    .top {
                        h6 {
                            color: $round-border;
                        }
                    }

                    .middle {

                        .address,
                        .number {
                            p {
                                color: $white;
                            }
                        }
                    }

                    .bottom {
                        border-color: $dark-border;

                        .bottom_btn {
                            background-color: $dark-top;
                            color: $white;
                        }
                    }
                }
            }
        }

        .radio_animated {
            &::after {
                background: $dark-body;
                border-color: $dark-border;
            }
        }

        .faq-content {
            .card {
                background-color: $dark-top;
                border-color: $dark-border;
            }

            .dashboard-table {
                background-color: transparent;

                .table {
                    thead {
                        th {
                            background-color: $dark-top;
                        }
                    }
                }
            }

            .download-detail {
                border-color: $dark-border;
                background-color: $dark-top;

                form {
                    .input-group {
                        .form-control {
                            border-color: $dark-border;
                        }
                    }
                }
            }

            .download-table {
                border-color: $dark-border;

                .user-download-table {
                    .dropdown {
                        .dropdown-toggle {
                            background-color: $dark-top;
                            border-color: $dark-border;
                            color: $round-border;
                        }
                    }

                    tbody {
                        td {
                            border-color: $dark-border;
                        }
                    }
                }
            }

            .tab-pane {
                .card-body {
                    .tracking-total {
                        ul {
                            li {
                                &:last-child {
                                    border-color: $dark-border;
                                }
                            }
                        }
                    }

                    .customer-detail {
                        ul {
                            li {
                                h4 {
                                    color: $round-border;
                                }
                            }
                        }
                    }

                    .order-title {
                        color: $white;
                        border-color: $dark-border;
                    }
                }
            }
        }

        .counter-section {
            .counter-box {
                background-color: $dark-body;

                h5 {
                    color: $round-border;
                }
            }
        }

        .box-head {
            h4 {
                color: $white;
            }
        }

        .dashboard-table {
            h3 {
                color: $white-2;
            }

            .table {

                th,
                td {
                    color: $white;
                }
            }
        }

        .dashboard-box {
            background-color: $dark-top;
            border-color: $dark-border;

            .dashboard-title {
                border-color: $dark-border;
            }

            .dashboard-detail {
                ul {
                    li {
                        .details {
                            .left {
                                h6 {
                                    color: #9c9c9c;
                                }
                            }
                        }

                        &.details {
                            h5 {
                                color: $white;

                                span {
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .faq-tab {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    background-color: $dark-top;
                    color: $white-5;

                    &:hover {
                        color: var(--theme-color);
                    }

                    &.active {
                        background-color: $dark-top;

                        i {
                            background-color: var(--theme-color);
                        }
                    }

                    i {
                        background-color: $dark-body;
                    }
                }
            }

            .logout-cls {
                border-color: $dark-border;
            }
        }
    }

    .category-img-wrapper {
        .category-wrap {
            .category-content {
                h3 {
                    color: $white-2;
                }
            }
        }
    }

    .add_to_cart {
        background-color: $dark-body;

        ::-webkit-scrollbar-thumb {
            background: $dark-border;
        }

        ::-webkit-scrollbar-track {
            background: $dark-body;
        }

        .cart-inner {
            background-color: $dark-body;

            .pere-text-box {
                border-color: $dark-border;

                .progress {
                    background-color: $dark-top;
                }
            }

            .cart_top {
                border-color: $dark-border;

                h3 {
                    color: $white-2;
                }

                .close-cart {
                    background-color: $dark-top;
                    border-color: $dark-border;

                    i {
                        color: $dark-link !important;
                    }
                }
            }

            .cart_media {
                li {
                    +li {
                        border-color: $dark-border;
                    }

                    .total {
                        border-color: $dark-border;
                    }

                    .media {
                        .media-body {
                            .gram {
                                color: #dee2e6;
                            }

                            h4 {
                                color: $white-3;
                            }
                        }

                        img {
                            background-color: $dark-top;
                            border-color: $dark-border;
                        }
                    }

                    .total {
                        h5 {
                            color: $round-border;
                        }
                    }
                }

                .close-circle {
                    button {
                        background-color: $dark-top;
                        border-color: $dark-border;
                    }

                    .close_button {
                        i {
                            color: $dark-link !important;
                        }
                    }
                }

                .cart_total {
                    border-color: $dark-border;
                    background-color: $dark-top;
                }
            }

            .qty-box {
                background-color: $dark-top;
                border-color: $dark-border;

                .input-group {
                    .input-group-prepend {
                        .btn {
                            i {
                                color: $dark-link !important;
                            }
                        }
                    }
                }
            }
        }

        &.top,
        &.bottom {
            .cart-inner {
                .cart_top {
                    background-color: $dark-top;
                }
            }
        }
    }

    .theme-settings {
        ul {
            li {
                background-color: $dark-top;
                box-shadow: 0px 0px 5px 0px rgba($black, 0.54);
                color: $white;

                a {
                    color: $white;
                }

                .backend-btn {
                    a {
                        color: $white;
                    }
                }
            }
        }
    }

    .cookie-bar {
        &.left-bottom {
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
            background-color: $dark-body;

            p {
                color: $white-5;
            }

            .btn-close {
                i {
                    color: $dark-link;
                }
            }
        }
    }

    .service-w-bg {
        .service-block {
            .media {
                background-color: $dark-top;
            }
        }
    }

    .title-basic {
        .timer {
            background-color: $dark-top;

            p {
                color: $white-5;
            }

            span {
                color: #fff;
            }
        }
    }

    .loader-wrapper {
        background-color: $dark-body;

        .loader {
            &:after {
                border-color: $dark-body;
            }
        }
    }

    .product-parallax {
        .theme-card {
            .offer-slider {
                >div {
                    .media {
                        background-color: $dark-body;
                    }
                }

                .media {
                    background-color: $dark-body;
                }
            }
        }
    }

    .load-more-button {
        .btn {
            &.loading {
                border-color: $dark-border $dark-border $dark-border #999;
                background: $dark-body;
            }
        }
    }

    .footer-style-1 {
        .footer-social {
            i {
                background-color: $dark-body;
                border-color: $dark-border;
            }
        }
    }

    .category-width {
        .category-block {
            .category-image {
                img {
                    filter: invert(0.6);
                }
            }

            &:hover {
                .category-image {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    .image-swatch {
        li {
            img {
                border-color: $dark-border;
            }

            &.active {
                img {
                    border-color: var(--theme-color);
                }
            }
        }
    }

    .gradient-category {
        .gradient-border {
            .img-sec {
                background-color: $dark-top;

                &:after {
                    filter: invert(0.9);
                }
            }
        }
    }

    .deal-category {
        .deal-content {
            background-color: $dark-body;
        }
    }

    .mobile-menu {
        background-color: $dark-top;

        ul {
            li {
                &.active {

                    i,
                    span {
                        color: $white;
                    }
                }

                a {
                    i {
                        color: $round-border;
                    }

                    span {
                        color: rgba(221, 221, 221, 0.8);
                    }
                }
            }
        }
    }

    .header-style-7 {
        border-color: $dark-border;

        .main-menu {
            .menu-left {
                .navbar {
                    .bar-style {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }

    .shop-sidebar-demo {
        .shop-sidebar {
            background-color: $dark-top;
            border-color: $dark-border;

            .pixelstrap.sm-vertical {
                a {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }

        .shop-main {
            .small-slider {
                .home {
                    .slider-contain {

                        h4,
                        h2 {
                            color: $dark-font;
                        }
                    }
                }
            }
        }
    }

    .top-panel-adv {
        .delivery-area {
            h5 {
                color: $dark-font;
            }
        }

        .panel-left-content {
            h4 {
                color: $dark-font;
            }
        }
    }

    &.bg_cls {
        .basic-product {
            &.theme-product-11 {
                background-color: $dark-body;
            }
        }

        .service-block1 {
            background-color: $dark-top;
            border-color: $dark-border;
        }
    }

    .title1 {
        &.title5 {
            hr[role=tournament6] {
                &::before {
                    background-color: $dark-body;
                }
            }
        }
    }

    .brand-slider-box {
        .logo-block {
            background-color: $dark-top;
            border-color: $dark-border;

            h4 {
                color: $white;
            }
        }
    }

    .left-header {
        .menu-left {
            ul {
                li {
                    a {
                        color: $round-border;
                    }
                }
            }
        }
    }

    .blog-wo-bg {
        .brand-slider-box {
            .logo-block {
                background-color: transparent;
            }
        }
    }

    .full-banner.feature-banner,
    .full-banner {
        &.feature-banner {

            .feature-object-right,
            .feature-object {
                li {
                    &:hover {
                        .media {
                            img {
                                background-color: #232323;
                            }
                        }
                    }

                    .media {
                        img {
                            border-color: #404040;
                        }
                    }
                }
            }
        }
    }

    &.christmas {
        header {
            &.sticky {
                background-color: $dark-top;
            }
        }

        .main-menu {
            .menu-right {
                .icon-nav {
                    li {
                        i {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .basic-product {
        .price {
            color: $white;
        }

        .product-title {
            color: $white;
        }

        .cart-info {

            button,
            a {
                background-color: $dark-body;

                i {
                    color: $round-border;
                }
            }
        }

        .timer {
            background-color: rgba(43, 43, 43, 0.92);
        }

        .img-wrapper {
            background-color: $dark-top;

            .rating-label {
                background-color: $dark-body;
                color: $white;
            }

            .addto-cart-bottom {
                background-color: $dark-body;
                border: 1px solid $dark-border;
                color: $round-border;
            }

            .trending-label {
                li {
                    &:nth-child(2) {
                        background-color: $dark-body;
                    }
                }
            }

            .unit-label {
                background-color: $dark-body;
            }

            .quick-option {
                background-color: $dark-top;
            }
        }

        .product-right {
            .form-select {
                background-color: $dark-top;
                border-color: $dark-border;
                color: $round-border;
                background-image: url(../../../assets/svg/dropdown.svg);
            }
        }

        .theme-bg-service {
            &.service-w-bg {
                .service-block {
                    .media {
                        background-color: var(--theme-color);
                    }
                }
            }
        }

        .add-cart-btn {
            color: $round-border;
            background-color: $dark-border;
        }

        .product-action {
            border-color: $dark-border;

            button {
                color: $round-border;
                background-color: $dark-border;
            }
        }

        .general-variant {
            li {
                background-color: $dark-body;
                border-color: $dark-border;

                button {
                    color: $white;
                }
            }

            .active {
                border-color: $dark-top;
            }
        }

        .product-detail {
            .digital-price {
                .form-check {
                    .form-check-input {
                        background-color: $dark-top;
                        border-color: $dark-border;

                        &:checked {
                            background-color: var(--theme-color);
                            border-color: var(--theme-color);
                        }
                    }
                }
            }
        }

        .addtocart_btn {
            .qty-box {
                .input-group {
                    background-color: $dark-top;

                    button {
                        border-color: $dark-border;
                        background-color: $dark-body;

                        i {
                            color: $round-border;
                        }
                    }
                }
            }
        }

        &.theme-product-1 {
            border-color: $dark-border;
            background-color: $dark-body;

            .product-detail {
                >div {
                    border-color: $dark-border;
                }
            }
        }

        &.theme-product-2 {
            background-color: $dark-top;
            border-color: $dark-border;

            .cart-detail {
                border-color: $dark-border;

                li {
                    +li {
                        border-color: $dark-border;
                    }

                    button,
                    a {
                        color: $round-border;
                    }
                }
            }

            .img-wrapper {
                background-color: $dark-body;

                &::after {
                    background-color: rgba(34, 34, 34, 0.77);
                }
            }

            .product-detail {
                .details {
                    li {
                        color: #ddd;
                    }
                }

                .add-wish {
                    background-color: $dark-border;
                }
            }
        }

        &.theme-product-3 {
            border-color: $dark-border;
        }

        &.theme-product-5 {
            .cart-info {
                background-color: $dark-border;

                a,
                button {
                    background-color: transparent;
                    box-shadow: unset;
                }
            }
        }

        &.theme-product-6 {
            border-color: $dark-border;

            .cart-info {
                background-color: $dark-body;

                a {
                    +a {
                        &::after {
                            background-color: $dark-border;
                        }
                    }
                }
            }

            .hover-action {
                li {
                    a {
                        background-color: transparent;
                    }
                }
            }
        }

        &.theme-product-7 {
            background-color: $dark-top;
            border-color: $dark-border;

            .img-wrapper {
                background-color: $dark-body;
            }
        }

        &.theme-product-8 {
            background-color: $dark-body;
            border-color: $dark-border;

            .cart-info {
                a {
                    i {
                        color: var(--theme-color);
                    }
                }
            }
        }

        &.theme-product-9 {
            background-color: $dark-top;
            border-color: $dark-border;

            .img-wrapper {
                background-color: $dark-body;
            }

            .discount-value {
                border-color: $dark-border;
                color: $round-border;

                .offer-icon {
                    color: $round-border;
                }
            }
        }

        &.theme-product-10 {
            .product-title {
                color: $white;
            }
        }

        &.theme-product-11 {
            border-color: $dark-border;

            .abs-product {
                background-color: $dark-top;
            }
        }
    }

    .collection-filter {
        .custom-nav-tab {
            .nav-item {
                border-color: $dark-border;
                background-color: $dark-top;

                .nav-link {
                    color: $round-border;

                    img {
                        filter: invert(1) brightness(100);
                    }
                }
            }
        }
    }

    .slider-nav,
    .rtl-slider-nav,
    .slider-right-nav,
    .rtl-slider-right-nav {
        .slick-slide {
            >div {
                border-color: $dark-border;

                img {
                    background-color: $dark-top;
                }
            }
        }
    }

    .question-answer {
        li {
            border-color: $dark-border;

            h6 {
                color: $white;
            }

            .answer-box {
                p {
                    color: $round-border;
                }
            }

            .question-box {
                h5 {
                    border-color: $dark-border;
                    background-color: #262626;
                }

                .link-dislike-box {
                    margin-left: unset;
                    margin-right: auto;

                    li {
                        a {
                            span {
                                color: rgba(221, 221, 221, 0.67);
                            }
                        }
                    }
                }
            }
        }
    }

    .shop-filter-category {
        border-color: $dark-border;

        .filter-list {
            border-color: $dark-border;

            li {
                background-color: $dark-body;

                a,
                i {
                    color: $round-border;
                }
            }
        }
    }

    .search-modal {
        .search-title {
            color: $white;
        }

        .search-category-box {
            li {
                &.text-secondary {
                    color: #fff !important;
                }
            }
        }

        .modal-body {
            .search-box {

                i,
                .form-control {
                    border-color: #3c3c3c;
                }
            }
        }
    }

    .card-white-bg {
        .title2 {
            h4 {
                color: var(--theme-color);
            }

            .title-inner2 {
                color: $dark-font;
            }
        }

        .theme-card {
            h5 {
                color: $dark-font;

                &.title-border {
                    border-color: #eee;
                }
            }

            .offer-slider {
                .media {
                    .media-body {
                        h4 {
                            color: $dark-font;
                        }

                        a {
                            h6 {
                                color: #777;
                            }
                        }
                    }
                }
            }
        }
    }

    .wo-box {
        .service-block1 {
            background-color: transparent;
        }
    }

    .setting-box {
        .accordion-button {
            .setting-description-text {
                .setting-content {
                    color: #cecece;
                }
            }
        }
    }

    .product-sidebar-box {
        .collection-filter-block {
            &.accordion {
                border-color: #3c3c3c;
            }
        }
    }

    .download-dropdown-menu {
        border-color: $dark-border;
        background-color: $dark-body;

        .dropdown-item {
            color: $round-border;
        }
    }

    .variation-modal {
        .variation-title {
            .main-title {
                a {
                    color: $white;
                }
            }
        }

        .variation-box {
            .quantity-variant {
                background-color: $dark-top;
                border-color: $dark-border;
            }
        }

        .variation-qty-button {
            border-color: $dark-border;

            .qty-section {
                .qty-box {
                    .input-group {
                        background-color: $dark-top;
                        border-color: $dark-border;

                        button {
                            i {
                                color: #cecece;
                            }
                        }
                    }
                }
            }
        }
    }

    .alert {
        .btn-close {
            color: #222;
        }
    }

    .product-service {
        background-color: $dark-top;
        border-color: $dark-border;

        .media {
            border-color: $dark-border;
        }
    }

    .details-sidebar-accordion {
        border-color: $dark-border;
        background-color: transparent;

        .accordion-item {
            background-color: $dark-top;

            .accordion-button {
                background-color: $dark-top;
                color: $white;
            }
        }

        .accordion-body {
            background-color: $dark-top;

            &::-webkit-scrollbar-track {
                background-color: $dark-border;
            }

            .collection-listing {
                li {
                    .form-check {
                        .form-check-label {
                            color: #ddd;
                        }
                    }
                }
            }
        }
    }

    .subscribe-form {
        &.classic-form {
            .form-control {
                background-color: $dark-top;
            }
        }
    }

    .category-nft {
        a {
            box-shadow: none;
            border-color: #404040;
        }
    }

    .nft-top-collection {
        .collection-panal {
            .nft-collection {
                +.nft-collection {
                    border-color: #404040;
                }

                .number {
                    color: #ddd;
                }
            }
        }
    }

    .nft-box {
        border-color: #525252;

        .nft-img {
            .timer-sec {
                background-color: rgba(64, 64, 64, 0.7);
            }
        }

        .nft-content {
            .top-content {
                h4 {
                    color: $white;
                }

                h5 {
                    color: rgba($white, 0.7);
                }
            }

            .bottom-content {
                span {
                    color: rgba($white, 0.7);
                }

                .price {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    .tools-grey {
        .product-box {
            border-color: #404040;

            .product-info {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border-color: #404040;
            }

            .cart-info {
                button {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                    color: $dark-link;
                }

                a {
                    background-color: $dark-top;
                    transition: all 0.3s ease;

                    i {
                        color: $dark-link;
                    }
                }
            }

            .img-wrapper {
                .front {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    .loader_skeleton {
        background-color: $dark-body;

        .product-box {
            .img-wrapper {
                background-color: $dark-border;
            }
        }

        .service-block1 {
            svg {
                background-color: #404040;
            }
        }

        .gradient-category {
            .gradient-border {
                background-color: #404040;
            }
        }

        .marketplace-sidebar.sidenav .pixelstrap>li>a .ldr-text,
        .home-slider .home .slider-contain h2,
        .home-slider .home .slider-contain h1,
        .home-slider .home .slider-contain h6,
        .collection-banner .ldr-bg .contain-banner h4,
        .collection-banner .ldr-bg .contain-banner h2,
        .collection-banner .ldr-bg .contain-banner h6,
        .title1 h4,
        .title1 h2,
        .title8 h2,
        .title8 p,
        .product-para .first,
        .product-para .second,
        .top-banner-wrapper .top-banner-content h4,
        .top-banner-wrapper .top-banner-content h5,
        .top-banner-wrapper .top-banner-content p,
        .product-top-filter .ldr-text,
        .product-box .product-detail h4,
        .product-box .product-detail h5,
        .product-box .product-detail h6,
        .product-box.product-style-5.product-box h6,
        .product-box.product-style-5.product-box h5,
        .product-box.product-style-5.product-box h4,
        .category-button .btn-outline,
        .category-block .category-details h5,
        .category-block .category-details h6,
        .service-home .service-block1 h4,
        .service-home .service-block1 p,
        .service-block1 h5,
        .service-block h4,
        .service-block p,
        .category-tools .category-m .category-wrapper h4,
        .category-tools .category-m .category-wrapper .category-link li,
        .category-tools .category-m .category-wrapper .ldr-btn,
        .collection-filter-block .filter-block h4,
        .collection-filter-block .filter-block ul li,
        .theme-card .title-border,
        .product-page .product-right h2,
        .product-page .product-right h4,
        .product-page .product-right h3,
        .product-page .product-right ul li,
        .product-page .product-right .btn-group .btn-ldr,
        .tab-product ul li,
        .gradient-category h4,
        .img-category h4,
        .title-basic .title,
        .vertical-banner .vertical-content h2,
        .vertical-banner .vertical-content a {
            background: linear-gradient(90deg, rgba(212, 212, 212, 0.3) 8%, #6e6e6e 18%, rgba(212, 212, 212, 0.3) 33%);
        }

        .product-page {
            .product-right {
                &.product-form-box {
                    border-color: $dark-border;
                }
            }

            .sm-product,
            .main-product {
                background-color: $dark-border;
            }
        }

        .marketplace-sidebar {
            &.sidenav {
                background-color: #232323;

                .pixelstrap {
                    >li {
                        >a {
                            .ldr-img-icon {
                                background-color: #404040;
                            }
                        }
                    }
                }
            }
        }

        .home-slider,
        .collection-banner,
        .home-slider {

            .home,
            .ldr-bg {
                background: #404040;
            }
        }

        .service-block {
            svg {
                background: #404040;
            }
        }

        .tab-product {
            p {
                background-color: $dark-border;
            }
        }

        .top-banner-wrapper {
            .img-ldr-top {
                background-color: $dark-border;
            }
        }
    }
}

@media (min-width: 1200px) {
    .dark {

        .pixelstrap .scroll-up,
        .pixelstrap .scroll-down {
            background: $dark-border;
        }

        header {
            .navbar-expand-xl {
                .dropdown-menu {
                    background-color: $dark-body;

                    .dropdown-item {
                        &:active {
                            color: $white;
                        }
                    }
                }

                .dropdown-mega {
                    .dropdown-column {
                        .dropdown-header {
                            color: $round-border;
                        }
                    }

                    .dropdown-menu {
                        .dropdown-item {

                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }
                    }
                }
            }

            .navbar {
                &.navbar-expand-xl {
                    .navbar-nav {
                        .nav-item {
                            &:hover {
                                >.nav-link {
                                    &:not(.disabled) {
                                        color: var(--theme-color);
                                    }
                                }
                            }

                            &.dropdown {
                                >a {
                                    &:hover {
                                        color: var(--theme-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1430px) {
    .dark {
        header {
            &.left-header {
                .sidenav {
                    .sidebar-back {
                        color: $white-5;
                        border-color: $dark-border;
                    }
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .product-filter-content {
                    .collection-view {
                        border-color: $dark-border;
                    }

                    .product-page-filter {
                        border-color: $dark-border;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .dark {
        .sm-horizontal {
            background-color: $dark-body;
            border-color: $dark-border;

            .mobile-back {
                border-color: $dark-border;
                color: $white-3;
                background-color: $dark-top;

                i {
                    color: #ddd;
                }
            }

            &.pixelstrap {
                ul {
                    background-color: $dark-body;
                }
            }
        }

        .tab-product {
            .nav-material {
                &.nav-tabs {
                    background-color: $dark-body;
                    border-color: $dark-border;
                }
            }

            &.product-details-contain {

                .nav-tabs,
                .nav-tabs {
                    border-color: $dark-border;
                    background-color: #262626;
                }
            }
        }

        header {
            &.left-header {
                .pixelstrap {
                    ul {
                        background-color: $dark-top;
                    }
                }

                &.left-header-relative {
                    .pixelstrap {
                        >li {
                            >a {
                                background-color: $dark-top;
                            }
                        }
                    }
                }
            }

            &.header-christmas {
                .pixelstrap {
                    >li {

                        >a,
                        >a:hover,
                        >a:focus {
                            color: $dark-link !important;
                        }
                    }
                }
            }

            .offcanvas-collapse {
                .offcanvas-body {
                    .navbar-nav {
                        .dropdown-menu {
                            .dropdown-item {
                                color: $dark-link;

                                &::before {
                                    background-color: rgba(206, 206, 206, 0.65);
                                }
                            }
                        }
                    }
                }
            }

            .navbar-nav {
                .dropdown-menu {
                    .dropdown-column {
                        .dropdown-header {
                            color: rgba(221, 221, 221, 0.8);
                        }
                    }
                }

                .dropdown-menu-2 {
                    .dropdown-column {
                        .dropdown-item {
                            color: $dark-link;

                            &::before {
                                background-color: rgba(206, 206, 206, 0.65);
                            }
                        }
                    }
                }
            }
        }

        .header-style-1 {
            .bg-light-xl {
                background-color: $dark-top !important;
            }
        }

        .pixelstrap {
            .link-section {
                h5 {
                    color: rgba(207, 212, 218, 0.8);
                }

                .menu-content {
                    a {
                        &::before {
                            background-color: #ddd;
                        }
                    }
                }
            }

            .clothing-menu {
                background-color: $dark-top;

                .link-section {
                    >ul {
                        >li {
                            >a {
                                &::before {
                                    background-color: rgba(207, 212, 218, 0.65);
                                }
                            }
                        }
                    }
                }
            }

            li {
                >ul {
                    background-color: $dark-top;

                    >li {
                        >ul {
                            >li {
                                a {
                                    &::before {
                                        background-color: rgba(207, 212, 218, 0.65);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .header-style-5 {
            .bottom-part {
                .pixelstrap {
                    >li {
                        >a {
                            color: $dark-link;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $dark-link;
                            }
                        }
                    }
                }

                &.bottom-light {
                    .marketplace-sidebar {
                        &.sidenav {
                            &.fixed-sidebar {
                                background-color: $dark-top;

                                .sm-vertical {
                                    background-color: $dark-top;
                                }
                            }
                        }
                    }
                }
            }

            &.color-style {
                .marketplace-sidebar {
                    &.sidenav {
                        &.fixed-sidebar {
                            background-color: $dark-top;

                            .sm-vertical {
                                background-color: $dark-top;
                            }
                        }
                    }
                }

                &.style-classic {
                    .bottom-part {
                        .container {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        .marketplace-sidebar {
            &.sidenav {
                &.wo-bg {
                    background-color: $dark-top;
                }
            }
        }

        .sidenav {
            .pixelstrap {
                li {
                    >ul {
                        >li {
                            >a {
                                color: rgba(207, 212, 218, 0.8);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .dark {
        .collection-filter {
            background-color: $dark-top;
        }

        .collection-product-wrapper {
            .product-top-filter {
                background-color: $dark-top;

                .product-filter-content {
                    background-color: $dark-top;
                    border-color: $dark-border;

                    .product-page-per-view,
                    .product-page-filter {
                        select {
                            border-color: $dark-border;
                        }
                    }
                }

                .popup-filter {
                    background-color: $dark-top;

                    .product-page-filter,
                    .product-page-per-view {
                        border-color: $dark-border;
                    }
                }
            }
        }

        .collection-mobile-back {
            border-color: $dark-border;
            background-color: $dark-body;

            span,
            i {
                color: $white;
            }
        }

        .collection-filter {
            box-shadow: none;
        }

        header {
            &.header-tools {
                .logo-menu-part {
                    background-color: $dark-body;
                }
            }

            &.header-style {
                &.header-tools {
                    .top-header {
                        background-color: $dark-body;
                    }
                }
            }
        }

        .service_slide {
            .service-home {
                .service-block1 {
                    background-color: $dark-body;

                    &:nth-child(even) {
                        background-color: $dark-body;
                    }
                }
            }
        }

        .dashboard-section {
            .dashboard-sidebar {
                .back-btn {
                    color: $white;
                    border-color: $dark-border;
                    background-color: $dark-top;
                }

                .profile-top {
                    background-color: $dark-body;

                    .profile-image {
                        .user-round {
                            background-color: $dark-top;
                        }
                    }
                }

                .faq-tab {
                    .nav-tabs {
                        background-color: $dark-top;

                        .nav-item {
                            background-color: $dark-top;
                        }
                    }
                }
            }
        }

        .collection-collapse-block {
            &.open {
                background-color: $dark-top;
            }
        }
    }
}

@media (max-width: 767px) {
    .dark {
        .collection-product-wrapper {
            .product-pagination {
                .theme-pagination-block {
                    nav {
                        border-color: $dark-border;
                    }
                }
            }
        }

        .product-pagination {
            .pagination {
                border-color: $dark-border;
            }
        }

        .product-wrapper-grid {
            &.list-view {
                .product-box {
                    border-color: $dark-border;
                }
            }
        }

        .wishlist-section {
            tbody {
                tr {
                    td {
                        .mobile-cart-content {
                            .col {
                                &:last-child {
                                    .td-color {
                                        a {
                                            border-color: $dark-border;
                                            background-color: $dark-top;
                                            color: #ddd;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 577px) {
    .dark {
        header {
            &.header-style {
                .top-header {
                    .header-dropdown {
                        >li {
                            img {
                                filter: invert(0.55);
                            }
                        }
                    }
                }
            }
        }

        .header-5 {
            .top-header {
                .header-dropdown {

                    .mobile-wishlist,
                    .mobile-account {
                        img {
                            filter: invert(1);
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .product-filter-content {
                    .product-page-per-view {
                        border-color: $dark-border !important;
                    }
                }
            }
        }

        .tools-service {
            &.absolute-banner {
                .absolute-bg {
                    box-shadow: none;
                }
            }
        }

        .full-banner {
            .banner-contain {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        .mobile-fix-option {
            background-color: $dark-body;
        }

        .top-header {
            .header-dropdown {

                .mobile-account,
                .mobile-wishlist {
                    i {
                        color: #cecece;
                    }
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .dark {
        .full-box {
            .theme-card {
                .offer-slider {
                    .sec-1 {
                        .product-box2 {
                            border-color: $dark-border;
                        }
                    }
                }
            }

            .center-slider {
                .offer-slider {
                    .product-box {
                        border-color: $dark-border;
                    }
                }
            }
        }
    }
}
/**=====================
    About us Page css start
==========================**/

.accordion-body {
    ::-webkit-scrollbar {
        width: 4px;
        height: 7px;
    }

    ::-webkit-scrollbar-track {
        background: $round-border;
    }

    ::-webkit-scrollbar-thumb {
        background: $grey5;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $grey2;
    }
}

// sitemap page //
.sitemap_page {
    .title {
        color: var(--theme-color);
        text-transform: capitalize;
        font-weight: 700;
    }

    ul {
        padding-left: 10px;

        li {
            display: block;
            margin-bottom: 10px;

            a {
                color: $dark-font;
                font-weight: 500;
                font-size: 16px;
                transition: all 0.3s ease-in-out;
                text-transform: capitalize;

                i {
                    font-size: 10px;
                }

                &:hover {
                    color: var(--theme-color);
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        ul {
            padding-left: 15px;
            margin-top: 5px;

            li {
                margin-bottom: 0;
                line-height: 24px;
                font-size: 20px;

                a {
                    font-weight: 400;
                    color: $grey;
                    font-size: 16px;
                }
            }
        }
    }

    .row {
        >div {
            padding-top: 15px;
            padding-bottom: 15px;

            &:nth-child(odd) {
                background-color: $top-header;
            }
        }
    }
}

// profile page //
.select_input {
    select {
        padding: 15px 20px;
        margin-bottom: 30px;
    }
}

// coming soon //
.template-password {
    background: url("../images/coming-soon.jpg");
    background-size: cover;
    height: 100vh;

    #container {
        height: 100vh;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
            width: 100%;
        }

        #login {
            width: 100%;
            margin-bottom: 120px;

            h2 {
                color: var(--theme-color);
                font-size: 30px;
                font-weight: 400;
            }

            .theme-form {
                h3 {
                    color: $header-font;
                    font-size: 20px;
                }

                input {
                    border-color: $round-border;
                    font-size: 12px;
                    padding: 17px 25px;
                    margin-bottom: 15px;
                    height: inherit;
                }

                input {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        #powered {
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
        }

        #owner {
            a {
                color: var(--theme-color);
            }
        }
    }
}

.review-page {
    .comment-section {
        border-bottom: none !important;

        li {
            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .comnt-sec {
        margin-top: 10px;

        li {
            margin-right: 15px;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            border-top: none !important;

            a {
                color: $grey;
                font-size: 16px;

                i {
                    margin-right: 5px;
                }
            }
        }
    }
}

// error section //
.error-section {
    padding: 130px 0 140px;
    text-align: center;

    h1 {
        font-size: 200px;
        font-weight: 900;
        margin: 0;
        line-height: 0.8;
        color: $font-grey;
        margin-top: -11px;
    }

    h2 {
        color: $font-grey;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 40px 0;
    }
}

// breadcrumb section //
.breadcrumb-section {
    background-color: $top-header;
    padding: 30px 0;

    h2 {
        font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .page-title {
        display: flex;
        height: 100%;
        align-items: center;

        h2 {
            font-size: 16px;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .breadcrumb {
        padding-bottom: 0;
        background-color: transparent;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        color: $grey2;
        font-weight: 600;
        margin: 0;
        padding-inline: 0;
        row-gap: 5px;

        a {
            color: $grey2;
            font-weight: 600;
        }
    }
}

.top-banner-wrapper {
    margin-bottom: calc(17px + 11*(100vw - 320px) / 1600);
}

/* about page section */
.about-head {
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    height: 100%;

    h2 {
        color: var(--theme-color);
        margin-bottom: 0;
    }
}

.about-page {
    h4 {
        margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 6px;
        letter-spacing: unset;
        text-transform: unset;
        font-weight: 600;
        line-height: 1.3;
        color: #222;
        font-size: calc(16px + .00625*(100vw - 320px));
    }

    p {
        letter-spacing: 0.03em;
        line-height: 1.7;
        margin-bottom: 0;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
    }
}

.testimonial {
    background-color: $grey-about;

    .testimonial-slider {
        .media {
            img {
                border-radius: 100%;
                border: 1px solid $round-border;
                padding: 5px;
                height: 130px;
                width: 130px;
            }

            h5 {
                font-weight: 700;
                color: var(--theme-color);
                margin-bottom: 0;
                margin-top: 7px;
            }

            h6 {
                margin-bottom: -4px;
            }

            .media-body {
                padding: 30px 75px 30px 30px;

                p {
                    line-height: 2;
                    letter-spacing: 0.03em;
                }
            }
        }
    }
}

.team {
    position: relative;

    img {
        margin: 0 auto;
        padding: 0 10px;
        border: 8px solid #f8f8f8;
    }

    h2 {
        color: $dark-font;
        padding-bottom: 15px;
        border-bottom: 1px solid $border;
        margin-bottom: 10px;
    }

    h4,
    h6 {
        text-align: center;
    }

    h4 {
        color: var(--theme-color);
        font-weight: 700;
        padding-top: 11px;
        padding-bottom: 5px;
        background-color: #f8f8f8;
        margin-bottom: 0;
    }

    h6 {
        margin-bottom: 0;
        background-color: #f8f8f8;
        padding-bottom: 14px;
    }
}

// category page //
.collection-filter-block {
    position: sticky;
    top: 0;
    left: 0;
    border: 1px solid lighten($grey-lighter, 86.5%);
    background-color: $grey-lighter;

    .product-service {
        padding: 30px 0;

        .media {
            padding-bottom: 20px;
            margin-bottom: 20px;

            svg {
                height: 40px;
                width: 40px;
                fill: var(--theme-color);

                path {
                    fill: var(--theme-color);
                }
            }

            .media-body {
                padding-left: 10px;

                h4 {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 600;
                }

                p {
                    margin-bottom: 0;
                    text-transform: capitalize;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.collection-collapse-block {
    padding-bottom: 24px;

    &.open {
        padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 991px) {
            background-color: $white;
        }
    }

    .collection-accordion {
        .accordion-item {
            border-radius: 0;
            background-color: transparent;
            border: none;

            .accordion-header {
                border-radius: 0;

                .accordion-button {
                    z-index: 0;
                    color: $dark-font;
                    padding-inline: 0;
                    box-shadow: unset;
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    background-color: #f9f9f9;
                    border-radius: 0;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }

                    &:not(.collapsed) {
                        font-weight: 600;
                    }

                    &::after {
                        content: "\ea4e";
                        font-family: remixicon;
                        font-style: normal;
                        -webkit-font-smoothing: antialiased;
                        width: auto;
                        height: auto;
                        font-size: 24px;
                        background: unset;
                        font-weight: 100;
                        line-height: 1;
                    }

                    @media (max-width: 991px) {
                        background-color: $white;
                        padding-block: 6px;
                    }
                }
            }

            .accordion-body {
                padding: 0;
                background-color: #f9f9f9;
                max-height: 160px;
                overflow: hidden auto;

                @media (max-width: 991px) {
                    background-color: $white;
                }

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background: var(--theme-color);
                }

                &::-webkit-scrollbar-track {
                    background: #eaeaea;
                }

                .collection-listing {
                    li {
                        width: 100%;
                        margin-bottom: 10px;

                        .form-check {
                            display: flex;
                            min-height: unset;
                            align-items: center;
                            gap: 10px;
                            padding: 0;

                            .form-check-input {
                                width: 20px;
                                height: 20px;
                                background-color: $white;
                                float: unset;
                                margin: 0;
                                cursor: pointer;
                                position: relative;
                                border: unset;
                                filter: unset;
                                z-index: 0;

                                &:focus {
                                    border-color: #dee2e6;
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 21px;
                                    height: 21px;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    left: 50%;
                                    background-color: $white;
                                    border: 2px solid #ececec;
                                    cursor: pointer;
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 12px;
                                    height: 6px;
                                    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                                    transform: rotate(-45deg) scale(0);
                                    left: 4px;
                                    top: 6px;
                                    z-index: 1;
                                    border: 2px solid var(--theme-color);
                                    border-top-style: none;
                                    border-right-style: none;
                                }

                                &:checked {
                                    &::before {
                                        transform: rotate(-45deg) scale(1);
                                    }

                                    ~.form-check-label {
                                        color: var(--theme-color);
                                    }
                                }
                            }

                            .form-check-label {
                                margin-bottom: 0;
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                font-weight: 500;
                                line-height: 1;
                                transition: all 0.1s ease-in-out;
                                position: relative;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                color: #777;

                                .star-rating {
                                    .ri-star-fill {
                                        color: #ffa200;
                                    }

                                    .ri-star-line {
                                        color: #ffa200;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &.price-body {
                    height: auto;
                    padding-bottom: 0;
                }
            }
        }

        &.top-filter-accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        background-color: #f9f9f9;
                    }
                }

                .accordion-body {
                    background-color: #f9f9f9;
                }
            }
        }
    }

    .collapse-block-title {
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        color: lighten($black, 26.5%);
        font-weight: 600;
        margin: 30px 0 0 0;

        &:after {
            position: absolute;
            display: block;
            top: 0;
            right: -3px;
            content: "\f0d7";
            font-family: $font-awesome;
            color: lighten($black, 26.5%);
        }
    }

    .collection-collapse-block-content {
        .color-selector {
            margin-top: 30px;
        }

        .collection-brand-filter {
            .category-list {
                li {
                    display: flex;
                    font-size: 16px;
                    text-transform: uppercase;
                    line-height: 18px;

                    a {
                        color: $grey;
                        padding-bottom: 10px;
                    }

                    &:last-child {
                        a {
                            padding-bottom: 0;
                        }
                    }

                    &:first-child {
                        margin-top: 30px;
                    }
                }
            }

            .collection-filter-checkbox {
                margin-bottom: 12px;

                &:first-child {
                    margin-top: 30px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    text-transform: uppercase;
                    cursor: pointer;
                    font-size: 12px;
                    color: lighten($black, 46.5%);
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    padding-left: 10px;

                    &:before {
                        top: 1px;
                        height: 15px;
                        width: 15px;
                        background: $white;
                        border: 1px solid lighten($black, 46.5%);
                    }

                    &:after {
                        top: 1px;
                        height: 15px;
                        width: 15px;
                        background-size: 65%;
                    }
                }

                .form-check-input {
                    &:checked {
                        ~.form-check-label {
                            &:after {
                                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                            }
                        }
                    }
                }
            }
        }
    }
}

.collection-filter {
    .theme-card {
        .offer-slider {
            img {
                padding: 15px 15px 15px 0;
            }
        }
    }
}

.color-selector {
    ul {
        li {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            border: 1px solid $grey4;
        }

        .color-1 {
            background-color: $white;
        }

        .color-2 {
            background-color: grey;
        }

        .color-3 {
            background-color: $color-red;
        }

        .color-4 {
            background-color: purple;
        }

        .color-5 {
            background-color: blue;
        }

        .color-6 {
            background-color: yellow;
        }

        .color-7 {
            background-color: green;
        }

        li.active {
            border: 1px solid $black;
            position: relative;

            &:after {
                content: "";
                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                top: 9px;
                right: 4px;
                height: 15px;
                width: 15px;
                background-size: 70%;
                background-repeat: no-repeat;
                position: absolute;
                opacity: 0.4;
            }
        }
    }
}

.color-w-name {
    margin-top: 30px;

    ul {
        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: 12px;
            color: $grey;
            font-weight: 600;
            letter-spacing: 0.5px;
            cursor: pointer;

            span {
                display: block;
                height: 26px;
                width: 26px;
                border-radius: 3px;
                margin-right: 5px;
                margin-bottom: 0;
                border: 1px solid $grey;
                margin-right: 10px;
            }
        }

        .color-1 {
            background-color: $white;
        }

        .color-2 {
            background-color: grey;
        }

        .color-3 {
            background-color: red;
        }

        .color-4 {
            background-color: purple;
        }

        .color-5 {
            background-color: blue;
        }

        .color-6 {
            background-color: yellow;
        }

        .color-7 {
            background-color: green;
        }

        li.active {
            span {
                border: 1px solid $grey;
                position: relative;

                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                    top: 7px;
                    right: 3px;
                    height: 15px;
                    width: 15px;
                    background-size: 70%;
                    background-repeat: no-repeat;
                    position: absolute;
                }
            }
        }
    }
}

.collection-sidebar-banner {
    margin-top: 30px;

    img {
        width: 100%;
    }
}

.category-slider-section {
    .product-category-slider {
        &.slick-slider {
            .slick-list {
                margin-inline: calc(-8px + (-10 - -8) * ((100vw - 320px) / (1920 - 320)));

                .slick-slide {
                    >div {
                        padding-inline: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
                        margin-bottom: -6px;
                    }
                }
            }
        }

        .category-box {
            background-color: $top-header;
            margin: 0px auto;
            display: block;
            width: 100%;
            text-align: center;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            border: 1px solid #eee;
            position: relative;
            padding: calc(15px + 13*(100vw - 320px) / 1600) 8px;
            z-index: 0;

            &:hover {
                background-color: var(--theme-color);
                border-color: var(--theme-color);

                img {
                    filter: invert(1) brightness(100);
                }

                h5 {
                    color: $white;
                }
            }

            img {
                width: calc(38px + 17*(100vw - 320px) / 1600) !important;
                height: calc(38px + 17*(100vw - 320px) / 1600);
                object-fit: contain;
                margin: 0 auto;
                transition: all 0.3s ease-in-out;
            }

            h5 {
                color: #555555;
                margin-top: 12px;
                font-weight: 500;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                z-index: 0;
                position: relative;
            }
        }
    }
}

.filter-btn {
    display: none;
    background-color: var(--theme-color);
    padding: calc(6px + 0.0025 * (100vw - 320px)) calc(11px + 11 * (100vw - 320px) / 1600);
    color: $white;
    font-size: calc(15px + 5 * (100vw - 320px) / 1600);
    border: 1px solid transparent;
    font-weight: 600;
    margin-bottom: calc(9px + 11*(100vw - 320px) / 1600);
    text-transform: uppercase;

    &:hover {
        color: var(--theme-color);
        background-color: $white;
        border: 1px solid var(--theme-color);
    }

    @media (max-width: 991px) {
        display: inline-block;
    }
}

.top-banner-wrapper {
    .top-banner-content {
        h4 {
            font-weight: 600;
            color: lighten($black, 26.5%);
            margin-bottom: 15px;
        }

        h5 {
            font-size: 16px;
            font-weight: 600;
            color: lighten($black, 26.5%);
            letter-spacing: 1px;
            line-height: 1.2;
            margin-bottom: 10px;
        }

        p {
            line-height: 1.5;
            color: lighten($black, 46.5%);
            margin-bottom: 0;
            font-size: 16px;
            letter-spacing: 0.05em;
        }
    }
}

.product-wrapper-grid.list-view {
    .product-wrap {
        .product-info {
            text-align: left;
            align-self: center;
            padding-left: 15px;
        }
    }

    .product-box {
        display: flex;
        padding-bottom: 0;

        .img-wrapper,
        .img-block {
            width: 25%;
        }

        .product-detail {
            padding-left: 15px;
            align-self: center;
            text-align: left !important;

            .rating {
                margin-top: 0;
            }

            p {
                display: block !important;
                margin-bottom: 5px;
                line-height: 23px;
                font-size: 16px;
            }

            .color-variant {
                padding-top: 10px;
            }

            h6 {
                font-weight: 700;
            }
        }
    }

    .basic-product {
        .overflow-hidden {
            display: flex;
            align-items: center;
            gap: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 575px) {
                display: block;
            }
        }

        .img-wrapper {
            width: calc(130px + (300 - 130) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 575px) {
                width: 100%;
            }
        }

        .product-detail {
            width: calc(100% - calc(130px + (300 - 130) * ((100vw - 320px) / (1920 - 320))) - calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))));

            @media (max-width: 575px) {
                width: 100%;
            }

            .color-panel,
            .offer-panel {
                display: none;
            }

            >div {
                border-bottom: none;
            }

            h6 {
                margin-bottom: 0;

                @media (max-width: 575px) {
                    margin-bottom: 5px;
                }
            }

            p {
                display: block !important;
                font-size: 14px;
                margin-bottom: 12px;

                @media (max-width: 767px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 575px) {
                    display: none !important;
                }
            }
        }
    }
}

.collection-product-wrapper {
    .product-top-filter {
        margin-block: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 15px;
        padding: 12px 16px;
        background-color: $top-header;
        position: relative;
        border: 1px solid $white-dark;

        .product-filter-content {
            .form-select {
                width: 180px;
                height: calc(40px + (48 - 40) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 0;
                color: #777777;
                background-color: $white;
                border: 1px solid $white-dark;
                cursor: pointer;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            @media (max-width: 991px) {
                >div {
                    width: 100%;
                }
            }

            @media (max-width: 575px) {
                >div {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            ul {
                li {
                    margin: 0 !important;
                    width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white;
                    border: 1px solid $round-border;
                    cursor: pointer;

                    &.active {
                        background-color: var(--theme-color);

                        img {
                            filter: invert(1) brightness(100);
                        }
                    }
                }
            }

            &.sidebar-popup-filter-content {
                display: contents;
                width: 100%;

                @media (max-width: 767px) {
                    >div {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .collection-grid-view {
                        display: none;
                    }
                }
            }
        }

        .product-filter-content,
        .popup-filter {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .search-count,
            .sidebar-popup {
                text-transform: capitalize;

                h5 {
                    margin-bottom: 0;
                }
            }

            .collection-view {
                padding: 20px;
                width: 10%;

                ul {
                    li {
                        height: 100%;
                        vertical-align: middle;
                        cursor: pointer;

                        &:first-child {
                            margin-right: 14px;
                        }
                    }
                }
            }

            .collection-grid-view {
                margin-left: auto;

                ul {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    li {
                        width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $white;
                        border: 1px solid $white-dark;
                        cursor: pointer;

                        &.active {
                            background-color: var(--theme-color);
                            border: transparent;
                        }

                        img {
                            height: 18px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .product-page-per-view,
            .product-page-filter {
                position: relative;
                width: 20%;

                select {
                    border: 0;
                    padding: 20px;
                    border-right: 1px solid lighten($black, 86.5%);
                    width: 100%;
                    appearance: none;
                    appearance: none;
                    cursor: pointer;
                    background: url(../images/dropdown.png) no-repeat 95%;

                    &:focus {
                        outline: unset;
                    }

                    option {
                        padding: 20px 0;
                    }
                }
            }

            .product-page-filter {
                width: 15%;

                &:before {
                    right: 10px;
                }

                select {
                    border-right: none;
                }
            }
        }

        .popup-filter {
            justify-content: unset;

            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-track {
                background: $round-border;
            }

            ::-webkit-scrollbar-thumb {
                background: $grey5;
            }

            .sidebar-popup {
                position: relative;
                cursor: pointer;

                a {
                    color: #777777;
                    background: $white;
                    border: 1px solid $white-dark;
                    height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
                    width: 180px;
                    font-size: 16px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: capitalize;
                }

                .collection-filter {
                    background-color: $top-header;
                    width: 270px;
                    height: 600px;
                    overflow-y: scroll;

                    .collection-filter-block {
                        border: none;
                    }

                    .theme-card {
                        padding: 0 30px;
                    }

                    .collection-sidebar-banner {
                        padding: 0 30px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .open-popup {
                display: none;
                position: absolute;
                z-index: 9;
                top: 75px;
                left: 0;
                border: 1px solid $border-grey;
                box-shadow: 0 0 5px $round-border;
                max-width: 300px;

                &.open {
                    display: block;
                }

                .collection-filter-block {
                    margin-bottom: 0;
                }
            }

            .product-page-per-view,
            .product-page-filter {
                width: 20%;

                select {
                    padding-right: 45px;
                    font-size: 16px;
                }

                &:before {
                    right: 35px;
                }
            }

            .collection-grid-view {
                padding: 20px;
            }

            .collection-view {
                width: 20%;
            }

            .product-page-filter {
                &:before {
                    right: 25px !important;
                }
            }
        }

        &.sidebar-top-filter {
            position: relative;

            @media (max-width: 767px) {
                display: flex;
                flex-wrap: wrap;

                .popup-filter {
                    width: 100%;

                    .sidebar-popup {
                        width: 100%;

                        a {
                            width: 100%;
                            justify-content: left;
                            padding-left: 10px;
                        }
                    }
                }

                .sidebar-popup-filter-content {
                    .form-select {
                        width: 100%;
                    }
                }
            }

            .collection-top-filter {
                position: absolute;
                top: 85px;
                left: 0;
                width: 100%;
                z-index: 1;

                .top-filter-card-body {
                    background-color: #f9f9f9;
                    border: 1px solid $white-dark;
                    border-radius: 0;
                }

                @media (max-width: 767px) {
                    top: 60px;
                }
            }
        }
    }

    .product-wrapper-grid {
        .product-box {
            margin-top: 50px;

            .product-detail {
                p {
                    display: none;
                }
            }
        }

        .basic-product {
            .product-detail {
                p {
                    display: none;
                }
            }
        }

        .product-five {
            flex: 0 0 20%;
            max-width: 20%;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }

        .col-lg-2 {
            .product-box {
                .product-detail {
                    h6 {
                        font-size: 13px;
                    }

                    h4 {
                        font-size: 18px;
                    }

                    .rating {
                        i {
                            padding-right: 0;
                        }
                    }
                }

                .img-wrapper {
                    .lable-block {
                        .lable3 {
                            padding: 8px 4px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.filter-main-btn {
    display: none;
    margin-bottom: 20px;
    cursor: pointer;

    span {
        width: auto;
        border-radius: 0;
        font-size: 18px;
        background-color: var(--theme-color);
        padding: 5px 16px;
        color: $white;
        margin-bottom: 20px;
        display: inline-block;
        text-transform: uppercase;
    }
}

.collection-mobile-back {
    padding: 15px 0;
    border-bottom: 1px solid $white-dark;
    display: none;
    border: none;
    width: 100%;

    &:active {
        border-bottom: 1px solid $white-dark;
    }

    i {
        font-size: 23px;
        line-height: 1;
        font-weight: 500;
    }

    span {
        text-transform: uppercase;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: #222;
        font-weight: 700;
        line-height: 1;
        margin-top: 3px;
        display: flex;
        align-items: center;
        gap: 4px;
        flex-direction: row-reverse;
    }
}

.product-pagination {
    margin-top: 50px;

    .pagination {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .page-item {
            border-radius: 0;

            a {
                border: 1px solid $white-dark;
                color: #777777;
                background-color: $white;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                box-shadow: none;
                width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
                height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
            }

            &.active {
                a {
                    color: $white;
                    background-color: var(--theme-color);
                    border: 1px solid $white-dark;
                }
            }

            &:hover {
                a {
                    color: $white;
                    background-color: var(--theme-color);
                }
            }

            &:last-child {
                .page-link {
                    border-radius: 0;
                }
            }
        }
    }

    .product-search-count-bottom {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid lighten($black, 86.5%);
        border-right: 1px solid lighten($black, 86.5%);
        padding-right: 15px;

        h5 {
            color: lighten($black, 46.5%);
            font-size: 14px;
            margin-bottom: 0;
            padding: 5px 0;
        }
    }
}

.bundle {
    margin-top: 10px;

    .bundle_img {
        display: flex;

        .img-box {
            img {
                max-width: 115px;
                border: 1px solid $round-border;
                padding: 2px;
            }
        }

        .plus {
            display: flex;
            align-items: center;
            padding: 0 7px;
            font-size: 22px;
        }
    }

    .bundle_detail {
        margin-top: 15px;

        .price_product {
            color: $black;
            padding-left: 3px;
            font-weight: 700;
        }
    }
}

.theme_checkbox {
    label {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
        text-transform: capitalize;
        color: $font-color;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
                ~.checkmark {
                    &:after {
                        display: block;
                    }
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: $white;
            border: 1px solid $round-border;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid $black;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
}

.sticky-top-cls {
    position: sticky;
    top: 20px;
    z-index: 1;
}

.modern-box-margin {
    margin-bottom: -40px;
}

.modern-box {
    padding: 25px 25px;
    background-color: $top-header;
    margin-top: 94px;
    margin-bottom: 40px;

    .absolute-img {
        img {
            margin-left: 30px;
            margin-top: -120px;
            transition: all 0.3s ease-in-out;
        }
    }

    .modern-content {
        margin-top: 14px;

        h6 {
            font-size: 16px;
            margin-bottom: 2px;
            font-weight: 600;
            color: $dark-font;
        }

        p {
            line-height: 20px;
            letter-spacing: 0.05em;
            margin-bottom: 14px;
        }

        .modern-bottom {
            display: flex;

            .right {
                margin-left: auto;

                .add-extent {
                    position: relative;

                    .animated-btn {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: #e8e8e8;
                        transition: all 0.3s ease-in-out;
                        color: $dark-font;
                    }

                    .options {
                        position: absolute;
                        right: -6px;
                        bottom: 50%;
                        background-color: $white;
                        padding: 10px 5px;
                        border-radius: 20px;
                        box-shadow: 0px 5px 7px 1px rgba($black, 0.09);
                        height: 0;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            width: 30px;
                            height: 30px;
                            border-radius: 100%;
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            button {
                                border: none;
                                background-color: transparent;
                            }

                            i {
                                color: $grey6;
                                font-size: 18px;
                            }

                            +li {
                                margin-top: 6px;
                            }
                        }
                    }

                    &.show {
                        i {
                            transform: rotate(45deg);
                            transition: all 0.3s ease-in-out;
                        }

                        .options {
                            opacity: 1;
                            visibility: visible;
                            height: auto;
                            bottom: 38px;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

.modern-box-margin {
    margin-bottom: -40px;
}

.modern-box {
    padding: 25px 25px;
    background-color: $top-header;
    margin-top: 94px;
    margin-bottom: 40px;

    .absolute-img {
        img {
            margin-left: 30px;
            margin-top: -120px;
            transition: all 0.3s ease-in-out;
        }
    }

    .modern-content {
        margin-top: 14px;

        h6 {
            font-size: 16px;
            margin-bottom: 2px;
            font-weight: 600;
            color: $dark-font;
        }

        p {
            line-height: 20px;
            letter-spacing: 0.05em;
            margin-bottom: 14px;
        }

        .modern-bottom {
            display: flex;

            .left {
                h4 {
                    margin-bottom: 10px;
                }
            }

            .right {
                margin-left: auto;

                .add-extent {
                    position: relative;

                    .animated-btn {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: #e8e8e8;
                        transition: all 0.3s ease-in-out;
                        color: $dark-font;
                    }

                    .options {
                        position: absolute;
                        right: -6px;
                        bottom: 50%;
                        background-color: $white;
                        padding: 10px 5px;
                        border-radius: 20px;
                        box-shadow: 0px 5px 7px 1px rgba($black, 0.09);
                        height: 0;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            width: 30px;
                            height: 30px;
                            border-radius: 100%;
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            button {
                                border: none;
                                background-color: transparent;
                            }

                            i {
                                color: $grey6;
                                font-size: 18px;
                            }

                            +li {
                                margin-top: 6px;
                            }
                        }
                    }

                    &.show {
                        i {
                            transform: rotate(45deg);
                            transition: all 0.3s ease-in-out;
                        }

                        .options {
                            opacity: 1;
                            visibility: visible;
                            height: auto;
                            bottom: 38px;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

// collection page //
.collection {
    .collection-block {
        text-align: center;

        img,
        .bg-size {
            margin-bottom: 20px;
        }

        .collection-content {
            text-align: center;

            h3 {
                margin-bottom: 10px;
                color: $dark-font;
                text-transform: uppercase;
                font-weight: 900;
                letter-spacing: 0.05em;
            }

            h4 {
                color: $dark-font;
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                line-height: 1.5;
            }

            .btn-solid,
            .btn-outline {
                padding: 10px 25px;
            }
        }
    }
}

.category-shop-section {
    .nav {
        height: 100%;
        background-color: $top-header;
        padding: 13px 0;
        border-radius: 8px;

        .nav-link {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            transition: all 0.25s ease-in-out;
            color: #495057;
            border-right: 2px solid transparent;
            cursor: pointer;
            padding: 12px 20px;
            font-weight: 500;
            text-transform: capitalize;

            &.active {
                color: var(--theme-color);
                border-right: 2px solid var(--theme-color);
                font-weight: 700;
            }
        }
    }
}

//faq //
.faq-section {
    .theme-accordion {
        .accordion-item {
            background-color: transparent;
            margin-top: 10px;
            border-radius: 0;
            overflow: hidden;
            border: 1px solid rgba($black, 0.07);

            &:first-child {
                margin-top: 0;
            }

            .accordion-header {
                padding: 0;
                border-bottom: 0;
                transition: all 0.3s ease;
                background-color: $grey-lighter;
                letter-spacing: unset;
                text-transform: unset;

                .accordion-button {
                    color: #333;
                    font-size: calc(16px + .00125*(100vw - 320px));
                    padding: calc(11px + 7*(100vw - 320px) / 1600);
                    line-height: 1.4;
                    font-weight: 500;
                    text-transform: unset;
                    text-decoration: unset;
                    box-shadow: unset;
                    background-color: #f8f8f8;
                    border: unset;
                    letter-spacing: unset;
                    transition: all .3s ease;
                    width: 100%;
                    transition: all 0.3s ease;
                    text-align: unset;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: "\ea4e";
                        @include font;
                        font-size: 22px;
                        background: none;
                        line-height: 1;
                        transition: all 0.3s ease;
                        width: auto;
                        height: auto;
                    }

                    &::before {
                        content: none;
                    }

                    &:hover {
                        color: var(--theme-color);
                    }

                    &:not(.collapsed) {
                        font-weight: 600;
                        color: var(--theme-color);
                    }
                }
            }

            .accordion-body {
                padding: calc(10px + 5*(100vw - 320px) / 1600) calc(12px + .00375*(100vw - 320px));

                p {
                    margin-bottom: 0;
                    font-size: calc(14px + .00125*(100vw - 320px));
                    color: #777;
                    line-height: 1.7;
                    letter-spacing: unset;
                    font-weight: 500;
                }
            }
        }
    }
}

//forget_pwd//
.pwd-page {
    text-align: center;

    h2 {
        margin-bottom: 25px;
        color: $dark-font;
        margin-top: -5px;
    }

    .theme-form {
        input {
            border-color: $round-border;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: center;
        }

        .btn-solid,
        .btn-outline {
            margin: 0 auto;
        }
    }
}

//login//
.login-page {
    h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $dark-font;
        font-weight: 700;
        margin-top: -5px;
    }

    .theme-card {
        padding: 30px;
        background-color: #f8f8f8;
        line-height: 1;
        border: 1px solid #eee;

        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 14px;
                font-weight: 600;
            }

            input {
                border-color: #eaeaea;
                font-size: 12px;
                padding: 17px 25px;
                margin-bottom: 30px;
                height: inherit;
            }
        }

        .form-box {
            position: relative;

            .form-label {
                font-weight: 500;
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 4px;
                color: #777;
            }

            .form-control {
                height: auto;
                padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                border: 1px solid $white-dark;
                font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1920 - 320)));

                &::-webkit-inner-spin-button {
                    display: none;
                }
            }

            .form-select {
                height: auto;
                padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
                padding-right: 2.25rem;
                font-weight: 500;
                border: 1px solid $white-dark;
                font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 0;

                option {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    font-weight: 500;
                }
            }
        }
    }

    .authentication-right {
        height: calc(100% - 48px);

        h6 {
            text-transform: capitalize;
            color: #333;
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 22px;
        }

        p {
            margin-bottom: 21px;
            font-size: calc(14px + .00125*(100vw - 320px));
            color: #777;
            line-height: 1.7;
            letter-spacing: unset;
            font-weight: 500;
        }
    }
}

// register page //
.register-page {
    h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $dark-font;
        font-weight: 700;
        margin-top: -5px;
    }

    .theme-card {
        padding: 30px;
        border: none;
        background-color: $top-header;
        box-shadow: 0 0 2px 2px rgb(241, 241, 241);
        line-height: 1;

        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 14px;
                font-weight: 600;
            }

            input {
                border-color: #eaeaea;
                font-size: 12px;
                padding: 17px 25px;
                margin-bottom: 30px;
                height: inherit;
            }
        }
    }
}

// search //
.search-block {
    padding: 0;

    .form-header {
        .input-group {
            input {
                border-radius: 0 5px 5px 0;
                border: 1px solid #eee;
                padding: 15px 20px;
            }

            i {
                padding-right: 10px;
            }

            .btn {
                z-index: 0;
            }
        }
    }
}

// contacts //
.contact-page {
    .contact-title {
        position: sticky;
        top: 0;
        left: 0;

        h2 {
            letter-spacing: unset;
            text-transform: none;
            margin: 0;
            line-height: 1.3;
        }

        p {
            font-size: calc(14px + .00125*(100vw - 320px));
            line-height: 1.7;
            width: 100%;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .contact-form {
        background-color: $top-header;
        padding: calc(20px + (44 - 20) * ((100vw - 320px) / (1920 - 320))) calc(16px + (44 - 16) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 0;
        border: 1px solid $white-dark;

        .form-box {
            .form-control {
                border: unset;

                &::placeholder {
                    color: #8a8a8a;
                }
            }

            label {
                color: #333333;
            }
        }
    }

    .contact-right {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: calc(17px + (23 - 17) * ((100vw - 320px) / (1920 - 320)));
            margin-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 1200px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }

            li {
                width: 100%;
                padding: 15px;
                background-color: $top-header;
                border: 1px solid $white-dark;
                border-radius: 0;
                display: flex;
                gap: 11px;

                .contact-icon {
                    i {
                        font-size: 23px;
                        color: var(--theme-color);
                        width: 50px;
                        height: 50px;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        border-radius: 0;
                        justify-content: center;
                    }
                }

                .media-body {
                    h6 {
                        font-weight: 600;
                        color: $dark-font;
                        font-size: 18px;
                        line-height: 1.4;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    p {
                        font-weight: 500;
                        margin-bottom: 0;
                        line-height: 1.5;
                        font-size: 15px;
                        margin-top: 3px;
                    }
                }
            }
        }
    }

    .form-control {
        border-radius: 0;
    }
}

.map-section {
    height: 420px;
}

// shopping cart //
.qty-box {
    .input-group {
        justify-content: center;

        span {
            button {
                background: $white !important;
                border: 1px solid $silver-light-shade;
            }
        }

        .form-control {
            text-align: center;
            width: 80px;
            flex: unset;
        }

        button {
            background-color: transparent;
            border: 0;
            color: $grey;
            cursor: pointer;
            padding-left: 12px;
            font-size: 12px;
            font-weight: 900;
            line-height: 1;

            i {
                font-size: 16px;
                color: $dark-font;
            }
        }

        .icon {
            padding-right: 0;
        }
    }
}

.order-history {
    .responsive-data {
        display: none;

        .price {
            margin-bottom: 2px;
        }
    }

    .dark-data {
        color: black;
        font-weight: 600;
    }
}

.wishlist-section {
    tbody {
        tr {
            &:last-child {
                td {
                    border: unset !important;
                }
            }

            td {
                .icon-box {
                    a {
                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                        border: 1px solid $white-dark;
                        width: calc(28px + (32 - 28) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(28px + (32 - 28) * ((100vw - 320px) / (1920 - 320)));
                        border-radius: 0;
                        background-color: $top-header;
                        color: $grey;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .mobile-cart-content {
                    @media (max-width: 767px) {
                        .col {
                            &:last-child {
                                .td-color {
                                    display: flex;
                                    align-items: center;
                                    gap: calc(7px + (11 - 7) * ((100vw - 320px) / (767 - 320)));

                                    a {
                                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (767 - 320)));
                                        border: 1px solid $white-dark;
                                        width: calc(28px + (32 - 28) * ((100vw - 320px) / (767 - 320)));
                                        height: calc(28px + (32 - 28) * ((100vw - 320px) / (767 - 320)));
                                        border-radius: 0;
                                        margin: 0 auto;
                                        background-color: $top-header;
                                        color: $grey;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cart-section,
.wishlist-section {
    .table {
        tbody {
            +tbody {
                border-top: none;
            }
        }
    }

    .cart-buttons {
        padding-top: 35px;

        >div {
            &:last-child {
                text-align: right;
            }
        }

        a {
            background-color: var(--theme-color);
            color: $white;
            text-transform: none;
            font-weight: 600;
            font-size: calc(16px + 2 * (100vw - 320px) / 1600);
            line-height: 1.3;
            padding: calc(9px + 2 * (100vw - 320px) / 1600) 20px;
            border-radius: 0;
            border: 1px solid transparent;

            &:hover {
                border-color: var(--theme-color);
                background-color: $white;
                color: var(--theme-color);
            }
        }
    }

    .wishlist-buttons {
        padding-top: 22px;
        text-align: right;

        &:last-child {
            a {
                margin-left: 10px;
            }
        }
    }

    .cart-table {
        overflow: hidden;
        margin-bottom: 0;
        border: 1px solid $white-dark;

        thead {
            th {
                font-weight: 600;
                color: $dark-font;
                font-size: calc(17px + (19 - 17) * ((100vw - 320px) / (1920 - 320)));
                border-top: 0;
                text-align: center;
                white-space: nowrap;
                text-transform: capitalize;
                padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 12px;
                background-color: $top-header;
                border-bottom: 1px solid #eee !important;
            }
        }
    }

    tbody {
        h2 {
            margin-bottom: 0;
        }

        .remove-btn {
            font-size: calc(18px + 2 * (100vw - 320px) / 1600);
            border: 1px solid $white-dark;
            width: calc(28px + 4 * (100vw - 320px) / 1600);
            height: calc(28px + 4 * (100vw - 320px) / 1600);
            border-radius: 0;
            background-color: $top-header;
            color: $grey;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }

        .table-price {
            h2 {
                color: $dark-font;
                font-weight: 600;
                font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                white-space: nowrap;

                del {
                    font-weight: 500;
                    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                    color: $grey;
                }
            }

            h6 {
                font-size: 18px;
                margin-block: 4px 0;
                font-weight: 600;
                color: var(--theme-color);
            }
        }

        .qty-box {
            .input-group {
                justify-content: center;
                flex-wrap: nowrap;

                .form-control {
                    padding-block: 10px;
                    border: 1px solid $white-dark;
                    font-weight: 500;
                }

                button {
                    border: 1px solid $white-dark;
                    font-size: 16px;
                    z-index: 0;

                    i {
                        font-weight: 500;
                    }
                }
            }
        }

        tr {
            td {
                vertical-align: middle;
                color: $grey;
                border-top: 0;
                border-bottom: 1px solid $white-dark;
                text-align: center;

                &:nth-child(2) {
                    width: 29rem;

                    @media (max-width: 767px) {
                        width: auto;
                    }
                }

                .name {
                    color: #222;
                    font-weight: 500;
                }

                a,
                p {
                    color: $grey;
                    font-weight: 500;
                    font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    margin-bottom: 0;

                    img {
                        height: 90px;
                    }
                }

                .td-color {
                    color: var(--theme-color);
                }

                h2 {
                    color: var(--theme-color);
                    font-size: calc(19px + 3 * (100vw - 320px) / 1600);
                    font-weight: 500;
                    letter-spacing: unset;
                    text-transform: unset;

                    del {
                        font-weight: 500;
                        color: $grey;
                        font-size: 16px;
                    }
                }

                .mobile-cart-content {
                    display: none;
                    justify-content: center;
                    margin-top: 10px;
                    flex-wrap: nowrap;

                    .qty-box {
                        border-radius: 0;

                        .input-group {
                            .form-control {
                                width: 48px;
                                padding: 6px;
                            }
                        }
                    }

                    .col {
                        align-self: center;
                        margin-left: 10px;
                        margin-right: 10px;

                        @media (max-width: 768px) {
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    tfoot {
        tr {
            th {
                padding-top: 35px;
                text-align: right;
            }

            td {
                padding-top: 20px;
                padding-bottom: 20px;
                border: none;
                text-align: center;
                color: $dark-font;
                font-size: 17px;
                font-weight: 500;
                white-space: nowrap;

                h2 {
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 0;
                }

                &:first-child {
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 18px;
                    color: $grey;
                    text-align: right;
                }
            }
        }
    }
}

.cart_counter {
    display: flex;
    align-items: center;
    background: $top-header;
    padding: 15px;
    font-size: 14px;
    color: #2c2c2c;
    font-weight: bold;
    border-radius: 0;
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 0 0 4px 0 #e4e4e4;

    .countdownholder {
        span {
            padding-left: 8px;
            color: var(--theme-color);
            padding-right: 6px;
        }
    }

    .cart_checkout {
        padding: 3px 8px;
        margin-left: 10px;
    }
}

// blog-details //
.blog-detail-page {
    .blog-detail {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            margin-bottom: 40px;
        }

        h3 {
            color: $dark-font;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        p {
            color: $grey;
            line-height: 1.8;
        }

        .post-social {
            color: $grey;
            text-align: left;
            padding-bottom: 15px;

            li {
                &+li {
                    padding-left: 15px;
                    margin-left: 15px;
                    border-left: 1px solid $round-border;
                }
            }
        }
    }

    .blog-advance {
        >div {
            &:last-child {
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        img,
        .bg-size {
            margin-bottom: 25px;
            margin-top: 25px;
        }

        ul {
            list-style-type: decimal;
            list-style-position: inside;
            line-height: 2.5;
            font-size: 15px;

            li {
                display: list-item;
                font-weight: 700;
            }
        }

        p {
            line-height: 1.8;
        }
    }

    .comment-section {
        border-bottom: 1px solid $round-border;

        li {
            padding-top: 60px;
            padding-bottom: 60px;
            border-top: 1px solid $round-border;

            img {
                height: 65px;
                width: 65px;
                margin-right: 20px;
                border-radius: 100%;
            }

            h6 {
                color: $grey4;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 5px;

                span {
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: capitalize;
                    color: $grey;
                    margin-left: 20px;
                }
            }

            p {
                line-height: 1.8;
                margin-bottom: 0;
            }
        }
    }

    .blog-contact {
        h2 {
            margin-bottom: 30px;
            margin-top: -5px;
        }

        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 16px;
                font-weight: 700;
            }

            input,
            textarea {
                border-color: $round-border;
                font-size: 14px;
                padding: 17px 25px;
                margin-bottom: 30px;
            }
        }
    }
}

// blog-page //
.blog-page {
    .blog-sidebar {
        h6 {
            margin-bottom: 0;
            line-height: 20px;
            color: $font-color;
            font-weight: 700;
        }

        p {
            line-height: 20px;
            margin-bottom: 0;
            color: $grey2;
        }

        h4 {
            color: $font-color;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 20px;
            letter-spacing: 1px;
        }

        .theme-card {
            padding: 20px;
            background-color: $grey-lighter;

            .recent-blog {
                li {
                    .media {
                        img {
                            width: 100px;
                            margin-right: 10px;
                        }
                    }

                    &+li {
                        margin-top: 30px;
                    }
                }
            }

            .popular-blog {
                li {
                    .blog-date {
                        background-color: var(--theme-color);
                        height: 70px;
                        width: 70px;
                        margin: 0 15px 0 0;
                        padding: 15px;

                        span {
                            display: block;
                            text-align: center;
                            color: $white;
                            text-transform: uppercase;
                        }
                    }

                    &+li {
                        margin-top: 30px;
                    }

                    p {
                        margin-top: 10px;
                    }
                }
            }

            &+.theme-card {
                margin-top: 30px;
            }
        }
    }

    .blog-media {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .blog-right {
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;

            h6 {
                color: var(--theme-color);
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            h4 {
                color: $font-color;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 15px;
            }

            p {
                line-height: 1.8;
                margin-bottom: 0;
                letter-spacing: 0.04em;
            }

            ul {
                color: $grey;
                margin-bottom: 15px;

                li {
                    font-size: 14px;
                    line-height: 1.5;

                    i {
                        padding-right: 3px;
                    }

                    &+li {
                        padding-left: 15px;
                        margin-left: 15px;
                        border-left: 1px solid $round-border;
                    }
                }
            }
        }
    }
}

.media-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--theme-color);
    color: $white;
    width: 40px;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 22px;
    border: none;
}

// product page //
.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {

    .slick-prev,
    .slick-next {
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        margin-top: 0;
        box-shadow: 0 0 8px #ddd9;
        border-radius: 100%;

        &:before {
            @include font;
            opacity: 1;
            color: #222;
            background-color: $white;
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .slick-next {
        right: -15px;

        &:before {
            content: "\ea6e";
        }
    }

    .slick-prev {
        left: -15px;
        z-index: 1;

        &:before {
            content: "\ea64";
        }
    }

    .slick-slide {
        >div {
            display: flex;
            margin-bottom: 15px;
        }
    }
}

.slider-right-nav {
    &.gap-low {
        .slick-slide {
            >div {
                margin: 10px 10px 0 10px;
            }
        }
    }
}

.slider-nav {
    .slider-image {
        border: 1px solid #eeeeee;
        background-color: #f8f8f8;
        overflow: hidden;
        height: calc(123px + .02375*(100vw - 320px));
    }
}

.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
    .slick-slide {
        >div {
            display: flex;
            margin: 10px 10px 0 10px;
            border: 1px solid $white-dark;
            transition: all 0.3s ease-in-out;
            opacity: 0.4;

            img {
                height: calc(80px + (160 - 80) * ((100vw - 320px) / (1920 - 320)));
                width: 100%;
                object-fit: contain;
                cursor: pointer;
                background-color: rgba(212, 215, 212, 0.2);
            }
        }

        &.slick-current {
            >div {
                transition: all 0.3s ease-in-out;
                opacity: 1;

                img {
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }
            }
        }

        &.slick-active {
            >div {
                transition: all 0.3s ease-in-out;
                opacity: 1;

                img {
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }
            }
        }
    }
}

.slider-right-nav {
    &.border-nav {
        .slick-slide {
            >div {
                padding: 4px;
            }
        }
    }
}

.slider-right-nav,
.rtl-slider-right-nav {
    .slick-slide {
        &:first-child {
            >div {
                margin-top: 0;
            }
        }
    }
}

.pro_sticky_info {
    border: 1px solid $round-border;
    padding: 20px;
}

.is_stuck {
    margin-top: 80px;
}

.image-scroll {
    div {
        >div {
            padding-bottom: 20px;

            &:last-child {
                padding: 0;
            }
        }
    }
}

.sale-label {
    display: inline-block;
    background-color: var(--theme-color2);
    padding: 6px 17px;
    border-radius: 8px;
    color: $white;
}

.product-right {
    .product-count {
        background-color: $top-header;
        padding: 10px 12px;
        margin-bottom: 12px;

        img {
            margin-right: 6px;
        }

        ul {
            margin-bottom: -4px;

            li {
                margin-bottom: 4px;

                &:first-child {
                    margin-right: 14px;
                }
            }
        }
    }

    p {
        margin-bottom: 0;
        line-height: 1.5em;
    }

    .product-title {
        color: $dark-font;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 3px;
        font-size: 16px;
    }

    .shipping-info {
        li {
            display: block;
            font-size: 16px;
            color: $grey;
            line-height: 1.8;
        }
    }

    .border-product {
        padding-top: 15px;
        padding-bottom: 20px;
        border-top: 1px dashed $white-dark;
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 25px;
        line-height: 1.2em;
    }

    .price-detail {
        span {
            font-size: 16px;
            color: var(--theme-color);
            padding-left: 10px;
        }
    }

    h3 {
        font-size: 26px;
        color: $dark-font;
        margin-bottom: 15px;
    }

    h4 {
        font-size: 16px;
        margin-bottom: 7px;

        del {
            color: $grey;
        }

        span {
            padding-left: 5px;
            color: var(--theme-color);
        }
    }

    .color-variant {
        margin-bottom: 10px;

        li {
            height: 30px;
            width: 30px;
            cursor: pointer;

            &.active {
                position: relative;

                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                    top: 10px;
                    right: 4px;
                    height: 15px;
                    width: 15px;
                    background-size: 70%;
                    background-repeat: no-repeat;
                    position: absolute;
                    opacity: 0.8;
                }
            }
        }
    }

    .product-buttons {
        margin-bottom: 20px;

        .btn-solid,
        .btn-outline {
            padding: 7px 25px;
        }

        a {
            &:last-child {
                margin-left: 10px;
            }
        }
    }

    .product-description {
        h6 {
            span {
                float: right;
            }
        }

        .qty-box {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .input-group {
                justify-content: unset;
                width: unset;

                .form-control {
                    border-right: none;
                }
            }
        }
    }

    .size-box {
        margin-top: 10px;
        margin-bottom: 10px;

        ul {
            li {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                border: 1px solid $grey3;
                text-align: center;

                a {
                    color: $dark-font;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                &.active {
                    background-color: $grey3;
                }
            }
        }
    }

    .product-icon {
        display: flex;

        .product-social {
            margin-top: 5px;

            li {
                padding-right: 30px;

                a {
                    color: $font-color;
                    transition: all 0.3s ease;

                    i {
                        font-size: 18px;
                    }

                    &:hover {
                        color: var(--theme-color);
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .wishlist-btn {
            background-color: transparent;
            border: none;

            i {
                border-left: 1px solid $round-border;
                font-size: 18px;
                padding-left: 10px;
                margin-left: 5px;
                transition: all 0.3s ease-in-out;
            }

            span {
                padding-left: 10px;
                font-size: 18px;
            }

            &:hover {
                i {
                    color: var(--theme-color);
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    .payment-card-bottom {
        margin-top: 10px;

        ul {
            li {
                padding-right: 10px;
            }
        }
    }

    .timer {
        margin-top: 10px;
        background-color: $grey-about;

        p {
            color: $dark-font;
        }
    }

    .rating-section {
        margin-bottom: 8px;
        margin-top: -6px;
        display: flex;
        align-items: center;

        h6 {
            margin-bottom: 0;
            margin-left: 10px;
            color: #323232;
            font-weight: 700;
            font-size: 15px;
        }

        .rating {
            i {
                padding-right: 2px;
                font-size: 18px;
            }
        }
    }

    .label-section {
        margin-bottom: 15px;

        .badge {
            padding: 6px 11px;
            font-size: 12px;
        }

        .label-text {
            text-transform: capitalize;
            padding-left: 5px;
        }
    }

    &.product-form-box {
        text-align: center;
        border: 1px solid $round-border;
        padding: 20px;

        .product-description {
            .qty-box {
                margin-bottom: 5px;

                .input-group {
                    justify-content: center;
                    width: 100%;
                }
            }
        }

        .product-buttons {
            margin-bottom: 0;
        }

        .timer {
            margin-bottom: 10px;
            text-align: left;
        }
    }
}

.single-product-tables {
    display: flex;
    margin-top: 10px;

    table {
        width: 30%;

        tr {
            height: 35px;
            color: #6d6d6d;
            font-size: 16px;
            letter-spacing: 0.01em;

            td:nth-child(2) {
                font-weight: 500;
                color: black;
            }
        }
    }

    &.detail-section {
        margin-top: 0;

        table {
            width: 55%;
        }
    }
}

.product-accordion {
    .btn-link {
        color: $dark-font;

        &:hover {
            text-decoration: none;
        }
    }

    .card {
        border: none;
    }

    .card-body {
        width: 100%;
        height: auto;
    }

    .card-header {
        padding: 5px 8px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
        background-color: rgba(127, 127, 127, 0.03);
    }
}

.product-related {
    h2 {
        text-transform: capitalize;
        font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $dark-font;
        padding-bottom: 20px;
        border-bottom: 1px solid $white-dark;
        margin-bottom: 20px;
    }
}

.rating {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    i {
        padding-right: calc(0px + .00125*(100vw - 320px));
        font-size: calc(14px + .0025*(100vw - 320px));
        color: $star-yellow;

        &.fill {
            &::before {
                content: "\f186";
            }
        }
    }

    .three-star {
        padding-bottom: 5px;

        i {
            color: #acacac;

            &:nth-child(-n + 3) {
                color: #ffd200;
            }
        }
    }
}

.tab-border {
    border: 1px solid $white-dark;
    margin: 0;

    .nav-border {
        border-bottom: 0;
        border-right: 1px solid $white-dark;
    }
}

.vertical-tab {

    &.tab-product,
    .product-full-tab {
        .nav-material {
            border: none !important;

            &.nav-tabs {

                .nav-item {
                    @media (min-width: 1199px) {
                        width: 100%;
                    }

                    .nav-link {
                        padding: 15px 20px;
                    }
                }
            }
        }
    }
}

.product-full-tab {
    padding-top: 70px;
}

.product-load-more {
    .col-grid-box {
        display: none;
    }
}

.load-more-sec {
    margin-top: 40px;
    text-align: center;

    a {
        font-size: 18px;
        text-transform: uppercase;
        display: block;
        padding: 10px 0;
        border-top: 1px solid $round-border;
        border-bottom: 1px solid $round-border;
        width: 100%;
        color: var(--theme-color);
    }
}

.video-icon {
    position: relative;

    img {
        opacity: 0.8;
    }

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
    }
}

.video-product {
    iframe {
        width: 100%;
        height: 44vh;
        margin-top: 90px;
    }
}

.top-filter {
    position: absolute;
    z-index: 1;
    top: 80px;
    left: 0;
    width: 100%;
    display: none;

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .collection-collapse-block {
        padding-bottom: 0;

        .collapse-block-title {
            &:after {
                display: none;
            }
        }
    }

    .button_bottom {
        padding-bottom: 20px;
    }

    .collection-collapse-block-content {
        max-height: 210px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.filter-bottom-title {
    position: relative;
    cursor: pointer;

    &:after {
        position: absolute;
        display: block;
        top: 0;
        right: -3px;
        content: "\f0d7";
        font-family: $font-awesome;
        font-weight: 900;
        color: #969696;
    }
}

.sticky-bottom-cart {
    position: fixed;
    bottom: -200px;
    width: 100%;
    z-index: 9;

    transition: all 0.3s ease-in-out;
    left: 50%;
    transform: translateX(-50%) scale(0.95);

    .qty-box {
        .input-group {
            justify-content: center;
        }
    }

    .cart-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        padding: 10px;
        box-shadow: 0 0 100px rgba(0, 0, 0, 0.06);

        .btn-solid {
            @media (max-width: 991px) {
                padding: 9px 19px;
            }
        }

        .middle-value {
            display: flex;
            gap: 20px;
            align-items: center;

            .qty-box {
                input {
                    background-color: $top-header;
                    border-color: $white-dark;
                }

                .input-group {
                    span {
                        button {
                            background: $top-header !important;
                            border-color: $white-dark;
                        }
                    }
                }
            }
        }
    }

    .product-image {
        display: flex;
        align-items: center;

        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            object-position: top;
            background-color: $top-header;
        }

        @media (max-width: 767px) {
            display: none;
        }

        .content {
            margin-left: 12px;

            h5,
            h6 {
                margin-bottom: 0;
            }

            h5 {
                font-size: 18px;
                font-weight: 500;
            }

            h6 {
                font-weight: 400;
                font-size: 16px;
                color: $dark-font;

                del {
                    margin-left: 7px;
                }

                span {
                    color: var(--theme-color);
                    margin-left: 5px;
                }
            }
        }
    }

    .selection-section {
        display: flex;
        align-items: center;
        gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        margin: 0 auto;
        text-align: center;

        @media (max-width: 767px) {
            justify-content: start;
            margin-left: 0;
        }

        h4 {
            margin-bottom: 0;
            margin-right: 10px;
            font-weight: 500;
        }

        .variation-box {
            display: flex;
            align-items: center;
            gap: calc(26px + 24 * (100vw - 767px) / 1153);

            h4 {
                margin-bottom: 0;
            }
        }

        .form-control {
            background-color: $top-header;
            border: 1px solid $white-dark;
            font-weight: 500;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.stickyCart {
    .sticky-bottom-cart {
        bottom: 20px;
        transition: all 0.3s ease-in-out;
        transform: translateX(-50%) scale(1);
    }

    .recently-purchase {
        &.show {
            bottom: 125px;
        }
    }
}

.product-center-page {
    .product-right.right-side {
        text-align: right;

        .timer {
            padding-right: 40px;
        }
    }

    .product-description {
        .qty-box {
            justify-content: flex-end;
        }
    }
}

.addeffect-section {
    .error-message {
        display: none;
        color: red;
    }

    &.cartMove {
        .error-message {
            display: block;
        }

        .size-box {
            animation: buttons-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }
    }
}

.custom-variations {
    ul {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;

        li {
            display: flex;
            align-items: center;
            border: 1px solid $round-border;
            margin: 0 10px;
            padding: 5px;
            text-align: center;
            cursor: pointer;

            img {
                width: 50px;
                height: 70px;
                object-fit: cover;
                object-position: center;
            }

            .product-content {
                padding-left: 15px;
                padding-right: 25px;

                h6 {
                    margin-bottom: 0;
                    color: $dark-font;
                    font-weight: 600;
                    font-size: 15px;
                }

                span {
                    color: #888;
                    font-size: 15px;
                }
            }

            &.active {
                border-color: var(--theme-color);
            }
        }
    }
}

@keyframes buttons-shake {

    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }
}

.custom_stop {
    display: none !important;
}

.play-video .custom_play {
    display: none !important;
}

.play-video .custom_stop {
    display: inline-block !important;
}

// Quick-view //
.quick-view {
    width: 100%;
    max-width: 1080px;
    max-height: 600px;
    position: relative;
}

// Check-out page //
.checkout-page {
    .checkout-title {
        margin-bottom: 25px;
        border-bottom: 1px solid $white-dark;

        h3 {
            color: $dark-font;
            font-weight: 700;
        }
    }

    .order-btn {
        width: 100%;
        margin-top: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
        background-color: var(--theme-color);
        border-radius: 0;
        padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        font-weight: 600;
        letter-spacing: unset;
        text-transform: unset;
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
        border: 1px solid transparent;

        &:hover {
            border-color: var(--theme-color);
            background-color: transparent;
            color: var(--theme-color);
        }
    }

    .promo-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        button {
            padding: 0;
            font-size: 15px;
            display: flex;
            align-items: center;
            border: none;
            gap: 6px;
        }
    }

    .checkout-form {
        .checkout-details {
            position: sticky;
            top: 120px;
            left: 0;
            background-color: $top-header;
            padding: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
            border: 1px solid $white-dark;
            border-radius: 0;

            .coupon-box {
                padding: 10px;
                border: 1px solid $white-dark;
                background-color: $white;

                .coupon-content {
                    margin-top: 0px;

                    .coupon-apply {
                        padding-top: 9px;
                    }
                }
            }

            .card-name {
                display: block;

                h6 {
                    line-height: 1.4;
                    font-weight: 600;
                }
            }

            .promo-code-box {
                margin-bottom: 10px;
                margin-top: 14px;

                h5 {
                    font-weight: 500;
                    font-size: calc(15px + 1 * (100vw - 320px) / 1600);
                    color: $dark-font;
                    line-height: 1.3;
                    margin: 0;
                }

                >a {
                    font-size: 15px;
                    cursor: pointer;
                    color: var(--theme-color);
                }

                .coupon-input-box {
                    flex-wrap: nowrap;
                    gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
                    display: flex;
                }

                .form-control {
                    height: auto;
                    padding: calc(10px + 2 * (100vw - 320px) / 1600) calc(13px + 6 * (100vw - 320px) / 1600);
                    font-weight: 500;
                    border-radius: 0;
                    border: 1px solid $white-dark;
                    font-size: calc(14px + 0 * (100vw - 320px) / 1600);
                }

                .apply-button {
                    white-space: nowrap;
                    border: 1px solid var(--theme-color);
                    border-radius: 0;
                    font-weight: 600;
                    color: var(--theme-color);
                    letter-spacing: unset;
                    text-transform: none;
                    background-color: transparent;

                    &:hover {
                        background-color: var(--theme-color);
                        color: $white;
                    }
                }

                .invalid-feedback {
                    font-size: 14px;
                    letter-spacing: unset;
                    font-weight: 500;
                }
            }

            .order-box {
                margin: 0;

                .title-box {
                    padding-bottom: 8px;

                    h4 {
                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 600;
                        letter-spacing: unset;
                        color: $dark-font;
                        line-height: 1.3;
                        margin: 0;
                    }

                    p {
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                        line-height: 1.5;
                        font-weight: 500;
                        margin: 4px 0 0;
                    }
                }
            }

            .offer-apply-box {
                display: flex;
                gap: 9px;
                margin-top: 25px;
                position: relative;
                padding: 8px 13px;
                overflow: hidden;
                border: 1px dashed #198754;
                background-color: rgba(25, 135, 84, 0.18);

                img {
                    width: 28px;
                    height: 28px;
                }

                h4 {
                    font-size: 16px !important;
                }

                p {
                    margin: 3px 0 0 !important;
                    font-size: 14px !important;
                }

                .close-coupon {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    font-size: 14px;
                    line-height: 1;
                    color: #198754;
                    font-weight: 500;
                }
            }
        }

        .check-out {
            .form-group {
                &:last-child {
                    margin-bottom: -5px;

                    label {
                        margin-bottom: -5px;
                    }
                }
            }
        }

        .form-group {
            position: relative;
            margin-bottom: 25px;

            h3 {
                color: $grey4;
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 30px;
            }

            .field-label {
                line-height: 24px;
                text-transform: capitalize;
                color: #333333;
                margin-bottom: 10px;
                font-weight: 700;

                span {
                    font-size: 16px;
                    color: $dark-font;
                    font-weight: 600;
                }
            }

            label {
                color: $grey;
            }
        }

        select,
        textarea {
            width: 100%;
            padding: 0 22px;
            height: 45px;
            border: 1px solid $round-border;
        }
    }

    .check-box {
        line-height: 24px;
        font-size: 14px;
        font-weight: normal;
        padding-top: 5px;

        label {
            position: relative;
            top: -1px;
            font-weight: normal;
            padding: 0;
            font-size: 16px;
            cursor: pointer;
            color: $font-color;
        }
    }

    .lower-content {
        margin-top: 30px;

        .order-column {
            margin-bottom: 40px;
        }
    }

    .checkout-form-section {
        display: grid;
        gap: calc(20px + 0.0125 * (100vw - 320px));

        .checkout-title1 {
            h2 {
                font-weight: 600;
                font-size: calc(18px + 2 * (100vw - 320px) / 1600);
                letter-spacing: unset;
                text-transform: unset;
                margin: 0;
                line-height: 1.3;
                border-bottom: 1px solid #ededed;
                margin-bottom: 20px;
                padding-bottom: 8px;
            }
        }

        .checkout-form {
            .form-box {
                position: relative;

                .form-label {
                    font-weight: 500;
                    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: 4px;
                    color: $grey;
                }

                .form-control {
                    height: auto;
                    padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    border-radius: 0;
                    border: 1px solid $white-dark;
                    font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1920 - 320)));

                    &::-webkit-inner-spin-button {
                        display: none;
                    }
                }

                .custom-select {
                    .select2 {
                        &.select2-container--focus {
                            .select2-focused {
                                .select2-selection--single {
                                    border: 1px solid transparent;
                                }
                            }
                        }
                    }

                    .select2-selection {
                        border: 1px solid $white-dark;
                        padding: calc(10px + 2 * (100vw - 320px) / 1600) calc(13px + 6 * (100vw - 320px) / 1600);
                        padding-right: calc(7px + (19 - 7) * ((100vw - 320px) / (1920 - 320)));
                        height: auto;
                        background-color: $white;
                        border-radius: 0;

                        .select2-selection__rendered {
                            padding: 0;
                            line-height: 1.6;
                        }

                        .select2-selection__placeholder {
                            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                            color: $grey;
                            font-weight: 500;
                        }

                        .select2-selection__arrow {
                            &::before {
                                content: "\ea4e";
                                @include font;
                                border: unset;
                                width: auto;
                                height: auto;
                                color: $grey;
                                font-size: 17px;
                            }
                        }
                    }

                    .select2-dropdown {
                        border: 1px solid $white-dark;

                        .select2-search {
                            .select2-search__field {
                                border: 1px solid $white-dark;
                            }
                        }
                    }

                    .select2-results {
                        .select2-results__options {
                            &::-webkit-scrollbar-track {
                                background-color: rgba($color: var(--theme-color), $alpha: 0.14);
                            }

                            &::-webkit-scrollbar {
                                width: 4px;
                                height: 4px;
                                background-color: #f5f5f5;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: var(--theme-color);
                            }

                            .select2-results__option {
                                background-color: $white;
                                display: block;

                                .country {
                                    .dial-code {
                                        color: $grey;
                                        font-weight: 500;
                                    }
                                }

                                &--highlighted {
                                    background-color: var(--theme-color);

                                    .country {
                                        .dial-code {
                                            color: $white-dark;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .form-checkbox {
                display: flex;
                align-items: center;
                gap: 10px;

                .form-check-input {
                    width: 21px;
                    height: 21px;
                    background: unset;
                    background-color: $white;
                    box-shadow: unset;
                    border: unset;
                    filter: unset;
                    position: relative;
                    margin: 0;
                    vertical-align: unset;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 6px;
                        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                        transform: rotate(-45deg) scale(0);
                        left: 4px;
                        top: 5px;
                        z-index: 1;
                        border: 2px solid var(--theme-color);
                        border-top-style: none;
                        border-right-style: none;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 21px;
                        height: 21px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        background-color: $white;
                        border: 2px solid #ececec;
                        cursor: pointer;
                    }

                    &:checked {
                        &::before {
                            transform: rotate(-45deg) scale(1);
                        }
                    }
                }
            }

            .form-check-label {
                color: #4a5568;
                font-weight: 500;
                font-size: calc(15px + 1 * (100vw - 320px) / 1600);
                margin: 0;
                line-height: 1;
            }
        }

        .phone-field {
            .form-box {
                .form-control {
                    padding-left: calc(111px + (132 - 111) * ((100vw - 320px) / (1920 - 320)));
                }
            }

            .custom-select {
                position: absolute;
                top: 29px;
                left: 1px;
                height: calc(42px + (48 - 42) * ((100vw - 320px) / (1920 - 320)));
                width: calc(94px + (120 - 94) * ((100vw - 320px) / (1920 - 320)));

                .select2 {
                    height: 100%;

                    .selection {
                        height: calc(100% - 2px);
                        border-right: 1px solid $white-dark;

                        .select2-selection {
                            height: 100%;
                            border: unset;
                            border-radius: 0;
                            gap: 5px;
                            background-color: transparent;

                            .select2-selection__rendered {
                                display: flex;
                                align-items: center;
                            }

                            .select2-selection__arrow {
                                width: auto;

                                &::before {
                                    content: "\ea4e";
                                    @include font;
                                    border: unset;
                                    width: auto;
                                    height: auto;
                                    color: $grey;
                                    font-size: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .delivery-option,
        .payment-option {
            height: 100%;
            padding: calc(17px + 9 * (100vw - 320px) / 1600);
            background-color: $white;
            height: 100%;
            border-radius: 0;

            .custom-form-check {
                min-height: unset;
                padding: 0;
                margin: 0;
                display: flex;
                gap: 5px;

                .form-check-input {
                    width: calc(15px + 0.00125 * (100vw - 320px));
                    height: calc(15px + 0.00125 * (100vw - 320px));
                    margin: 0;
                    float: unset;
                    margin-top: 4px;
                    background-color: unset;
                    position: relative;
                    filter: unset;
                    border: unset;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 16px;
                        height: 16px;
                        background-color: $white;
                        border-radius: 100%;
                        transition: all 0.3s ease-in-out;
                        border: 1px solid $white-dark;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0);
                        width: 11px;
                        height: 11px;
                        background-color: var(--theme-color);
                        transition: all 0.3s ease-in-out;
                        border-radius: 100%;
                    }

                    &:checked {
                        background-color: transparent;
                        border-color: transparent;

                        &::before {
                            border-color: var(--theme-color);
                        }

                        &::after {
                            transform: translate(-50%, -50%) scale(1);
                        }

                        ~label {
                            color: $dark-font;
                        }
                    }
                }

                label {
                    cursor: pointer;
                    font-size: calc(15px + 2 * (100vw - 320px) / 1600);
                    padding-left: 12px;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
        }

        .checkbox-main-box {
            border-radius: 0;
            padding: calc(14px + 15 * (100vw - 320px) / 1600);
            background-color: $top-header;
            border: 1px solid $white-dark;
            position: relative;
        }
    }

    .future-option {
        padding: calc(17px + 9 * (100vw - 320px) / 1600);
        position: relative;
        border-radius: 10px;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 1;
            z-index: -1;
        }

        .delivery-items {
            h4 {
                margin-bottom: 16px;
                color: $dark-font;
                font-weight: 500;
            }
        }

        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 7px;

            @media (max-width: 1399px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 991px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 767px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 480px) {
                grid-template-columns: 1fr;
            }

            li {
                &.active {
                    a {
                        color: $white;
                        background-color: var(--theme-color);
                    }
                }

                a {
                    background-color: $white;
                    display: block;
                    text-align: center;
                    color: $black;
                    padding: 12px 8px;
                    border: 1px solid var(--theme-color);
                    border-radius: 0;
                    font-weight: 500;
                }
            }
        }
    }
}

.checkout-section-2 {
    .left-sidebar-checkout {
        .checkout-detail-box {
            >ul {
                display: flex;
                flex-wrap: wrap;
                gap: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

                >li {
                    position: relative;
                    width: 100%;

                    &:last-child {
                        .checkout-box {
                            &::before {
                                content: none;
                            }
                        }
                    }

                    .checkout-box {
                        padding: calc(14px + (29 - 14) * ((100vw - 320px) / (1920 - 320)));
                        background-color: $top-header;
                        border-radius: 0;
                        border: 1px solid $white-dark;
                        position: relative;

                        .payment-method {
                            ul {
                                display: flex;
                                align-items: center;
                                gap: 15px;

                                li {
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid transparent;
                                    background-color: $white;
                                    padding: 15px 40px;
                                    border-radius: 20px;
                                    text-align: center;
                                    cursor: pointer;

                                    i {
                                        font-size: 28px;
                                        color: #898989;
                                        line-height: 1;
                                    }

                                    h5 {
                                        color: #898989;
                                    }

                                    &.active {
                                        border-color: var(--theme-color);
                                        color: var(--theme-color);

                                        i {
                                            color: var(--theme-color);
                                        }
                                    }
                                }
                            }
                        }

                        .form-select {
                            background-color: $white;
                            border-color: $white-dark;
                            line-height: 1.8;
                        }

                        @media (max-width: 576px) {
                            margin-left: 0;
                        }

                        .checkout-title {
                            border-bottom: 1px solid #ededed;
                            margin-bottom: 20px;
                            padding-bottom: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            h4 {
                                font-weight: 600;
                                font-size: calc(18px + 2 * (100vw - 320px) / 1600);
                                letter-spacing: unset;
                                text-transform: unset;
                                margin: 0;
                                line-height: 1.3;
                            }

                            button {
                                padding: 0;
                                border: none;
                                font-size: 14px;
                                font-weight: 700;
                                color: var(--theme-color);
                            }
                        }

                        .checkout-detail {
                            .delivery-address-box {
                                gap: 10px;
                                border-radius: 0;
                                padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
                                background-color: $white;
                                height: 100%;
                                display: flex;
                                position: relative;
                                cursor: pointer;

                                .label {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    background-color: var(--theme-color);
                                    padding: 2px 8px;
                                    border-radius: 4px;
                                    color: $white;
                                    font-size: 12px;
                                    letter-spacing: 0.8px;

                                    label {
                                        margin-bottom: 0;
                                        line-height: 1.7;
                                    }
                                }

                                .form-check-input {
                                    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                                    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                                    margin-top: 2px;
                                    float: unset;
                                    margin-left: 0;
                                    background-color: unset;
                                    position: relative;
                                    filter: unset;
                                    margin: 0;
                                    border: unset;
                                    margin-top: 3px;

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 16px;
                                        height: 16px;
                                        background-color: $white;
                                        border-radius: 100%;
                                        transition: all 0.3s ease-in-out;
                                        border: 1px solid #ddd;
                                    }

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) scale(0);
                                        width: 11px;
                                        height: 11px;
                                        background-color: var(--theme-color);
                                        transition: all 0.3s ease-in-out;
                                        border-radius: 100%;
                                    }

                                    &:checked {
                                        background-color: transparent;
                                        border-color: transparent;

                                        &::before {
                                            border-color: var(--theme-color);
                                        }

                                        &::after {
                                            transform: translate(-50%, -50%) scale(1);
                                        }
                                    }

                                    &:focus {
                                        box-shadow: none;
                                        border-color: var(--theme-color);
                                    }
                                }

                                .form-check-label {
                                    display: grid;
                                    gap: calc(6px + (10 - 6)*((100vw - 320px) /(1920 - 320)));
                                    cursor: pointer;
                                    font-size: calc(15px + .00125*(100vw - 320px));
                                    font-weight: 500;
                                    margin-bottom: 0;
                                    width: calc(100% - (15px + .00125*(100vw - 320px)));

                                    >span {
                                        display: block;
                                    }

                                    .address {
                                        line-height: 1.4;
                                        margin-bottom: 0;
                                        font-weight: 400;
                                        color: #222;
                                        font-size: calc(14px + (16 - 14)*((100vw - 320px) /(1920 - 320)));

                                        span {
                                            margin-right: 4px;
                                            font-weight: 500;
                                            color: #222;
                                        }
                                    }

                                    .name {
                                        font-weight: 600;
                                        margin: 0;
                                        text-transform: none;
                                        letter-spacing: unset;
                                        line-height: 1.3;
                                        font-size: 18px;
                                    }
                                }

                                .delivery-address-detail {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                                    margin-left: 10px;
                                    width: calc(85% + (75 - 85) * ((100vw - 320px) / (1920 - 320)));

                                    li {
                                        display: block;
                                        width: 100%;
                                    }

                                    h4 {
                                        font-weight: 600;
                                        margin: 0;
                                        text-transform: none;
                                        letter-spacing: unset;
                                        line-height: 1.3;
                                    }

                                    p,
                                    h6 {
                                        line-height: 1.4;
                                        margin-bottom: 0;
                                        font-weight: 400;
                                        color: $dark-font;
                                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                                        span {
                                            margin-right: 4px;
                                            font-weight: 500;
                                            color: $dark-font;
                                        }
                                    }
                                }
                            }

                            .add-address {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 30px;
                                height: 100%;
                                text-align: center;
                                cursor: pointer;
                                background-color: $white;
                                box-shadow: 0 0 9px rgba($black, 0.07);
                                border-radius: 8px;
                            }

                            .delivery-option,
                            .payment-option {
                                padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
                                background-color: $white;
                                border-radius: 0;
                                height: 100%;

                                .select-option {
                                    @media (max-width: 1460px) {
                                        margin-top: 6px;
                                    }
                                }

                                .date-box {
                                    position: relative;

                                    i {
                                        top: 50%;
                                        transform: translateY(-50%);
                                        position: absolute;
                                        right: 17px;
                                        font-size: 18px;
                                        color: #4a5568;
                                    }
                                }

                                .delivery-category,
                                .payment-category {
                                    display: flex;
                                    height: 100%;

                                    @media (max-width: 767px) {
                                        display: block;
                                    }

                                    .form-check {
                                        min-height: unset;
                                        padding: 0;
                                        margin: 0;

                                        .form-check-input {
                                            width: calc(15px + 2 * (100vw - 320px) / 1600);
                                            height: calc(15px + 2 * (100vw - 320px) / 1600);
                                            margin: 2px 0 0 0;
                                            float: unset;
                                            background-color: unset;
                                            filter: unset;
                                            position: relative;
                                            margin-top: 5px;
                                            border: unset;

                                            &::before {
                                                content: "";
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                width: 16px;
                                                height: 16px;
                                                background-color: $white;
                                                border-radius: 100%;
                                                transition: all 0.3s ease-in-out;
                                                border: 1px solid $white-dark;
                                            }

                                            &::after {
                                                content: "";
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%) scale(0);
                                                width: 11px;
                                                height: 11px;
                                                background-color: var(--theme-color);
                                                transition: all 0.3s ease-in-out;
                                                border-radius: 100%;
                                            }

                                            &:checked {
                                                background-color: transparent;
                                                border-color: transparent;

                                                &::before {
                                                    border-color: var(--theme-color);
                                                }

                                                &::after {
                                                    transform: translate(-50%, -50%) scale(1);
                                                }
                                            }

                                            &:focus {
                                                box-shadow: none;
                                                border-color: var(--theme-color);
                                            }
                                        }
                                    }
                                }

                                .custom-form-check {
                                    display: flex;
                                    margin-bottom: 0;
                                    min-height: auto;

                                    .form-check-label {
                                        cursor: pointer;
                                        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                                        padding-left: 12px;
                                        font-weight: 500;
                                        margin-bottom: 0;
                                        width: calc(100% - calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))));
                                    }
                                }

                                .delivery-date {
                                    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                                    font-weight: 500;
                                    border: 1px solid $white-dark;
                                    background-color: $white;
                                    display: flex;
                                    align-items: center;
                                    color: #4a5568;
                                    width: 100%;
                                    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));

                                    input {
                                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                                        font-weight: 500;
                                        border: none;
                                        background-color: $white;
                                        display: flex;
                                        align-items: center;
                                        color: #4a5568;
                                        width: 100%;
                                    }
                                }

                                .delivery-time {
                                    display: flex;
                                    align-items: center;
                                    height: 100%;

                                    .dropdown-toggle {
                                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                                        font-weight: 500;
                                        border: 1px solid $white-dark;
                                        background-color: $white;
                                        display: flex;
                                        align-items: center;
                                        color: #4a5568;

                                        i {
                                            -webkit-text-stroke: 1px #4a5568;
                                            color: transparent;
                                            font-size: 18px;
                                            margin-right: 10px;
                                        }

                                        &::after {
                                            content: none;
                                        }

                                        &::before {
                                            content: "\f107";
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            right: 20px;
                                            font-family: "Font Awesome 6 Free";
                                            font-weight: 900;
                                        }
                                    }

                                    .dropdown-menu {
                                        border: 1px solid transparent;
                                        box-shadow: 0 6px 5px rgba($black, 0.1);

                                        li {
                                            a {
                                                &:active {
                                                    color: $black;
                                                    text-decoration: none;
                                                    background-color: $white-dark;
                                                }
                                            }

                                            +li {
                                                margin-top: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                            .future-box {
                                display: none;

                                &.show {
                                    display: block;
                                }

                                .future-option {
                                    padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
                                    border-radius: 10px;

                                    .delivery-items {
                                        align-items: center;
                                        height: 100%;

                                        h4 {
                                            margin-bottom: 16px;
                                            color: $dark-font;
                                            font-weight: 500;
                                        }

                                        ul {
                                            display: flex;
                                            flex-wrap: wrap;
                                            width: 100%;
                                            margin: -8px;
                                            gap: 0;

                                            @media (max-width: 460px) {
                                                margin-inline: 0;
                                            }

                                            li {
                                                width: 25%;
                                                padding: 8px;

                                                @media (max-width: 1500px) {
                                                    width: 33.33%;
                                                }

                                                @media (max-width: 1400px) {
                                                    width: 50%;
                                                }

                                                @media (max-width: 460px) {
                                                    width: 100%;
                                                    padding-inline: 0;
                                                }

                                                a {
                                                    background-color: #f8f8f8;
                                                    display: block;
                                                    text-align: center;
                                                    color: $dark-font;
                                                    padding: 12px 8px;
                                                    border: none;
                                                    border-radius: 8px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: 100%;
                                                }

                                                &.active {
                                                    a {
                                                        background-color: var(--theme-color);
                                                        color: $white;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .payment-button {
                                padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                font-weight: 500;
                            }

                            .payment-method {
                                .custom-form-check {
                                    label {
                                        font-weight: 500;
                                        font-size: 17px;
                                    }
                                }
                            }

                            .credit-card-box {
                                .credit-detail {
                                    position: relative;
                                }
                            }

                            .credit-info {
                                label {
                                    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                                    font-weight: 500;
                                }
                            }

                            .payment-option {
                                padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                                border-radius: 0;
                                position: relative;

                                .form-check {
                                    .form-check-input {
                                        margin-top: 0;
                                    }

                                    label {
                                        font-weight: 500;
                                        font-size: 18px;
                                        padding-left: 10px;
                                    }
                                }

                                img {
                                    top: 50%;
                                    transform: translateY(-50%);
                                    position: absolute;
                                    right: 20px;
                                    width: 60px;
                                }
                            }

                            .custom-accordion {
                                display: flex;
                                flex-wrap: wrap;
                                gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
                                align-items: center;

                                .accordion-item {
                                    border: none;
                                    border-radius: 0;
                                    overflow: hidden;
                                    display: block;
                                    width: 100%;
                                    background-color: $top-header;
                                    border-radius: 0;

                                    .accordion-header {
                                        .accordion-button {
                                            color: #4a5568;
                                            position: relative;
                                            padding: 0;
                                            background-color: $top-header;

                                            &::after {
                                                content: none;
                                            }

                                            &::before {
                                                font-family: "Font Awesome 6 Free";
                                                font-weight: 900;
                                                position: absolute;
                                                content: "\f107";
                                                top: 50%;
                                                transform: translateY(-50%);
                                                right: 30px;
                                                transition: transform 0.2s ease-in-out;
                                            }

                                            &:not(.collapsed) {
                                                color: $dark-font;
                                                background-color: transparent;
                                                box-shadow: none;

                                                &::before {
                                                    top: 20%;
                                                    transform: rotate(-180deg) translateY(-50%);
                                                }
                                            }

                                            .form-check {
                                                display: flex;
                                                align-items: center;
                                                width: 100%;

                                                .form-check-label {
                                                    font-weight: 500;
                                                    color: $dark-font;
                                                    display: flex;
                                                    align-items: center;
                                                    width: 100%;
                                                    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                                                    padding: 16px 20px;
                                                    margin-bottom: 0;

                                                    @media (max-width: 480px) {
                                                        margin-top: 5px;
                                                    }

                                                    .form-check-input {
                                                        margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .accordion-collapse {
                                        .accordion-body {
                                            padding-top: 0;

                                            .cod-review {
                                                margin: 0;
                                                line-height: 1.5;
                                                color: #4a5568;
                                                font-size: 16px;

                                                a {
                                                    &:hover {
                                                        color: var(--theme-color);
                                                    }
                                                }
                                            }

                                            .custom-form-check {
                                                margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
                                                display: flex;
                                                align-items: center;

                                                label {
                                                    display: flex;
                                                    align-items: center;
                                                    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
                                                }

                                                input {
                                                    margin-right: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .custom-select {
                                position: absolute;
                                left: 1px;
                                height: 49px;
                                bottom: 1px;
                                border-radius: 0;

                                .select2-container {
                                    height: 100%;
                                }

                                .selection {
                                    height: 100%;

                                    .select2-selection {
                                        height: 100% !important;
                                        min-width: unset;
                                        background-color: transparent !important;
                                        width: 110px !important;
                                        border: unset !important;
                                        border-right: 1px solid #efefef !important;

                                        .select2-selection__rendered {
                                            display: flex;
                                            align-items: center;
                                            background-color: transparent !important;
                                        }
                                    }
                                }
                            }

                            .phone-field,
                            .form-input-box {
                                input {
                                    border: 1px solid #efefef;
                                }

                                .form-label {
                                    font-size: calc(15px + 2 * (100vw - 320px) / 1600);
                                    color: $dark-font;
                                    margin-bottom: 4px;
                                }
                            }

                            .account-checkbox {
                                min-height: unset;
                                padding: 0;
                                margin: 0;
                                gap: 5px;
                                display: flex;
                                align-items: center;

                                .checkbox_animated {
                                    float: unset;
                                    margin: 0;
                                    width: 23px;
                                    height: 23px;
                                    border: unset;
                                    background: unset;
                                    box-shadow: unset;

                                    &:after {
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        right: unset;
                                        width: 23px;
                                        height: 23px;
                                    }

                                    &:before {
                                        left: 7px;
                                        top: 7px;
                                        width: 10px;
                                        height: 6px;
                                    }
                                }

                                .form-check-label {
                                    line-height: 1.4;
                                    margin-bottom: 0;
                                    font-weight: 400;
                                    font-size: calc(14px + 2 * (100vw - 320px) / 1600);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-right-box {
        position: sticky;
        top: 20px;
        left: 0;

        .checkout-details {
            position: relative;
            background-color: #f8f8f8;
            border: 1px solid #eeeeee;
            border-radius: 0;
            padding: calc(18px + .00125*(100vw - 320px)) calc(15px + 5*(100vw - 320px) / 1600);
            inset: unset;

            +.checkout-details {
                margin-top: 20px;
            }

            .order-box {
                margin: 0;

                .title-box {
                    position: relative;
                    color: #444;
                    font-weight: 600;
                    font-size: 22px;
                    border-bottom: 1px solid #ededed;
                    margin-bottom: 20px;
                    padding-bottom: 8px;

                    h4 {
                        font-size: calc(18px + .00125*(100vw - 320px));
                        font-weight: 600;
                        letter-spacing: unset;
                        color: #222;
                        line-height: 1.3;
                        margin: 0;
                    }

                    p {
                        font-size: calc(14px + 1*(100vw - 320px) / 1600);
                        line-height: 1.5;
                        font-weight: 500;
                        margin: 4px 0 0;
                    }
                }

                .qty {
                    margin: 0;
                    border: none;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 11px;
                        font-size: 15px;
                        color: #444;
                        line-height: 20px;
                        margin-bottom: 18px;

                        .cart-image {
                            width: 60px;
                        }

                        .cart-content {
                            width: calc(100% - 71px);
                            display: grid;
                            grid-template-columns: 1fr auto;
                            gap: 9px;

                            h4 {
                                line-height: 1.3;
                                font-weight: 500;
                                font-size: calc(15px + 1*(100vw - 320px) / 1600);
                                margin-bottom: 4px;
                                letter-spacing: unset;
                                text-transform: unset;
                            }

                            h5 {
                                font-weight: 500;
                                margin: 0;
                                font-size: calc(16px + 1*(100vw - 320px) / 1600);
                                line-height: 1.3;
                            }

                            span {
                                font-size: calc(18px + .00125*(100vw - 320px));
                                text-align: right;
                                line-height: 1.3;
                                font-weight: 600;
                                width: auto;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.order-box {
    position: relative;
    margin-bottom: 50px;

    .box-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;

        .loader-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            inset: unset;
            background-color: $top-header;
        }
    }

    .title-box {
        position: relative;
        padding-bottom: 25px;
        color: $grey4;
        font-weight: 600;
        font-size: 22px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 20px;

        i {
            font-weight: 400;
        }

        span {
            position: relative;
            text-align: right;
            line-height: 1.2em;
        }
    }

    .qty {
        position: relative;

        li {
            position: relative;
            display: flex;
            align-items: center;
            gap: 11px;
            font-size: 15px;
            color: $grey4;
            line-height: 20px;
            margin-bottom: 18px;

            .cart-image {
                width: 60px;
            }

            &:last-child {
                margin: 0;
            }

            .cart-content {
                width: calc(100% - 60px - 11px);
                display: grid;
                grid-template-columns: 1fr auto;
                gap: 9px;

                h4 {
                    line-height: 1.3;
                    font-weight: 500;
                    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: 4px;
                    letter-spacing: unset;
                    text-transform: unset;
                }

                h5 {
                    font-weight: 500;
                    margin: 0;
                    font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
                    line-height: 1.3;
                }
            }

            span {
                font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                text-align: right;
                line-height: 1.3;
                color: $dark-font;
                font-weight: 600;
            }
        }
    }

    .sub-total {
        position: relative;

        li {
            position: relative;
            display: flex;
            gap: 9px;
            font-size: 16px;
            font-weight: 600;
            color: $grey;
            line-height: 1.3;
            justify-content: space-between;
            width: 100%;

            +li {
                margin-top: 14px;
            }

            &.border-cls {
                margin-top: 0;
                display: flex;
                align-items: center;
                gap: 0;

                .form-check-label {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .count {
                text-align: right;
                position: relative;
                font-size: 17px;
                line-height: 20px;
                color: var(--theme-color);
                font-weight: 500;
            }

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: $grey;
                line-height: 1.3;
                letter-spacing: unset;
                margin: 0;

                +h4 {
                    text-align: left;
                }
            }

            .txt-primary {
                color: var(--theme-color);
            }

            .check-it {
                margin-left: 14px;
                margin-right: 0;
                width: 20px;
                height: 20px;
                border: unset;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 6px;
                    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                    transform: rotate(-45deg) scale(0);
                    left: 4px;
                    top: 5px;
                    z-index: 1;
                    border: 2px solid var(--theme-color);
                    border-top-style: none;
                    border-right-style: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $white;
                    border: 2px solid #ececec;
                    cursor: pointer;
                }

                &:checked {
                    &::before {
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }
        }

        .shopping-option {
            label {
                position: relative;
                font-size: 16px;
                line-height: 32px;
                padding-left: 10px;
                color: $grey6;
            }
        }

        .shipping {
            width: 35%;
            float: right;
        }
    }

    .total {
        position: relative;
        margin-top: 13px;
        padding-top: 13px;
        border-top: 1px solid $white-dark;

        li {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 9px;
            font-weight: 600;
            color: $font-color;
            line-height: 1.3;
            font-size: 18px;

            +li {
                margin-top: 8px;
            }

            .count {
                position: relative;
                font-size: 18px;
                text-align: right;
                line-height: 1.3;
                color: var(--theme-color);
                font-weight: 600;
            }

            &:last-child {
                font-size: calc(19px + (21 - 19) * ((100vw - 320px) / (1920 - 320)));

                .count {
                    font-size: calc(19px + (21 - 19) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }
    }
}

.payment-box {
    position: relative;

    .upper-box {
        position: relative;
    }

    .payment-options {
        position: relative;
        margin-top: 20px;
        margin-bottom: 30px;

        li {
            display: flex;
            margin-bottom: 15px;

            .radio-option {
                position: relative;

                label {
                    position: relative;
                    padding-left: 30px;
                    text-transform: capitalize;
                    color: $grey6;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                }

                input[type="radio"] {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }

                label {
                    .small-text {
                        position: relative;
                        display: none;
                        font-size: 15px;
                        line-height: 25px;
                        font-weight: 300;
                        color: #666666;
                        margin-top: 10px;
                    }

                    img {
                        position: relative;
                        display: block;
                        max-width: 100%;
                        margin-left: -30px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

// Dashboard page
.dashboard-left {
    .block-title h2 {
        font-size: 24px;
        padding-bottom: 20px;
    }

    .block-content {
        padding: 10px 0;
        background-color: $top-header;

        ul li {
            display: flex;
            transition: all 0.3s ease-in-out;
            padding: 0.5rem 1rem;
            border-right: 2px solid transparent;

            &.active {
                color: var(--theme-color);
                border-right: 2px solid var(--theme-color);

                a {
                    color: var(--theme-color);
                }
            }

            &:before {
                display: inline-block;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                width: 30px;
                height: 30px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-top: 3px;
            }

            a {
                color: black;
            }

            &:hover {
                color: var(--theme-color);
                transition: all 0.3s ease-in-out;

                a {
                    color: var(--theme-color);
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

.padding-cls {
    padding-left: 50px;
    padding-right: 50px;
}

.dashboard-right {
    p {
        color: #5f5f5f;
        line-height: 20px;
    }

    .dashboard {
        border: 1px solid $round-border;
        padding: 30px;

        .page-title h2 {
            font-size: 22px;
            margin-bottom: 15px;
        }

        .welcome-msg {
            p {
                margin-bottom: 0;
            }
        }
    }
}

.dashboard {
    .box-head {
        h2 {
            font-size: 22px;
            margin: 20px 0 0;
            text-transform: capitalize;
            color: #333;
        }
    }

    .box {
        a {
            color: var(--theme-color);
        }

        h6 {
            margin-bottom: 0;
        }

        .box-title {
            border-bottom: 1px solid $round-border;
            display: inline-block;
            margin-bottom: 5px;
            padding: 12px 0;
            position: relative;
            width: 100%;

            h3 {
                font-size: 16px;
                margin: 0;
                text-transform: capitalize;
                color: #333;
            }

            >a {
                position: absolute;
                right: 0;
                top: 12px;
                color: var(--theme-color);
            }
        }

        address {
            margin-bottom: 0;
        }
    }
}

.box-content {
    h6 {
        font-size: 14px;
        line-height: 25px !important;
        margin-bottom: 0;
    }

    a {
        color: var(--theme-color);
    }
}

.account-sidebar {
    display: none;
    cursor: pointer;
    background-color: var(--theme-color);
    color: $white;
    width: 30%;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 5px;

    a {
        color: $white;
    }
}

.custom-badge {
    padding: 6px 10px;
    font-size: 12px;
}

.user-dashboard-section {
    .box {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.7;
    }

    .welcome-msg {
        margin-bottom: 20px;

        h4 {
            font-size: 16px;
            font-weight: 700;
            color: $dark-font;
        }

        p {
            line-height: 25px;
            letter-spacing: 0.05em;
            font-size: 16px;
        }
    }

    .box-head {
        h4 {
            font-size: 18px;
            margin: 34px 0 14px;
            text-transform: capitalize;
            color: #333;
            font-weight: 600;
        }
    }

    .box {
        a {
            color: var(--theme-color);
        }

        h6 {
            margin-bottom: 0;
        }

        .box-title {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            padding: 12px;
            position: relative;
            width: 100%;
            background-color: $top-header;
            border-radius: 8px;

            h3 {
                font-size: 16px;
                margin: 0;
                text-transform: capitalize;
                color: #333;
            }

            >a {
                margin-left: auto;
                color: var(--theme-color);
            }
        }

        address {
            margin-bottom: 0;
        }
    }

    .faq-content {
        .tab-pane {
            .card-body {
                padding: calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .show-btn {
        background-color: var(--theme-color);
        border-radius: 0;
        padding: calc(7px + 3*(100vw - 320px) / 1600) calc(12px + 7*(100vw - 320px) / 1600);
        text-transform: unset;
        font-weight: 600;
        color: #fff;
        margin-bottom: calc(18px + .005*(100vw - 320px));

        &:active {
            background-color: var(--theme-color);
            color: #fff;
        }
    }

    .edit-link {
        color: var(--theme-color);
        margin-left: 10px;
        text-transform: capitalize;
    }

    .address-book-section {
        .select-box {
            .address-box {
                padding: 15px;
                background-color: $white;
                transition: all 0.3s ease-in-out;

                .top {
                    h6 {
                        text-transform: capitalize;
                        color: $dark-font;
                        font-weight: 600;
                        font-size: 14px;

                        span {
                            float: right;
                            background-color: var(--theme-color);
                            color: $white;
                            padding: 2px 15px;
                            font-size: 80%;
                            border-radius: 3px;
                            line-height: 2.2;
                        }
                    }
                }

                .middle {
                    margin-top: 15px;

                    .address {
                        p {
                            margin-bottom: 5px;
                            color: rgba(0, 0, 0, 0.7);
                            line-height: 1.2;
                        }
                    }

                    .number {
                        margin-top: 15px;

                        p {
                            color: rgba(0, 0, 0, 0.7);
                            text-transform: capitalize;
                        }
                    }
                }

                .card-number {
                    margin-top: 15px;

                    h6,
                    h5 {
                        margin-bottom: 0;
                    }
                }

                .name-validity {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    h6 {
                        text-transform: capitalize;
                    }

                    h5,
                    h6 {
                        margin-bottom: 0;
                    }

                    .right {
                        margin-left: auto;
                    }
                }

                .bank-logo {
                    display: flex;
                    align-items: center;

                    img {
                        height: 22px;
                    }

                    .network-logo {
                        margin-left: auto;
                        width: 50px;
                        height: auto;
                    }
                }

                .bottom {
                    border-top: 1px solid $round-border;
                    display: flex;
                    text-align: center;
                    padding-top: 14px;
                    margin-top: 10px;
                    justify-content: space-between;

                    .bottom_btn {
                        width: 47.5%;
                        text-transform: capitalize;
                        font-weight: 600;
                        padding: 8px 4px;
                        border-radius: 5px;
                        background-color: rgba(248, 248, 248, 1);
                        color: $dark-font;
                    }
                }
            }
        }

        .select-box.active {
            .address-box {
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

// Order-success //
.success-text {
    text-align: center;

    .star {
        position: absolute;
        animation: grow 3s infinite;
        fill: var(--theme-color);
        opacity: 0;

        &:nth-child(1) {
            @include size(12px);
            left: 12px;
            top: 16px;
        }

        &:nth-child(2) {
            @include size(18px);
            left: 168px;
            top: 84px;
        }

        &:nth-child(3) {
            @include size(10px);
            left: 32px;
            top: 162px;
        }

        &:nth-child(4) {
            @include size(20px);
            left: 82px;
            top: -12px;
        }

        &:nth-child(5) {
            @include size(14px);
            left: 125px;
            top: 162px;
        }

        &:nth-child(6) {
            @include size(10px);
            left: 16px;
            top: 16px;
        }

        @for $i from 1 through 6 {
            $delay: $i * 1.5s;

            &:nth-child(#{$i}) {
                animation-delay: $delay;
            }
        }
    }

    .checkmark {
        position: relative;
        padding: 30px;
        animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
        display: inline-block;
        transform: scale(0.8);
        margin: -20px;

        &__check {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate3d(-50%, -50%, 0);
            fill: $white;
        }

        &__background {
            fill: var(--theme-color);
            animation: rotate 35s linear both infinite;
        }
    }

    i {
        font-size: 50px;
        color: #4ead4e;
    }

    h2 {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    p {
        font-size: 18px;
        margin-bottom: 6px;
        text-transform: capitalize;
    }

    &.order-fail {
        i {
            color: var(--theme-color);
        }
    }
}

.order-success-sec {
    background-color: $grey-lighter;
    padding: 25px;
    border: 1px solid #eee;

    h4 {
        font-weight: 600;
        text-transform: capitalize;
        font-size: 19px;
        border-bottom: 1px solid rgba(34, 38, 42, 0.12);
        padding-bottom: 8px;
        line-height: 1.3;
        margin-bottom: 8px;
    }

    .order-detail {
        li {
            display: flex;
            text-transform: capitalize;
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 3px;
            color: $grey;
        }
    }

    .payment-mode {
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
            letter-spacing: 0.05em;
        }
    }
}

.delivery-sec {
    margin-bottom: -5px;
    padding: 0;
    margin-top: 20px;
    background-color: transparent;

    h3 {
        color: #000000;
        text-transform: capitalize;
        font-size: 18px;
        margin-bottom: 0;

        span {
            font-weight: 700;
            margin-left: 5px;
        }
    }

    a {
        font-size: 16px;
        color: var(--theme-color);
        text-transform: capitalize;
        margin-top: 5px;
        display: block;
        font-weight: 600;
    }
}

.product-order {
    .product-order-table {
        border: 1px solid #eee;

        thead {
            tr {
                th {
                    font-size: 18px;
                    color: #222;
                    background-color: #f8f8f8;
                    font-weight: 600;
                    padding: 14px;
                    text-align: center;
                    text-transform: capitalize;

                    &:first-child {
                        min-width: 106px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    max-width: 220px;
                    min-width: 160px;
                    padding: 15px;
                    color: #232323;
                    border-bottom: 1px solid #eeeeee;
                    font-weight: 500;
                    text-align: center;
                    vertical-align: middle;
                    background-color: transparent;

                    &:first-child {
                        // text-align: left;
                        min-width: 106px;
                    }

                    img {
                        height: 78px;
                    }
                }
            }
        }

        tfoot {
            tr {
                &:first-child {
                    td {
                        padding-top: 15px;
                    }
                }

                &:last-child {
                    td {
                        border-top: 1px solid #eee;
                        font-size: 19px;
                        font-weight: 600;
                        color: #232323;
                    }
                }

                td {
                    padding: 8px 15px;
                    border-bottom: 1px solid #eeeeee;
                    font-weight: 500;
                    vertical-align: middle;
                    text-align: left;
                    text-transform: capitalize;
                    font-size: 16px;
                    color: #777;
                    border: none;

                    &:nth-child(2) {
                        color: #232323;
                        text-align: right;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

// compare //
.compare-section {
    .compare-part {
        position: relative;

        .close-btn {
            background-color: transparent;
            border: none;
            font-size: 25px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .img-section {
            padding: 25px 15px 15px;
            text-align: center;

            img,
            .bg-size {
                width: 50%;
                margin: 0 auto;
            }

            a {
                h5 {
                    margin-bottom: 5px;
                    text-transform: capitalize;
                    margin-top: 10px;
                    font-weight: 600;
                    color: black;
                }
            }

            h5 {
                margin-bottom: 0;
                line-height: 1.2;
                color: rgba(black, 0.7);
            }
        }

        .detail-part {
            .title-detail {
                background-color: #f7f8fa;
                text-align: center;
                padding: 8px 0;
                border-top: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;

                h5 {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            .inner-detail {
                padding: 15px;
                text-align: center;

                p {
                    margin-bottom: 0;
                    line-height: 1.2;
                    letter-spacing: 0.05em;
                }
            }
        }

        .btn-part {
            text-align: center;
            padding: 15px;
            border-top: 1px solid $round-border;

            .btn-solid,
            .btn-outline {
                padding: 5px 10px;
            }
        }
    }

    .slick-slide {
        >div {
            border: 1px solid $round-border;
            border-left: none;
        }

        &:first-child {
            border-left: 1px solid $round-border;
        }
    }
}

.compare-padding {
    padding-bottom: 50px;

    h2 {
        margin-bottom: 20px;
    }

    .compare-table {
        border: 1px solid #eeeeee;
        border-radius: 8px;

        tr {
            &:first-child {
                td {
                    padding: 25px 15px 15px;
                }
            }

            &:last-child {
                background-color: transparent;
            }

            &:nth-child(even) {
                background-color: #f8f8f8;
            }

            td {
                border-right: 1px solid #eeeeee;
                text-align: center;
                position: relative;
                padding: 8px;
                min-width: 200px;

                &.title-detail {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                }

                &.inner-detail,
                &.btn-part {
                    padding: 15px;
                    margin-bottom: 0;
                    line-height: 1.4;
                    letter-spacing: unset;
                    font-weight: 500;
                    font-size: 16px;
                    color: #777;
                }

                .compare-rating {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    .rating-list {
                        flex-wrap: nowrap;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        i {
                            color: #ffbc37;
                        }
                    }

                    span {
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 500;
                        color: #777;
                    }
                }

                .btn {
                    padding: 5px 10px;
                }

                .close-btn {
                    background-color: #f8f8f8;
                    font-size: 18px;
                    position: absolute;
                    line-height: 1;
                    top: 7px;
                    color: #424242;
                    font-weight: 400;
                    right: 7px;
                    border-radius: 0;
                    border: 1px solid #eeeeee;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .img-section {
                    img {
                        height: 190px;
                        margin: 0 auto;
                    }

                    a {
                        display: block;
                        text-transform: capitalize;
                        margin-top: 10px;
                        font-weight: 600;
                        color: #222;
                        line-height: 1.2;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.compare-lable {
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    top: 20px;
    left: 16px;
    letter-spacing: 0.1em;
    z-index: 1;
}

.compare-page {
    .table-wrapper {
        padding-bottom: 0;

        .table {
            border: 1px solid $white-dark;
            text-transform: capitalize;
            color: $dark-font;

            .product-name {
                width: 15%;
            }

            .featured-image {
                width: 185px;
            }

            td {
                border-top: none;
            }

            thead {
                .th-compare {
                    td {
                        font-size: 14px;
                        font-weight: 700;
                        background: $top-header;
                        border-right: 1px solid $white-dark;
                        border-bottom-color: #eee;
                    }

                    th {
                        text-align: left;
                        border-bottom: 1px solid $white-dark;
                        border-right: 1px solid $white-dark;

                        .remove-compare {
                            float: left;
                            border: none;
                            background: transparent;
                            padding: 0;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }

            tbody {
                tr {
                    th {
                        background: $top-header;
                    }

                    td {
                        border-right: 1px solid $white-dark;
                        position: relative;
                    }

                    th,
                    td {
                        padding: 15px;
                        vertical-align: top;
                        border-bottom: 1px solid $white-dark;
                        border-top: 1px solid $white-dark;
                        border-right: 1px solid $white-dark;
                    }

                    .grid-link__title {
                        font-size: 15px;
                    }

                    .product_price {
                        margin: 15px 0;

                        span.money {
                            padding-left: 5px;
                        }
                    }

                    p {
                        color: $dark-font;
                        font-size: 13px;
                        line-height: 20px;
                        margin: 0;
                    }

                    .add-to-cart,
                    .select-option-cls {
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}

// typography page //
.typography_section {
    .typography-box {
        margin-bottom: 30px;

        p {
            line-height: 22px;
        }

        .headings {
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 20px;
            background-color: rgba($grey-about, 0.5);
            padding: 8px 12px;

            h3 {
                text-transform: capitalize;
                color: #2d2d2d;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 20px;
            }

            span {
                color: $grey;

                code {
                    color: $grey;
                }
            }
        }

        .typo-content {
            &.heading_content {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-weight: 400;
                    color: black;
                    text-transform: uppercase;
                    line-height: 1;
                    margin-bottom: 10px;
                }
            }

            .sub-title {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 5px;
                margin-bottom: 8px;
                font-size: 18px;
                color: $dark-font;
                text-transform: capitalize;
            }

            &.product-pagination {
                border: none;
                margin: 0;

                .pagination {
                    .page-item {

                        a {
                            padding: 10px 14px;
                        }
                    }
                }
            }

            &.input_button {
                input {
                    margin-bottom: 10px;
                }

                label {
                    font-size: 16px;
                    text-transform: capitalize;
                    padding-left: 5px;
                }
            }

            &.loader-typo {
                width: 100%;
                justify-content: center;
                display: flex;

                .pre-loader {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    background-color: var(--theme-color);
                    border-radius: 50%;

                    &:after {
                        content: "";
                        position: absolute;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        border: 0 solid$white;
                        transform: translate(-50%, -50%);
                        animation: loading 1000ms ease-out forwards infinite;
                        transition: all 0.3s ease;
                    }
                }
            }

            .footer-social {
                margin-top: 0;
                justify-content: center;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    code {
        background-color: #f7f7f7;
        padding: 3px;
        margin: 0 3px;
        border-radius: 2px;
    }
}

// account page //
.profile-section {
    background-color: #f5f5f5;
    border: 1px solid $round-border;
    padding: 15px;

    .profile-detail {
        .profile-name {
            font-weight: 700;
        }

        p {
            margin-bottom: 8px;
        }
    }
}

// vendor pages
.vendor-cover {
    .bg-size {
        height: 350px;
    }
}

.vendor-profile {
    // margin-top: -70px;

    .profile-left {
        display: flex;
        padding: 20px;
        background-color: #f8f8f8;
        border: 1px solid #eeeeee;
        border-radius: 0;

        .profile-image {
            text-align: center;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60%;
                display: block;
                background-color: #fff;
                height: 45%;
                margin: 0 auto 16px;
                padding: 14px;
                border-radius: 0;
            }

            h3 {
                font-weight: 600;
                margin-bottom: 0;
                color: #222;
                letter-spacing: unset;
                line-height: 1.3;
            }

            h6 {
                margin-bottom: 0;
                margin-top: 0;
                letter-spacing: unset;
                text-transform: unset;
                font-weight: 500;
            }
        }

        .profile-detail {
            border-left: 1px solid rgba(34, 38, 42, .12);
            padding-left: 20px;
            margin-left: 20px;
            width: 60%;
            display: flex;
            align-items: center;

            h6 {
                margin-bottom: 0;
                margin-top: 0;
                letter-spacing: 0.05em;
                text-transform: capitalize;
            }

            p {
                margin-bottom: 0;
                font-size: 15px;
                line-height: 1.5;
                font-weight: 400;

                +p {
                    margin-top: 10px;
                }
            }
        }

        .vendor-contact {
            border-left: 1px solid rgba(34, 38, 42, .12);
            padding-left: 20px;
            margin-left: 20px;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .footer-social {
                margin-top: 0;
                margin-bottom: 12px;
                justify-content: center;

                i {
                    color: #989898;
                }

                li {
                    padding: 0 5px;
                }
            }

            h6 {
                text-transform: capitalize;
                color: #222;
                font-weight: 600;
                margin-bottom: 8px;
                font-size: 16px;
            }

            .vendor-details-box {
                margin-top: 12px;
                padding-top: 9px;
                justify-content: center;
                border-top: 1px solid rgba(34, 38, 42, 0.12);
            }

            .vendor-details {
                display: grid;
                gap: 7px;

                li {
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    justify-content: center;

                    i {
                        font-size: 20px;
                        line-height: 1;
                        color: $grey;
                    }

                    a {
                        text-transform: none;
                    }

                    h5,
                    a {
                        margin: 0;
                        font-weight: 500;
                        line-height: 1;
                        color: #464646;
                    }
                }
            }

            a {
                text-transform: capitalize;
            }
        }

        +.profile-left {
            margin-top: 40px;
        }
    }

    .page-link {
        border-color: $grey3;
        background-color: $grey-lighter;

        &:hover {
            background-color: #f1f1f1;
        }
    }
}

.become-vendor {
    background-color: $grey-about;
    overflow: hidden;

    h4 {
        margin-bottom: 40px;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1.3;
    }

    .step-bg {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -0;
            right: -0;
            height: 100%;
            display: block;
            background: url("../images/vendor/step-arrow.png") no-repeat center center;
        }

        .row {
            margin-left: -50px;
            margin-right: -50px;

            >div {
                padding-left: 50px;
                padding-right: 50px;

                &:nth-child(1),
                &:nth-child(3) {
                    margin-bottom: 30px;
                }

                &:nth-child(2) {
                    margin-top: 30px;
                }
            }
        }

        h4 {
            margin-bottom: 10px;
        }

        .step-box {
            position: relative;
            padding: 26px 23px;
            transition: all .3s ease;
            background-color: #fff;
            text-align: center;
            border-radius: 0;
            border: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .steps {
                position: relative;
                width: 80px;
                height: 80px;
                background: #f8f8f8;
                margin: 0 auto 25px;
                color: var(--theme-color);
                text-align: center;
                font-weight: 500;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: calc(28px + .0075*(100vw - 320px));
            }

            p {
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
    }
}

.start-selling {
    h4 {
        margin-bottom: 10px;
        line-height: 1.3;
        font-size: 22px;
        font-weight: 600;
    }

    p {
        line-height: 1.7;
        letter-spacing: unset;
        margin-bottom: 0;
        font-weight: 400;
        font-size: calc(14px + .00125*(100vw - 320px));
    }

    .btn {
        margin-top: 20px;
    }
}

// vendor dashboard
.vendor-dashboard {
    .dashboard-box {
        padding: 20px 10px;
        background-color: $grey-lighter;

        .date-picker-box {
            display: flex;

            .input-group {
                width: 50%;
                padding: 0 10px;
            }
        }
    }
}

// dashboard
.dashboard-section {
    .dashboard-sidebar {
        position: sticky;
        top: 30px;
        z-index: 1;
        padding: 30px 0 15px 0;
        background-color: $top-header;

        .profile-top {
            padding: 0 16px;
            margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            .profile-image {
                position: relative;

                img {
                    width: 130px;
                    margin: 0 auto;
                    display: block;
                    border-radius: 100%;
                }

                .profile-edit {
                    position: absolute;
                    bottom: 0;
                    right: 30%;

                    svg {
                        stroke: var(--theme-color);
                    }
                }
            }

            .profile-detail {
                h5 {
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 5px;
                    letter-spacing: unset;
                }

                h6 {
                    color: $grey;
                    font-weight: 400;
                    margin-bottom: 3px;
                }
            }
        }

        .faq-tab {
            .nav-tabs {
                box-shadow: none;
                padding: 0;
            }
        }
    }

    .counter-section {
        .counter-box {
            background-color: $top-header;
            padding: 30px 30px;
            display: flex;
            align-items: center;
            border-left: 3px solid var(--theme-color);
            border-radius: 8px;

            img {
                height: 50px;
                margin-right: 20px;
            }

            h3 {
                font-weight: 700;
                color: var(--theme-color);
                margin-bottom: 4px;
                font-size: 20px;
            }

            h5 {
                margin-bottom: 0;
                text-transform: capitalize;
            }
        }
    }

    .faq-content {
        .card {
            border: none;
            background-color: $top-header;
            margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .dashboard-table {
        h3 {
            text-transform: capitalize;
            font-size: 17px;
            color: $dark-font;
            margin-bottom: 15px;
            font-weight: 600;
        }

        img {
            width: 50px;
        }

        .table {
            thead {
                th {
                    border-top: none;
                }
            }

            th,
            td {
                vertical-align: middle;
                text-transform: capitalize;
                text-align: center;
            }
        }
    }

    .top-sec {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        h3 {
            text-transform: capitalize;
            color: $black;
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 0;
            font-weight: 600;
        }

        .btn {
            margin-left: auto;
            text-transform: capitalize;
            padding: calc(5px + (7 - 5) * ((100vw - 320px) / (1920 - 320))) calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .dashboard-box {
        .dashboard-title {
            margin-bottom: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;

            h4 {
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 0;
            }

            span {
                margin-left: 30px;
                padding: 2px 16px;
                border-radius: 2px;
                text-transform: capitalize;
                color: var(--theme-color);
                cursor: pointer;
            }
        }

        .setting-list {
            display: grid;
            gap: 7px;

            .form-check {
                margin: 0;
                padding: 0;
                min-height: unset;
                display: flex;
                align-items: center;
                gap: 10px;

                .form-check-label {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: unset;
                }
            }
        }
    }

    .apexcharts-toolbar {
        z-index: 1;
    }
}

.faq-tab {
    .nav-tabs {
        display: block;
        border-bottom: none;
        background-color: $white;
        padding: 10px 0;

        .nav-item {
            display: block;
            margin-bottom: 0;

            .nav-link {
                text-transform: capitalize;
                color: $black;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                border: none;
                transition: all 0.3s ease-in-out;
                border-radius: 0;
                background-color: $top-header;
                cursor: pointer;

                &.active {
                    border: none;
                    border-right: 2px solid var(--theme-color);
                    border-radius: 0;
                    color: var(--theme-color);
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    border: none;
                    border-right: 2px solid var(--theme-color);
                    color: var(--theme-color);
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

// order tracking
.tracking-page {
    .title-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        h5 {
            margin: 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.4;
        }

        .btn {
            background-color: var(--theme-color);
            color: #fff;
            text-transform: none;
            font-weight: 600;
            font-size: calc(14px + .00125*(100vw - 320px));
            line-height: 1.3;
            padding: 8px 20px;
            border-radius: 0;
        }
    }

    .tacking-table {
        border: 1px solid #eeeeee;
        border-radius: 0;
        margin: 0;

        tr {
            &:last-child {
                td {
                    border-bottom: unset;
                }
            }

            th {
                font-weight: 600;
                padding: 14px;
                text-align: center;
                vertical-align: middle;
                color: #232323;
                border-bottom: 1px solid #eeeeee;
                max-width: 220px;
                min-width: 160px;
                font-size: 18px;
                background-color: #f8f8f8;
            }

            td {
                max-width: 220px;
                min-width: 160px;
                padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
                color: #232323;
                border-bottom: 1px solid #eeeeee;
                font-weight: 500;
                text-align: center;
                vertical-align: middle;

                h6 {
                    color: #222;
                    font-weight: 500;
                    margin: 0;
                    line-height: 1.3;
                    font-size: 16px;
                }
            }

            .product-image {
                img {
                    width: 70px;
                }
            }
        }
    }

    .summary-details {
        .details-box {
            padding: calc(15px + 7*(100vw - 320px) / 1600) calc(15px + 7*(100vw - 320px) / 1600);
            border-radius: 0;
            border: 1px solid #eeeeee;
            background-color: #f8f8f8;
        }

        .order-title {
            font-size: calc(18px + .00125*(100vw - 320px));
            padding-bottom: 11px;
            margin-bottom: 16px;
            letter-spacing: unset;
            font-weight: 600;
            color: #222;
            border-bottom: 1px solid #dee2e6;
        }

        .customer-detail {
            label {
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 6px;
            }

            h4 {
                margin: 0;
                font-size: 15px;
                letter-spacing: unset;
                font-weight: 500;
                text-transform: unset;
                line-height: 1.3;
                color: #777;
            }
        }

        .tracking-total {
            display: grid;
            gap: 6px;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 500;

                &:last-child {
                    padding-top: 8px;
                    margin-top: 2px;
                    border-top: 1px solid #dee2e6;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
}

.blog-page {
    .no-sidebar {
        .blog-box {
            .blog-image {
                height: calc(150px + (280 - 150) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .blog-sidebar {
        h6 {
            margin-bottom: 0;
            line-height: 20px;
            color: $font-color;
            font-weight: 700;
        }

        p {
            line-height: 20px;
            margin-bottom: 0;
            color: $grey2;
        }

        h4 {
            color: $dark-font;
            text-transform: unset;
            font-weight: 600;
            margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.3;
            letter-spacing: unset;
            font-size: calc(17px + (21 - 17) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: 6px;
            border-bottom: 1px solid $white-dark;
        }

        .theme-card {
            +.theme-card {
                margin-top: calc(24px + (45 - 24) * ((100vw - 320px) / (1920 - 320)));
            }

            .recent-blog {
                li {
                    +li {
                        margin-top: calc(21px + (30 - 21) * ((100vw - 320px) / (1920 - 320)));
                    }

                    .media {
                        img {
                            width: 100px;
                            margin-right: 10px;
                        }

                        .media-body {
                            a {
                                h5 {
                                    font-weight: 600;
                                    line-height: 1.45;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }

                    .blog-box {
                        align-items: unset;
                        gap: 10px;
                        border: unset;
                        padding-bottom: 0;
                        border-radius: 0;

                        .blog-image {
                            width: calc(90px + (100 - 90) * ((100vw - 320px) / (1920 - 320)));
                            height: auto;
                            margin: 0;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                margin: 0;
                            }
                        }

                        .blog-content {
                            width: calc(100% - calc(90px + (100 - 90) * ((100vw - 320px) / (1920 - 320))) - 10px);

                            a {
                                h5 {
                                    padding-bottom: 0;
                                    text-transform: unset;
                                    margin: 0;
                                    line-height: 1.4;
                                    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                                    font-weight: 500;
                                }
                            }

                            h6 {
                                line-height: 1.4;
                                color: $grey;
                                font-weight: 500;
                                margin-bottom: calc(2px + (5 - 2) * ((100vw - 320px) / (1920 - 320)));
                                font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                            }
                        }
                    }
                }
            }

            .tags {
                display: flex;
                gap: 9px 12px;
                flex-wrap: wrap;

                li {
                    padding: 6px calc(9px + (13 - 9) * ((100vw - 320px) / (1920 - 320)));
                    transition: all 0.2s ease-in-out;
                    background-color: $white;
                    border: 1px solid $white-dark;

                    a {
                        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                        color: $grey;
                        text-transform: unset;
                        font-weight: 500;
                    }

                    &:hover {
                        background-color: var(--theme-color);
                        border-color: var(--theme-color);

                        a {
                            color: $white;
                        }
                    }
                }
            }

            .popular-blog {
                li {
                    .blog-date {
                        background-color: var(--theme-color);
                        height: 70px;
                        width: 70px;
                        margin: 0 15px 0 0;
                        padding: 15px;

                        span {
                            display: block;
                            text-align: center;
                            color: $white;
                            text-transform: uppercase;
                        }
                    }

                    +li {
                        margin-top: 30px;
                    }

                    p {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .blog-media {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .blog-right {
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;

            h6 {
                color: var(--theme-color);
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            h4 {
                color: $font-color;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 15px;
            }

            p {
                line-height: 1.8;
                margin-bottom: 0;
                letter-spacing: 0.04em;
            }

            ul {
                color: $grey;
                margin-bottom: 15px;

                li {
                    font-size: 14px;
                    line-height: 1.5;

                    +li {
                        padding-left: 15px;
                        margin-left: 15px;
                        border-left: 1px solid $round-border;
                    }

                    i {
                        padding-right: 3px;
                    }
                }
            }
        }
    }

    /*  Grid Blog */
    .blog-box {
        overflow: hidden;
        position: relative;

        &:hover {
            .blog-image {
                img {
                    transform: scale(1.12);
                    overflow: hidden;
                }
            }

            .blog-detail {
                label {
                    color: $white;

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        .blog-box-image {
            overflow: hidden;
            border-radius: 0;

            a {
                transition: all 0.2s ease-in-out;
            }
        }

        .blog-image {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            border-radius: 0;
            height: calc(140px + (180 - 140) * ((100vw - 320px) / (1920 - 320)));

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition:
                    opacity 0.5s,
                    transform 1.25s cubic-bezier(0, 0, 0.44, 1.18);
            }

            label {
                position: absolute;
                bottom: 10px;
                left: 10px;
                background-color: var(--theme-color);
                color: $white;
                border-radius: 0;
                border: none;
                padding: 8px 10px;
                font-size: 12px;
                letter-spacing: 0.9px;
                box-shadow: 3px 3px 6px rgba($black, 0.2);
            }
        }

        .blog-contain {
            padding: 0;

            .blog-label {
                display: flex;
                flex-wrap: wrap;
                margin-block: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320))) calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
                align-items: center;

                >span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    color: $dark-font;

                    +span {
                        margin-left: 10px;
                        padding-left: 10px;
                        border-left: 1px solid $white-dark;
                    }
                }
            }

            a {
                color: $dark-font;

                h3 {
                    font-weight: 600;
                    line-height: 1.5;
                    text-transform: capitalize;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                    transition: all 0.2s ease-in-out;
                    letter-spacing: unset;
                    margin: 0;
                    color: $dark-font;
                }
            }

            p {
                display: none;
            }

            .blog-button {
                border: none;
                display: inline-flex;
                align-items: center;
                position: relative;
                z-index: 0;
                overflow: hidden;
                border-radius: 0;
                font-weight: 500;
                color: var(--theme-color);
                transition: all 0.2s ease-in-out;
                font-size: calc(15px + 1 * (100vw - 320px) / 1600);

                i {
                    margin-left: 6px;
                    font-size: 20px;
                    line-height: 1;
                    transform: rotate(-45deg);
                    margin-top: -3px;
                }
            }
        }
    }

    .blog-label-tag {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: var(--theme-color);
        z-index: 1;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        color: $white;
    }

    .blog-featured-tag {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: var(--theme-color);
        z-index: 1;
        padding: 8px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: $white;
        line-height: 1;
        font-weight: 600;
    }

    .blog-list {
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 0;
        padding: 0;

        @media (max-width: 575px) {
            display: block;
        }

        .blog-image {
            margin: 0;
            border-radius: 0;
            height: calc(240px + (280 - 240) * ((100vw - 575px) / (1920 - 575)));
            width: calc(230px + (340 - 230) * ((100vw - 575px) / (1920 - 575)));

            @media (max-width: 575px) {
                width: 100%;
            }
        }

        .blog-contain {
            width: calc(100% - calc(230px + (340 - 230) * ((100vw - 575px) / (1920 - 575))) - 15px);
            padding: 0 20px 0 0;

            @media (max-width: 575px) {
                width: 100%;
                padding: 0;
                padding-top: calc(12px + (20 - 12) * ((100vw - 320px) / (576 - 320)));
            }

            a {
                h3 {
                    font-weight: 600;
                    line-height: 1.5;
                    text-transform: capitalize;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    transition: all 0.2s ease-in-out;
                    letter-spacing: unset;
                    margin: 0;
                    color: $dark-font;
                }
            }

            p {
                line-height: 1.6;
                color: $grey;
                margin-bottom: 0;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                margin-top: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
            }

            .blog-label {
                display: flex;
                flex-wrap: wrap;
                margin-block: 4px 0;
                align-items: center;

                @media (max-width: 380px) {
                    display: block;
                }

                >span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    color: $grey;

                    +span {
                        margin-left: 10px;
                        padding-left: 10px;
                        border-left: 1px solid $white-dark;

                        @media (max-width: 380px) {
                            margin-left: unset;
                            padding-left: unset;
                            border-left: unset;
                            margin-top: 4px;
                        }
                    }
                }
            }

            .blog-button {
                border: none;
                display: inline-flex;
                align-items: center;
                position: relative;
                z-index: 0;
                overflow: hidden;
                border-radius: 0;
                font-weight: 500;
                padding: 8px calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320)));
                background-color: var(--theme-color);
                transition: all 0.2s ease-in-out;
                margin-top: calc(9px + (14 - 9) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                color: $white;

                i {
                    margin-left: 10px;
                    font-size: 20px;
                    line-height: 1;
                    display: none;
                }

                &:hover {
                    color: $white;

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .blog-detail {
        padding: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 0;

        h2 {
            font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.4;
        }

        label {
            font-size: 12px;
            padding: 7px 11px;
            border-radius: 50px;
            color: var(--theme-color);
            margin-bottom: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            overflow: hidden;
            z-index: 0;
            transition: all 0.2s ease-in-out;

            &::before {
                top: 0;
                left: 0;
                background-color: var(--theme-color);
                opacity: 0.1;
                z-index: -1;
                transition: all 0.2s ease-in-out;
            }
        }

        a {
            display: block;
        }

        h3 {
            line-height: 1.4;
            margin-bottom: 5px;
            font-weight: 600;
        }

        h5 {
            font-size: 15px;
            margin-top: 10px;
            font-weight: 500;
        }

        .blog-list {
            margin-top: 15px;
            gap: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));

            span {
                margin-bottom: -5px;
                font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
            }

            .blog-social-icon {
                position: relative;

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    transition: all 0.2s ease-in-out;
                    opacity: 0;

                    li {
                        transition: all 0.2s ease-in-out;

                        .fab {
                            font-size: 14px;
                        }
                    }
                }

                &:hover {
                    .icon {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.blog-sidebar {
    .theme-card {
        position: relative;
        background-color: $top-header;
        border: 1px solid $white-dark;
        padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 0;

        .categories {
            display: flex;
            gap: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
            flex-wrap: wrap;
            margin-bottom: -4px;

            li {
                position: relative;
                transition: all 0.3s ease-in-out;
                z-index: 0;
                width: 100%;
                padding-left: 0;
                padding-top: 0;

                a {
                    display: block;
                    font-size: calc(14px + 0.00125 * (100vw - 320px));
                    color: $dark-font;
                    display: flex;
                    align-items: baseline;
                    gap: 8px;

                    h5 {
                        margin: 0;
                        padding: 0;
                        font-weight: 500;
                        text-transform: none;
                        line-height: 1.3;
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }
        }
    }
}

.blog-detail-page {
    .blog-detail-contain {
        p {
            color: $grey;
            line-height: 1.6;
            margin: 0;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;

            +p {
                margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .blog-detail {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            margin-bottom: 28px;
            width: 100%;
        }

        h3 {
            color: $dark-font;
            font-weight: 700;
            margin-bottom: calc(9px + 4 * (100vw - 320px) / 1600);
            letter-spacing: unset;
            font-size: calc(20px + (38 - 20) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.3;
        }

        .post-social {
            color: $grey;
            padding-bottom: 21px;

            li {
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                color: $grey;

                +li {
                    padding-left: 11px;
                    margin-left: 11px;
                    border-left: 1px solid $white-dark;
                }
            }
        }
    }
}

.dashboard-section {
    .dashboard-sidebar {
        position: sticky;
        top: 20px;
        z-index: 1;
        padding: 30px 0 15px 0;
        background-color: $top-header;
        border: 1px solid $white-dark;
        border-radius: 0;

        @media (max-width: 991px) {
            position: fixed;
            top: 0;
            left: -350px;
            width: calc(300px + (320 - 300) * ((100vw - 320px) / (992 - 320)));
            height: 100vh;
            overflow: hidden auto;
            padding-top: 0;
            border: unset;
            transition: all 0.25s ease-in-out;
            border-radius: 0;
            z-index: 11;
            background-color: $white;

            &.open {
                left: 0;
            }
        }

        .back-btn {
            display: none;

            @media (max-width: 991px) {
                padding: 15px 18px;
                display: flex;
                border: unset;
                align-items: center;
                gap: 5px;
                border-bottom: 1px solid $white-dark;
                width: 100%;
                position: sticky;
                top: 0;
                left: 0;
                background-color: $white;
                z-index: 2;

                i {
                    font-size: 20px;
                    line-height: 1;
                }

                span {
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }

        .profile-top {
            padding: 0 16px;
            display: flex;
            gap: 13px;
            align-items: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $white-dark;

            @media (max-width: 991px) {
                margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (991 - 320)));
                background-color: $top-header;
                padding: 15px;
            }

            .profile-top-box {
                width: 56px;
                height: 56px;
                position: relative;
            }

            .user-icon-2 {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                width: 20px;
                height: 20px;
                background-color: $white;
                border: 1px solid $white-dark;
                display: flex;
                align-items: center;
                color: #777;
                justify-content: center;
                cursor: pointer;
                overflow: hidden;

                &.close-icon {
                    top: 0;
                }

                input[type="file"] {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    cursor: pointer;
                }

                i {
                    line-height: 1;
                }
            }

            .profile-image {
                margin: 0 auto;
                text-align: center;
                z-index: 1;
                position: relative;
                width: 56px;
                height: 56px;
                border-radius: 0;
                overflow: hidden;

                &.vendor-image {
                    width: 80px;
                    height: 80px;
                    background-color: #fff;
                    padding: 8px;
                    border: 1px solid #eee;

                    img {
                        box-shadow: unset;
                        background-color: transparent;
                    }

                    ~.profile-detail {
                        width: calc(100% - 80px - 13px);
                    }
                }

                &:hover {
                    .user-icon {
                        opacity: 1;
                    }
                }

                .user-icon {
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transition: opacity 0.15s ease-in-out;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background-color: rgba(34, 34, 34, 0.631);
                    border: 1px solid rgba(207, 207, 207, 0.4);
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    color: $white;
                    justify-content: center;
                    cursor: pointer;
                    overflow: hidden;
                    font-size: 24px;

                    input[type="file"] {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        cursor: pointer;
                    }

                    i {
                        line-height: 1;
                    }

                    @media (max-width: 991) {
                        opacity: 1;
                        height: auto;
                        border-radius: 0;
                        inset: unset;
                        bottom: 0;
                        transform: unset;
                        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                        padding-block: 5px;
                        border: unset;
                        background-color: rgba(34, 34, 34, 0.8);
                    }
                }

                .user-round {
                    height: 56px;
                    width: 56px;
                    object-fit: contain;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin: 0 auto;

                    h4 {
                        font-size: 34px;
                        margin: 0;
                        font-weight: 600;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    background-color: $grey-lighter;
                    box-shadow: 2px 3px 8px rgba(34, 34, 34, 0.32);
                }

                .cover-icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    left: 55%;
                    z-index: 1;
                    background-color: $white;
                    border-radius: 100%;
                    color: $grey;

                    i {
                        position: relative;

                        input {
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            opacity: 0;
                            width: 35px;
                        }
                    }
                }
            }

            .profile-detail {
                width: calc(100% - 56px - 13px);

                h5 {
                    text-transform: capitalize;
                    font-weight: 600;
                    margin-bottom: 0;
                }

                h6 {
                    color: $grey;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
        }

        .faq-tab {
            .nav-tabs {
                box-shadow: none;
                padding: 0;
                background-color: $top-header;
            }
        }
    }

    .counter-section {
        padding: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
        background-color: $top-header;
        border: 1px solid $white-dark;
        border-radius: 0;

        .counter-box {
            background-color: $white;
            padding: 18px;
            display: flex;
            align-items: center;
            border-left: 3px solid var(--theme-color);
            border-radius: 0;

            img {
                height: 50px;
                margin-right: 20px;
            }

            h3 {
                font-weight: 600;
                color: var(--theme-color);
                margin-bottom: 4px;
                font-size: 20px;
                letter-spacing: unset;
            }

            h5 {
                margin-bottom: 0;
                font-weight: 500;
                color: $grey;
                line-height: 1.3;
            }
        }

        .box-account {
            .box {
                .box-content {
                    padding-left: 22px;
                    display: grid;
                    gap: 8px;

                    li {
                        display: list-item;
                        list-style: disc;

                        &::marker {
                            color: #777;
                            font-size: 13px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

    .faq-content {
        .card {
            border: 1px solid $white-dark;
            background-color: $top-header;
            border-radius: 0;
        }

        .show-btn {
            background-color: var(--theme-color);
            border-radius: 0;
            padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(12px + (19 - 12) * ((100vw - 320px) / (1920 - 320)));
            text-transform: unset;
            font-weight: 600;
            color: $white;
            margin-bottom: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
            display: none;

            @media (max-width: 991px) {
                display: block;
            }
        }

        .dashboard-table {
            border: unset;
            background-color: transparent;

            .card-body {
                padding: 0;
            }
        }

        .download-table {
            border: 1px solid $white-dark;
            margin-top: 20px;

            .user-download-table {
                .dropdown {
                    .dropdown-toggle {
                        background-color: $top-header;
                        border: 1px solid $white-dark;
                        padding: 12px 18px;
                        font-weight: 500;

                        &::after {
                            content: "\ea4e";
                            font-family: "remixicon" !important;
                            font-style: normal;
                            -webkit-font-smoothing: antialiased;
                            margin: 0;
                            vertical-align: unset;
                            border: unset;
                            font-size: 18px;
                            line-height: 1;
                            font-weight: normal;
                        }
                    }
                }

                thead {
                    tr {
                        th {
                            background-color: $top-header;
                            text-align: center;
                            padding: 14px 10px;
                            font-size: 18px;
                            font-weight: 600;
                            border-bottom: 1px solid $white-dark;
                        }
                    }
                }

                tbody {
                    td {
                        text-align: center;
                        vertical-align: middle;
                        padding: 14px 10px;
                        border-color: $white-dark;
                    }

                    tr {
                        &:last-child {
                            td {
                                border-bottom: unset;
                            }
                        }
                    }

                    .table-name {
                        width: 55%;
                        text-align: center;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .table-image {
                        width: auto;
                        height: 100px;
                    }
                }
            }
        }

        .download-detail {
            padding: 12px calc(15px + 7 * (100vw - 320px) / 1600);
            border: 1px solid $white-dark;
            background-color: $top-header;
            border-radius: 0;

            .download-form {
                gap: 13px;

                .form-control {
                    padding: calc(10px + 2 * (100vw - 320px) / 1600) calc(13px + 6 * (100vw - 320px) / 1600);
                    font-weight: 500;
                    border-radius: 0 !important;
                    border: 1px solid $white-dark;
                    font-size: calc(14px + 0 * (100vw - 320px) / 1600);
                }

                button {
                    padding: 12px 24px;
                    font-weight: 600;
                    border-radius: 0 !important;
                    letter-spacing: unset;
                    background-color: var(--theme-color);
                    border: 1px solid var(--theme-color);
                    color: $white;

                    &:hover {
                        background-color: $white;
                        color: var(--theme-color);
                    }
                }
            }
        }
    }

    .wallet-table {
        .table-responsive {
            border: 1px solid $white-dark;
            border-radius: 0;
        }

        .order-table {
            border: unset;
            margin: 0;

            thead {
                th {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 14px;
                }
            }

            th,
            td {
                max-width: 220px;
                min-width: 160px;
                padding: 15px;
                color: $dark-top;
                border-bottom: 1px solid $white-dark;
                font-weight: 500;
                text-align: center;
                vertical-align: middle;
                background-color: transparent;

                h6 {
                    color: $dark-font;
                    font-weight: 500;
                    margin: 0;
                    line-height: 1.3;
                    font-size: 16px;
                }

                &.image-box {
                    padding: 10px;

                    img {
                        height: 70px;
                        width: auto;
                    }
                }
            }

            tr {
                &:last-child {
                    td {
                        border-bottom: unset;
                    }
                }

                a {
                    color: $dark-font;
                }
            }

            .product-image {
                text-align: center !important;

                img {
                    width: 70px;
                }

                h6 {
                    font-size: 15px;
                    font-weight: 500;
                    margin: 0;
                }

                a {
                    color: $dark-font;
                }
            }
        }
    }

    .product-table {
        tr {

            th,
            td {
                &:first-child {
                    text-align: center;
                }
            }
        }
    }

    .title-header {
        h5 {
            margin: 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.4;

            i {
                color: $dark-font;
            }
        }

        .right-option {
            a {
                width: 100%;
                background-color: var(--theme-color);
                color: $white;
                text-transform: none;
                font-weight: 600;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1.3;
                padding: 8px 20px;
                border-radius: 0;

                &:hover {
                    border: 1px solid var(--theme-color);
                    background-color: $white;
                    color: var(--theme-color);
                }

                i {
                    font-weight: 500;
                }
            }
        }
    }

    .dashboard-table {
        h3 {
            text-transform: capitalize;
            font-size: 17px;
            color: $dark-font;
            margin-bottom: 15px;
            font-weight: 600;
        }

        .product-pagination {
            margin-top: 24px;

            .theme-pagination-block {
                padding: 0;
                background-color: transparent;
                justify-content: center;
            }
        }
    }

    .top-sec {
        display: flex;
        align-items: center;
        margin-bottom: calc(13px + (22 - 13) * ((100vw - 320px) / (1920 - 320)));

        &-2 {
            border-top: 1px solid $white-dark;
            margin-top: 34px;
            padding-top: 29px;
        }

        h3 {
            text-transform: capitalize;
            color: $dark-font;
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 0;
            font-weight: 600;
        }

        .btn {
            margin-left: auto;
            text-transform: capitalize;
            padding: calc(5px + (7 - 5) * ((100vw - 320px) / (1920 - 320))) calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .themeform-auth {
        .form-label {
            font-weight: 500;
            color: $grey;
            margin: 0;
        }

        .form-control {
            height: auto;
            padding: calc(10px + 2 * (100vw - 320px) / 1600) calc(13px + 6 * (100vw - 320px) / 1600);
            font-weight: 500;
            border-radius: 0;
            border: 1px solid $white-dark;
            font-size: calc(14px + 0 * (100vw - 320px) / 1600);
        }
    }

    .dashboard-box {
        padding: 0;
        background-color: #f8f8f8;
        border: 1px solid #eee;

        .dashboard-title {
            margin-bottom: calc(14px + (20 - 14)*((100vw - 320px) /(1920 - 320)));
            display: flex;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid #eee;

            h4 {
                text-transform: capitalize;
                font-weight: 600;
                margin-bottom: 0;
                font-size: 22px;
            }

            span {
                margin-left: auto;
                padding: 0;
                text-transform: capitalize;
                color: var(--theme-color);
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
            }
        }

        .dashboard-detail {
            padding: 0 16px 16px;

            ul {
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    &.details {
                        h5 {
                            font-size: 16px;
                            color: #222;
                            font-weight: 500;
                            letter-spacing: unset;
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
                            margin: 0;

                            span {
                                width: 200px;
                                display: block;
                                color: #777;
                                font-weight: 400;
                                margin: 0;
                            }
                        }

                        h6 {
                            text-transform: capitalize;
                            margin-bottom: 0;
                        }

                        span {
                            margin-left: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
                            border-radius: 2px;
                            text-transform: capitalize;
                            color: var(--theme-color);
                            cursor: pointer;
                        }

                        .left {
                            width: 150px;
                            margin-right: 15px;

                            h6 {
                                color: #4e4e4e;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;

                            h6 {
                                font-weight: 500;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .account-setting {
                h5 {
                    margin-bottom: 15px;
                    text-transform: capitalize;
                }

                .row {
                    >div {
                        .form-check {
                            margin-bottom: 5px;
                            padding-left: 0;
                            color: #606060;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .btn {
                    margin-top: 0;
                }

                +.account-setting {
                    margin-top: 20px;
                }
            }
        }
    }

    .radio_animated {
        position: relative;
        margin: 0;
        filter: unset;
        cursor: pointer;
        border: none;
        width: 1.25rem;
        height: 1.25rem;

        &:before {
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            z-index: 1;
            width: 0.75rem;
            height: 0.75rem;
            background: var(--theme-color);
            border-radius: 50%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 100%;
            border: 1px solid #eee;
        }

        &:checked {
            &::after {
                border-color: var(--theme-color);
            }

            &:before {
                transform: translate(-50%, -50%) scale(1);
            }
        }

        ~.form-check-label {
            margin: 0;
        }
    }

    .apexcharts-toolbar {
        z-index: 1;
    }
}

.user-dashboard-section {
    .box {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.7;
    }

    h6 {
        margin-bottom: 0;
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        line-height: 1.3;
    }

    .welcome-msg {
        margin-bottom: 20px;

        h4 {
            font-size: 20px;
            font-weight: 700;
            color: $dark-font;
        }

        p {
            line-height: 1.6;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            letter-spacing: unset;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .box-head {
        h4 {
            font-size: 18px;
            margin: 34px 0 14px;
            text-transform: capitalize;
            color: #333;
            font-weight: 600;
        }
    }

    .box {
        a {
            color: var(--theme-color);
        }

        h6 {
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.3;
        }

        .box-title {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            padding: 12px;
            position: relative;
            width: 100%;
            background-color: $white;
            border-radius: 8px;

            h3 {
                font-size: 16px;
                margin: 0;
                text-transform: capitalize;
                color: #333;
                line-height: 1.3;
                font-weight: 500;
            }

            >a {
                margin-left: auto;
                color: var(--theme-color);
            }
        }

        address {
            margin-bottom: 0;
        }
    }

    .faq-content {
        height: 100%;

        .box-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .loader-wrapper {
                position: relative;
                width: 100%;
                z-index: 4;
                height: 100%;
                inset: unset;
                background-color: $top-header;
                border: 1px solid $white-dark;
            }
        }
    }

    .tab-pane {
        .card-body {
            padding: calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 0;

            .order-title {
                font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                padding-bottom: 11px;
                margin-bottom: 16px;
                letter-spacing: unset;
                font-weight: 600;
                color: $dark-font;
                border-bottom: 1px solid #dee2e6;
            }

            .customer-detail {
                ul {
                    li {
                        label {
                            font-weight: 600;
                            font-size: 16px;
                            margin: 0 0 6px;
                        }

                        h4 {
                            margin: 0;
                            font-size: 15px;
                            letter-spacing: unset;
                            font-weight: 500;
                            text-transform: unset;
                            line-height: 1.3;
                            color: $grey;
                        }
                    }
                }
            }

            .tracking-total {
                ul {
                    display: grid;
                    gap: 6px;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;
                        font-weight: 500;

                        &:nth-child(3) {
                            font-weight: 600;
                        }

                        &:last-child {
                            padding-top: 8px;
                            margin-top: 2px;
                            border-top: 1px solid #dee2e6;
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }
                }
            }

            .wallet-point-box {
                display: flex;
                align-items: center;
                gap: 13px;

                .total-image {
                    width: 69px;
                    height: 69px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    padding: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .total-detail {
                    width: calc(100% - 69px - 13px);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .point-ratio {
                        background-color: $white;
                        padding: 15px 18px;
                        border-radius: 0;

                        .counter {
                            text-transform: none;
                            letter-spacing: unset;
                            font-size: 18px;
                            margin: 0;
                            color: $dark-top;
                            font-weight: 600;
                        }
                    }

                    .total-box {
                        h5 {
                            margin: 0;
                            font-weight: 500;
                            color: $grey;
                        }

                        h3 {
                            margin: 0;
                            margin-top: 3px;
                            font-size: 19px;
                            text-transform: none;
                            letter-spacing: normal;
                            font-weight: 600;
                            color: $dark-top;
                        }
                    }
                }
            }
        }
    }

    .wallet-point-box {
        display: flex;
        align-items: center;
        gap: 13px;
        padding: calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
        border: 1px solid #eeeeee;
        background-color: #f8f8f8;
        border-radius: 0;

        .total-image {
            width: 69px;
            height: 69px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            padding: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .total-detail {
            width: calc(100% - 69px - 13px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .point-ratio {
                background-color: $white;
                padding: 15px 18px;
                border-radius: 0;

                .counter {
                    text-transform: none;
                    letter-spacing: unset;
                    font-size: 18px;
                    margin: 0;
                    color: $dark-top;
                    font-weight: 600;
                }
            }

            .total-box {
                h5 {
                    margin: 0;
                    font-weight: 500;
                    color: $grey;
                }

                h3 {
                    margin: 0;
                    margin-top: 3px;
                    font-size: 19px;
                    text-transform: none;
                    letter-spacing: normal;
                    font-weight: 600;
                    color: $dark-top;
                }
            }
        }
    }

    .edit-link {
        color: var(--theme-color);
        margin-left: 10px;
        text-transform: capitalize;
    }

    .address-book-section {
        .select-box {
            .address-box {
                padding: 15px;
                background-color: $white;
                transition: all 0.3s ease-in-out;

                .top {
                    h6 {
                        text-transform: capitalize;
                        color: $dark-font;
                        font-weight: 600;
                        font-size: 14px;

                        span {
                            float: right;
                            background-color: var(--theme-color);
                            color: $white;
                            padding: 2px 15px;
                            font-size: 80%;
                            border-radius: 0;
                        }
                    }
                }

                .middle {
                    margin-top: 15px;

                    .address {
                        p {
                            margin-bottom: 5px;
                            color: rgba(0, 0, 0, 0.7);
                            line-height: 1.2;
                        }
                    }

                    .number {
                        margin-top: 15px;

                        p {
                            color: rgba(0, 0, 0, 0.7);
                            text-transform: capitalize;
                        }
                    }
                }

                .card-number {
                    margin-top: 15px;

                    h6,
                    h5 {
                        margin-bottom: 0;
                    }
                }

                .name-validity {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    h6 {
                        text-transform: capitalize;
                    }

                    h5,
                    h6 {
                        margin-bottom: 0;
                    }

                    .right {
                        margin-left: auto;
                    }
                }

                .bank-logo {
                    display: flex;
                    align-items: center;

                    img {
                        height: 22px;
                    }

                    .network-logo {
                        margin-left: auto;
                        width: 50px;
                        height: auto;
                    }
                }

                .address {
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: $grey;
                    }
                }

                .bottom {
                    border-top: 1px solid $white-dark;
                    display: flex;
                    text-align: center;
                    padding-top: 14px;
                    margin-top: 10px;
                    justify-content: space-between;

                    .bottom_btn {
                        width: 47.5%;
                        text-transform: capitalize;
                        font-weight: 600;
                        padding: 8px 4px;
                        border-radius: 0;
                        background-color: rgba(248, 248, 248, 1);
                        color: $dark-font;
                    }
                }
            }
        }

        .select-box.active {
            .address-box {
                transition: all 0.3s ease-in-out;
            }
        }
    }

    &.dashboard-section {
        .dashboard-table {
            .table {
                thead {
                    th {
                        font-size: 18px;
                        background-color: $top-header;
                    }
                }
            }
        }
    }

    .order-table {
        thead {
            tr {
                th {
                    background-color: transparent;
                }
            }
        }
    }
}

.faq-tab {
    .nav-tabs {
        display: block;
        border-bottom: none;
        background-color: $white;
        padding: 10px 0;

        .nav-item {
            display: block;
            margin-bottom: 0;

            @media (max-width: 991px) {
                width: 100%;
            }

            .nav-link {
                text-transform: capitalize;
                color: $dark-top;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                border: none;
                transition: all 0.2s ease-in-out;
                border-radius: 0;
                width: 100%;
                background-color: $top-header;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px;
                margin: 0;
                font-weight: 500;

                @media (max-width: 991px) {
                    background-color: $white;
                    padding-block: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
                }

                i {
                    width: calc(36px + (40 - 36) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(36px + (40 - 36) * ((100vw - 320px) / (1920 - 320)));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white;
                    border-radius: 0;
                    transition: all 0.2s ease-in-out;
                    border: 1px solid transparent;
                    font-size: calc(16px + (19 - 16) * ((100vw - 320px) / (1920 - 320)));

                    @media (max-width: 991px) {
                        background-color: $top-header;
                    }
                }

                &.active {
                    border: none;
                    border-radius: 0;
                    color: var(--theme-color);

                    i {
                        background-color: var(--theme-color);
                        color: $white;
                    }
                }

                &:hover {
                    border: none;
                    color: var(--theme-color);

                    i {
                        border: 1px solid var(--theme-color);
                    }
                }
            }
        }

        .logout-cls {
            border-top: 1px solid $white-dark;
            margin-top: 6px;
            padding-top: 14px;
            padding-inline: 19px;

            .loagout-btn {
                width: 100%;
                background-color: var(--theme-color);
                color: $white;
                text-transform: none;
                font-weight: 500;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1.3;
                padding: calc(9px + (11 - 9) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 0;
            }
        }
    }
}

.card {
    border: 1px solid $white-dark;
    background-color: $top-header;
    border-radius: 0;
    box-shadow: none !important;

    .card-body {
        padding: calc(15px + (12 - 15) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 0;

        .notification-list {
            display: grid;
            gap: 13px;

            li {
                display: block;
                border-radius: 0;
                border: 1px solid $white-dark;
                background-color: $white;
                padding: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320))) calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
                padding-left: calc(33px + (46 - 33) * ((100vw - 320px) / (1920 - 320)));
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
                    top: 31px;
                    width: 5px;
                    height: 5px;
                    background-color: #8e8e8e;
                    border-radius: 100%;
                }

                // +li {
                //     border-top: 1px solid $white-dark;
                // }

                &.unread {
                    position: relative;
                    background-color: transparent;
                    z-index: 0;
                    border: 1px solid var(--theme-color);

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--theme-color);
                        opacity: 0.09;
                        z-index: -1;
                    }

                    h4 {
                        color: var(--theme-color);
                        font-weight: 600;
                    }

                    h5 {
                        color: $dark-font;
                    }

                    &::after {
                        background-color: var(--theme-color);
                    }
                }
            }

            h4 {
                font-weight: 500;
                color: $dark-font;
                letter-spacing: unset;
                text-transform: none;
                line-height: 1.5;
                font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            }

            h5 {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 5px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $grey;
                margin-bottom: 0;
                font-weight: 500;
                line-height: 1.3;
            }
        }
    }
}

.video-cls {
    width: 100%;
    margin-bottom: -7px;
}

.element-page {
    .product-element {
        .card {
            box-shadow: none !important;
        }
    }

    .card {
        box-shadow: 0 0 8px #e1e1e1 !important;
    }
}

.title-padding {
    .card {
        .card-body {
            padding: 20px;
        }
    }
}
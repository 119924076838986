/**=====================
    Timer css start
==========================**/

.banner-timer {
    background-image: url("../images/offer-banner.jpg");
    background-size: cover;
    margin-left: 20px;
    margin-right: 20px;

    .banner-text {
        padding-top: 45px;
        padding-bottom: 45px;

        h2 {
            margin-bottom: 0;
            text-align: center;

            span {
                color: var(--theme-color);
            }
        }
    }

    .timer-box {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.timer {
    padding: 15px;
    background-color: rgba(236, 137, 81, 0.09);
    border: 1px dashed var(--theme-color);
    display: inline-block;

    p {
        font-size: 18px;
        color: $white;
        margin-bottom: 0;
    }

    span {
        text-align: center;
        color: $dark-font;
        width: calc(60px + (70 - 60) * ((100vw - 320px) / (1920 - 320)));
        display: inline-block;
        position: relative;
        font-size: 18px;
        font-weight: 600;

        .timer-cal {
            font-size: 12px;
            color: #777;
            text-align: center;
            font-weight: 500;
            margin-top: 7px;
        }

        .padding-l {
            display: inline;
            text-align: center;
            position: absolute;
            top: 0;
            transform: unset;
            width: auto;
            right: -1px;
        }
    }
}

.timer-section {
    display: flex;
    align-items: center;
    justify-content: center;

    .timer {
        margin-top: 0;
        background-color: var(--theme-color);

        span {
            .timer-cal {
                color: rgba(white, 0.9);
            }
        }
    }
}

.product-box-timer {
    &.timer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(white, 0.85);
        position: absolute;
        z-index: 1;
        bottom: 60px;
    }
}
/**=====================
    service css start
==========================**/

.service-block {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-right: 12px;
    }

    .media {
        padding-top: 23px;
        padding-bottom: 23px;
        align-items: center;
    }

    svg {
        margin-right: 12px;
        width: 60px;
        height: 60px;

        path {
            fill: var(--theme-color);
        }
    }

    h4 {
        font-weight: 700;
        text-transform: capitalize;
        letter-spacing: 0.03em;
    }

    p {
        text-transform: capitalize;
        margin-bottom: 0;
    }

    +.service-block {
        border-left: 1px solid $white-dark;
    }

    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }

        h4 {
            color: var(--theme-color);
            transition: all 0.3s ease;
        }
    }
}

.service-block1 {
    text-align: center;
    // background-color: #f8f8f8;
    // padding: calc(14px + (36 - 14) * ((100vw - 320px) / (1920 - 320))) 14px;
    // border: 1px solid #eee;

    +.service-block1 {
        border-left: unset;
    }

    img {
        margin-bottom: 20px;
        width: 60px;
    }

    svg {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;

        path {
            fill: var(--theme-color);
        }
    }

    h4 {
        font-weight: 600;
        margin-bottom: 7px;
        letter-spacing: unset;
        line-height: 1.3;
    }

    p {
        letter-spacing: 0.03em;
        margin-bottom: -3px;
        line-height: 1.5;
    }

    .service-svg {
        width: 70px;
        height: 70px;
        padding: 5px;
        margin: 0 auto 20px;
        background-color: #fff;
    }

    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }

        h4 {
            color: var(--theme-color);
            transition: all 0.3s ease;
        }
    }
}

.service-w-bg {
    .service {
        margin-bottom: -30px;
    }

    .service-block {
        margin-top: 0 !important;
        margin-bottom: 30px;

        p {
            line-height: 1.3;
        }

        +.service-block {
            border: none;
            margin-top: 0 !important;
        }

        .media {
            background-color: whitesmoke;
            width: 100%;
            padding: 20px;
        }
    }
}

.service-style-border {
    .service-block {
        .media {
            padding-top: 0;
            padding-bottom: 0;
        }

        svg {
            width: 80px;
            height: 50px;
        }

        .media-body {
            border-left: 1px solid $round-border;
            padding-left: 20px;
        }

        +.service-block {
            border-left: none;
        }
    }
}

.theme-bg-service {
    &.service-w-bg {
        .service-block {
            .media {
                border-radius: 0;
            }

            h4 {
                color: $white;
            }

            p {
                color: rgba($white, 0.7);
            }

            .media {
                background-color: var(--theme-color);
            }

            svg {
                path {
                    fill: $white;
                }
            }
        }
    }
}
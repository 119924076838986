/*=====================
    RTL CSS start
==========================*/

body.rtl {
    direction: rtl;
}

// general css
.rtl {
    .ms-5 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }

    .shop-sidebar-demo {
        .shop-main {
            margin-right: 320px;
            margin-left: 0;
        }

        .shop-sidebar {
            right: 0;
            left: unset;
            border-left: 1px solid $round-border;
            border-right: none;
        }
    }

    .sidebar-shop-footer {
        margin-left: 0;
        margin-right: 320px;
    }

    .shop-sidebar-demo {
        .shop-main {
            .small-slider {
                .home {
                    .slider-contain {
                        direction: ltr;
                    }
                }
            }
        }
    }

    .slick-slider {
        direction: ltr;

        .basic-product {
            direction: rtl;
        }
    }

    .slick-slide {
        float: left;
    }

    ul {
        -webkit-padding-start: 0;
    }

    .pre-2,
    .px-2 {
        padding-right: unset !important;
        padding-left: 0.5rem !important;
    }

    .me-1,
    .mx-1 {
        margin-left: 0.25rem !important;
        margin-right: unset !important;
    }

    .me-2,
    .mx-2 {
        margin-right: unset !important;
        margin-left: 0.5rem !important;
    }

    .ms-2,
    .mx-2 {
        margin-left: unset !important;
        margin-right: 0.5rem !important;
    }

    .me-3,
    .mx-3 {
        margin-right: unset !important;
        margin-left: 1rem !important;
    }

    .ms-3,
    .mx-3 {
        margin-left: unset !important;
        margin-right: 1rem !important;
    }

    .ms-auto,
    .mx-auto {
        margin-left: unset !important;
        margin-right: auto !important;
    }

    .pe-0,
    .px-0 {
        padding-right: unset !important;
        padding-left: 0 !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: unset !important;
    }

    .text-end {
        text-align: left !important;
    }

    .text-start {
        text-align: right !important;
    }

    .theme-form {
        .form-group {
            text-align: right;
        }
    }
}

.rtl {
    .form-check {
        padding-left: 0;
        padding-right: 1.5em;

        .form-check-input {
            float: right;
            margin-right: -1.5em;
            margin-left: 0;
        }
    }

    .pixelstrap {
        ul {
            a {
                text-align: right;
            }
        }

        a {
            .sub-arrow {
                right: auto;
                left: 10px;

                @media (max-width:1199px) {
                    margin-left: unset;
                    margin-right: auto;
                }
            }
        }

        .clothing-menu {
            .link-section {
                text-align: right;
            }
        }

        li {
            .lable-nav {
                left: unset;
                right: 28px;
            }
        }

        .link-section {
            h5 {
                &::before {
                    left: unset;
                    right: 0;
                }
            }
        }
    }

    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: unset;
        margin-right: calc(var(--bs-border-width) * -1);
    }

    .sm,
    .sm ul,
    .sm li {
        direction: rtl;
    }

    .onhover-dropdown {
        .onhover-show-div {
            right: unset;
            left: 0;
            text-align: right;
        }
    }

    .modal {
        .btn-close {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .form-select {
        background-position: left 0.75rem center;
        padding-right: 0.75rem;
        padding-left: 2.25rem;
    }

    .main-menu {
        .menu-right {
            .icon-nav {
                .onhover-div {
                    .show-div {
                        &.shopping-cart {
                            li {
                                .media {
                                    .media-body {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }

                .delivery-area {
                    i {
                        margin-right: 0;
                        margin-left: 5px;
                    }
                }
            }
        }

        .menu-left {
            &.category-nav-right {
                .navbar {
                    padding: 40px 45px 40px 0;
                }
            }
        }
    }

    .header-options {
        text-align: left;
    }

    .snow-slider {
        .slick-slide {
            img {
                width: 100%;
            }
        }
    }

    .effect-cls {

        &:before,
        &:after {
            right: 0;
        }
    }

    .left-header {
        .sidenav {
            .pixelstrap {
                >li {
                    >a {
                        text-align: right;
                    }
                }

                li {
                    ul {
                        li {
                            a {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .top-header {
        .header-contact {
            li {
                padding-right: 0;
                padding-left: 25px;
            }
        }
    }

    .header-compact {
        .menu-row {
            .delivery-area {
                margin-right: 0;
                margin-left: 25px;
            }
        }
    }

    .top-panel-adv {
        .delivery-area {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    .onhover-dropdown {
        &:before {
            left: 3px;
            right: unset;
        }
    }

    .top-header {
        .header-dropdown {
            >li {
                &:nth-child(2) {
                    padding: 0 25px;
                    padding-left: 0;
                }
            }

            li {
                i {
                    padding-left: 0;
                    padding-right: 5px;

                    @media (max-width: 577px) {
                        padding: 0;
                    }
                }

                &:first-child {
                    padding-right: 20px;
                    padding-left: 0;
                }
            }

            .onhover-dropdown {
                .onhover-show-div {
                    li {
                        padding-inline: 0;
                    }
                }
            }
        }

        .header-contact {
            text-align: right;

            li {
                i {
                    transform: rotateY(0deg);
                }
            }
        }
    }

    .svg-icon-menu {
        &.wo-bg {
            .pixelstrap {
                >li {
                    >a {

                        svg,
                        img {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .watch-banner {
        .timer {
            .timer {
                span {
                    .padding-l {
                        right: -1px;
                        left: unset;
                    }
                }
            }
        }
    }

    .basic-product {
        &.theme-product-2 {
            .cart-detail {
                li {
                    +li {
                        border-left: unset;
                        border-right: 1px solid #eee;
                    }

                    i {
                        margin-right: unset;
                        margin-left: 6px;
                    }
                }
            }

            .product-detail {
                .add-wish {
                    right: unset;
                    left: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }

        &.theme-product-6 {
            .cart-info {
                a {
                    +a {
                        &::after {
                            left: unset;
                            right: 0;
                        }
                    }
                }
            }
        }

        &.theme-product-8 {
            .add-round-btn {
                right: unset;
                left: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .main-menu {
        .menu-left {
            .navbar {
                padding: 40px 0 40px 45px;
            }
        }

        .menu-right {
            float: left;

            .icon-nav {
                li {
                    padding-left: 0;
                    padding-right: 20px;
                }

                .onhover-div {
                    .show-div {
                        &.setting {
                            right: unset;
                            left: 0;

                            h6 {
                                text-align: right;
                            }
                        }

                        &.shopping-cart {
                            right: unset;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .pixelstrap {

        // .clothing-menu,
        .full-mega-menu {
            // padding: 0 18px 0 30px !important;

            ul {
                a {
                    &:before {
                        right: 0;
                    }
                }
            }
        }
    }

    .cart_qty_cls {
        right: unset;
        left: -8px;
    }

    .timer {
        span {
            .padding-l {
                right: unset;
                left: -1px;
            }
        }
    }

    // .header-style-1 {
    //     .cart_qty_cls {
    //         left: -14px;
    //         right: unset;
    //     }
    // }

    header {
        .main-navbar {
            .nav-menu {
                >li {
                    float: right;

                    >a {
                        padding-right: 0;
                        padding-left: 45px;

                        .sub-arrow {
                            right: auto;
                            left: 7px;
                        }
                    }

                    .mega-menu-container {
                        .mega-box {
                            text-align: right;

                            .link-section {
                                .menu-content {
                                    ul {
                                        li {
                                            a {
                                                &:before {
                                                    right: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .nav-submenu {
                        text-align: right;

                        li {
                            a {
                                i {
                                    float: left;
                                }
                            }

                            .nav-sub-childmenu {
                                left: -203px;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }

        .sidenav {
            .sidebar-menu {
                li {
                    direction: rtl;
                    text-align: right;
                }
            }
        }

        &.left-header {
            .sidenav {
                right: 0;

                .pixelstrap {
                    li {
                        .mega-menu-container {
                            right: 0px;
                            margin-right: 270px;
                            left: unset;
                            margin-left: unset;
                        }

                        .nav-submenu {
                            right: 0px;
                            margin-right: 270px;
                            margin-left: 0;
                            left: unset;

                            li {
                                .nav-sub-childmenu {
                                    left: unset;
                                    margin-left: 0;
                                    right: 0px;
                                    margin-right: 228px;
                                }

                                a {
                                    i {
                                        float: left;
                                    }
                                }
                            }
                        }
                    }
                }

                .left-sidebar_center {
                    padding: 40px 30px 30px 0;

                    .pixelstrap {
                        li {
                            a {
                                .sub-arrow {
                                    left: 7px;
                                    right: unset;
                                }
                            }
                        }
                    }
                }
            }

            &.left-header-sm {
                .sidenav {
                    .left-sidebar_center {
                        padding: 0;

                        .pixelstrap {
                            >li {
                                >a {
                                    img {
                                        margin-left: auto !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.left-header-relative {
                .main-menu {
                    justify-content: flex-start;
                }

                .sidenav {
                    .left-sidebar_center {
                        padding: 0;
                    }
                }
            }
        }

        .layout3-menu {
            .main-navbar {
                .nav-menu {
                    >li {
                        >a {
                            padding-left: 34px;

                            .sub-arrow {
                                left: -5px;
                            }
                        }
                    }
                }
            }
        }

        &.header-christmas {
            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 20px 0 20px 45px;
                    }
                }
            }
        }

        &.header-tools {
            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 25px 0 25px 45px;
                    }
                }
            }
        }
    }

    .header-style-5 {
        .bottom-part {
            .category-menu {
                .toggle-sidebar {
                    i {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .header-compact {
        .delivery-area {
            svg {
                margin-left: 10px;
                margin-right: 0;
            }

            div {
                h6 {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }

        .top-header {
            .header-dropdown {
                li {
                    &:nth-child(2) {
                        padding: 10px 25px;
                    }

                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .addcart_btm_popup {
        left: 0;
        right: unset;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .fixed_cart {
            padding-right: 8px;
            padding-left: 0;
        }
    }

    .dark-light {
        right: unset;
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .blog-left {
        .blog-details {
            text-align: right;
        }
    }

    .sidenav {
        right: -300px;
        left: unset;

        &.open-side {
            right: 0;
        }

        &.marketplace-sidebar {
            right: unset;
        }
    }

    .bg-title {
        .theme-tab {
            .bg-title-part {
                .tab-title {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
        }
    }

    .product-left-title {
        .slick-custom-arrow {
            li {
                &.left-arrow {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }
        }
    }

    .setting-box {
        right: unset;
        left: -660px;

        &.open-setting {
            right: unset;
            left: -4px;
        }
    }

    .full-slider {
        direction: ltr;
    }

    .full-banner {
        .santa-img {
            left: unset;
            right: 28px;
        }

        &.feature-banner {

            .feature-object,
            .feature-object-right {
                text-align: left;

                li {
                    margin-right: 0;
                    margin-left: 50px;

                    .media {
                        img {
                            margin-left: 0;
                            margin-right: 15px;
                        }

                        .media-body {
                            text-align: left;
                        }
                    }

                    &:nth-child(2) {
                        padding-right: 0;
                        padding-left: 30px;
                    }

                    &:nth-child(3) {
                        padding-right: 0;
                        padding-left: 60px;
                    }
                }
            }

            .feature-object-right {
                text-align: right;
                margin-left: 0;
                margin-right: 50px;

                li {
                    .media {
                        img {
                            margin-left: 15px;
                            margin-right: 0;
                        }

                        .media-body {
                            text-align: right;
                        }
                    }

                    &:nth-child(2) {
                        padding-right: 30px;
                        padding-left: 0;
                    }

                    &:nth-child(3) {
                        padding-right: 60px;
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .blog-section {
        .review-box {
            .santa-img {
                transform: scaleX(-1);
                left: -116px;
                right: unset;
            }
        }
    }

    .home-slider {
        direction: ltr;
    }

    .beauty-about {
        text-align: right;

        .about-text {
            p {
                text-align: right;
            }
        }
    }

    .form_search {
        button {
            left: 5px;
            right: unset;
        }
    }

    .cart-qty-cls {
        right: unset;
        left: -8px;
    }

    .search-overlay {
        >div {
            .overlay-content {
                button {
                    left: 10px;
                    right: unset;
                }
            }

            .closebtn {
                right: unset;
                left: 25px;
            }
        }
    }

    .collection-banner {
        .contain-banner {
            justify-content: flex-end;
        }

        &.p-right {
            .contain-banner {
                justify-content: flex-start;
            }
        }

        &.p-left {
            .contain-banner {
                justify-content: flex-end;
            }
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        .btn-close {
                            right: unset;
                            left: 17px;
                        }
                    }
                }
            }
        }
    }

    .cart_counter {
        .cart_checkout {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    .product-box,
    .product-wrap {

        .product-detail,
        .product-info {
            text-align: right;
            padding-left: 0;
            padding-right: 5px;
        }

        .img-wrapper {
            .product-thumb-list {
                right: 0;
                left: unset;
            }
        }

        .cart-info,
        .cart-wrap {
            &.cart-wrap {
                text-align: left;
                left: 0;
                right: unset;
            }
        }
    }

    .product-style-4 {
        &.product-box {
            .product-info {
                text-align: right;
            }

            .addtocart_btn {
                .add_cart {
                    right: unset;
                    left: 0;
                }
            }
        }
    }

    .product-style-5 {
        &.product-box {
            text-align: right;

            .img-wrapper {
                margin-left: auto;
                margin-right: unset;
            }

            .addtocart_btn {
                .cart_qty {
                    &.qty-box {
                        .input-group {
                            margin-right: auto;
                            margin-left: 12px;

                            button {
                                border-radius: 10px !important;

                                &.quantity-right-plus {
                                    left: 0;
                                    right: unset;
                                }

                                &.quantity-left-minus {
                                    right: 0;
                                    left: unset;
                                }
                            }
                        }
                    }
                }

                .add_cart {
                    left: 15px;
                    right: unset;
                }
            }
        }
    }

    .card {
        .card-body {
            .notification-list {
                li {
                    padding-left: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
                    padding-right: calc(33px + (46 - 33) * ((100vw - 320px) / (1920 - 320)));

                    &::after {
                        left: unset;
                        right: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }
        }
    }

    .product-box,
    .product-wrap {
        .product-info {
            text-align: center;
        }

        .cart-detail {
            right: unset;
            left: 20px;
        }

        .back {
            right: 0;
            left: unset;
            transform: translateX(100px);
        }
    }

    .j-box {
        .product-box {
            .img-wrapper {
                .cart-info {
                    width: 100%;
                }
            }
        }

        &.style-box {
            .product-box {
                .cart-info {
                    a {
                        border: none;
                    }
                }
            }
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .cart-wrap {
                    left: 7px;
                }
            }
        }
    }

    .tools-grey {
        .product-box {
            .img-wrapper {
                .cart-info {
                    width: 100%;
                }
            }
        }
    }

    .tools-parallax-product {
        .tools-description {
            text-align: right;
        }
    }

    .service-block1 {
        +.service-block1 {
            border-left: 1px solid #eee;
            border-right: unset;
        }
    }

    .service-block {
        +.service-block {
            border-right: 1px solid $round-border;
            border-left: none;
        }

        svg {
            margin-right: 0;
            margin-left: 12px;
        }

        .media {
            .media-body {
                text-align: right;
            }
        }
    }

    .footer-light {
        .subscribe {
            border-right: none;
            border-left: 1px solid $round-border;
            text-align: right;
        }
    }

    .footer-theme {
        text-align: right;

        .sub-title {
            .contact-list {
                li {
                    padding-left: 0;
                    padding-right: 25px;
                }

                i {
                    left: unset;
                    right: 0;
                }
            }
        }
    }

    .footer-social,
    .social-white {
        li {
            padding-left: 45px;
            padding-right: 0;
        }
    }

    .social-white {
        li {
            padding-left: 22px;
            padding-right: 22px;
        }
    }

    .sub-footer {
        text-align: right;
    }

    .product-right {
        .product-buttons {

            a,
            button {
                &:last-child {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }
    }

    .theme-modal {
        &.modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        .close {
                            left: 36px;
                            right: unset;
                        }
                    }
                }
            }
        }
    }

    .theme-card {
        .slick-next {
            right: unset;
            left: 25px;
        }

        .slick-prev {
            left: 0;
            right: unset;
        }

        h5 {
            &.title-border {
                text-align: right;
            }
        }

        .offer-slider {
            .media {
                direction: rtl;

                // img {
                //     padding: 15px 30px 15px 15px;
                // }

                .media-body {
                    text-align: right;

                    a {
                        h6 {
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }
                }
            }

            img {
                padding: 15px 0 15px 15px;
            }
        }

        &.card-border {
            .offer-slider {
                img {
                    padding: 15px 30px 15px 15px;
                }
            }

            h5 {
                padding-right: 30px;
                padding-left: 0;
            }

            .slick-prev {
                left: 30px;
            }

            .slick-next {
                left: 50px;
            }
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    text-align: right;

                    .product-box2 {
                        .media {
                            .media-body {
                                a {
                                    h6 {
                                        margin-left: 35px;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .full-box {
        .theme-card {
            .offer-slider {
                .product-box2 {
                    .media {
                        .media-body {
                            a {
                                h6 {
                                    margin-right: 0;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .game-product {
        .product-box {
            .cart-info {
                left: 10px;
            }

            .product-detail {
                text-align: center;
            }
        }
    }

    .gym-product {
        .product-box {
            .img-wrapper {
                .cart-info {
                    width: 100%;
                }
            }

            .product-detail {
                text-align: center;
            }
        }
    }

    .gym-blog {
        .blog-details {
            text-align: right;
        }
    }

    .pets-box {
        .product-box {
            .product-detail {
                text-align: center;
            }
        }
    }

    .blog {
        &.blog_box {
            .blog-details {
                text-align: right;
            }
        }
    }

    .tab-left {
        .theme-tab {
            .left-side {
                text-align: right;

                .tab-title {
                    margin-right: 0;
                    margin-left: 100px;
                    text-align: right;
                }
            }
        }
    }

    .left-sidebar_space {
        padding-left: 0;
        padding-right: 300px;
    }

    .left-header {
        .top-header {
            padding-left: 0;
            padding-right: 300px;
        }
    }

    .absolute-product {
        .product-box {
            .product-detail {
                text-align: center;
            }
        }
    }

    .box-product {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            padding-right: 0;
                            padding-left: 15px;

                            img {
                                padding: 0 0 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .layout3-menu {
        .main-menu {
            .menu-left {
                .main-menu-right {
                    .toggle-nav {
                        left: unset;
                        right: 35px;
                    }
                }
            }
        }
    }

    .breadcrumb-item {
        +.breadcrumb-item {
            padding-left: 0;
            padding-right: 0.5rem;

            &:before {
                padding-left: 0.5rem;
                padding-right: 0;
                float: right;
            }
        }
    }

    .custom-control {
        padding-left: 0;
        padding-right: 1.5rem;
        text-align: right;
    }

    .custom-control-input {
        right: 0;
        left: unset;
    }

    .custom-control-label {
        &:after {
            left: unset;
            right: -1.5rem;
        }

        &:before {
            right: -1.5rem;
            left: unset;
        }
    }

    .collection-collapse-block {
        .collapse-block-title {
            text-align: right;

            &:after {
                right: unset;
                left: -3px;
            }
        }

        .collection-collapse-block-content {
            .color-selector {
                ul {
                    text-align: right;
                }
            }

            .collection-brand-filter {
                .collection-filter-checkbox {
                    label {
                        padding-left: 0;
                        padding-right: 10px;
                    }
                }
            }
        }

        .collection-accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        &::after {
                            margin-left: unset;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }

    .top-banner-wrapper {
        .top-banner-content {
            text-align: right;
        }
    }

    .banner-timer {
        direction: ltr;

        .banner-text {
            .text-start {
                text-align: left !important;
            }
        }
    }

    .product-filter-tags {
        text-align: right;

        li {
            a {
                i {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-top-filter {

            .product-filter-content,
            .popup-filter {

                .search-count,
                .sidebar-popup {
                    // border-right: unset;
                    // border-left: 1px solid $round-border;
                    text-align: right;
                }

                .collection-view {
                    ul {
                        li {
                            &:first-child {
                                margin-right: 0;
                                margin-left: 14px;
                            }
                        }
                    }
                }

                // .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
                // .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view

                // .product-filter-content {
                .collection-grid-view {
                    margin-left: unset;
                    margin-right: auto;
                }

                // }

                .product-page-per-view,
                .product-page-filter {
                    select {
                        background-position: 5%;
                        border-right: none;
                        border-left: 1px solid $round-border;
                    }
                }

                .product-page-filter {
                    select {
                        border-left: none;
                    }
                }
            }

            .popup-filter {
                .sidebar-popup {
                    background: url(../images/dropdown.png) no-repeat 5%;
                }

                .open-popup {
                    left: unset;
                    right: 0;
                }
            }
        }
    }

    .product-pagination {
        .product-search-count-bottom {
            padding-left: 15px;
            padding-right: 0;
        }
    }

    .details-sidebar-accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    &::after {
                        margin-left: unset;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    .collection-filter-block {
        .product-service {
            .media {
                .media-body {
                    padding-right: 10px;
                    padding-left: 0;
                    text-align: right;
                }
            }
        }
    }

    .product-service {
        padding: 20px;

        .media {
            .media-body {
                padding-left: unset;
                padding-right: 10px;
            }
        }
    }

    .product-right {
        text-align: right;

        h4 {
            span {
                padding-left: 0;
                padding-right: 5px;
            }
        }

        .product-description {
            h6 {
                span {
                    float: left;
                }
            }
        }

        .size-box {
            ul {
                li {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }

        .product-buttons {
            a {
                &:last-child {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }

        .product-icon {
            .product-social {
                li {
                    padding-right: 0;
                    padding-left: 30px;

                    &:last-child {
                        padding-left: 0;
                    }
                }
            }

            .wishlist-btn {
                span {
                    padding-left: 0;
                    padding-right: 10px;
                }

                i {
                    padding-left: 0;
                    margin-left: 0;
                    padding-right: 10px;
                    margin-right: 5px;
                    border-left: none;
                    border-right: 1px solid $round-border;
                }
            }
        }

        &.product-form-box {
            text-align: center;

            .timer {
                p {
                    text-align: right;
                }
            }

            .product-description {
                text-align: center;
            }
        }

        .product-count {
            img {
                margin-right: 0;
                margin-left: 6px;
            }

            ul {
                li {
                    &:first-child {
                        margin-right: 0;
                        margin-left: 14px;
                    }
                }
            }
        }

        .rating-section {
            h6 {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        .label-section {
            .label-text {
                padding-left: 0;
                padding-right: 5px;
            }
        }
    }

    .recently-purchase {
        text-align: right;

        .close-popup {
            left: 7px;
            right: unset;
        }
    }

    .sticky-bottom-cart {
        .product-image {
            .content {
                margin-left: 0;
                margin-right: 12px;

                h6 {
                    del {
                        margin-left: 0;
                        margin-right: 7px;
                    }

                    span {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
        }

        .selection-section {
            .form-group {
                &:nth-child(2) {
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }
    }

    .add_to_cart {
        .cart-inner {
            .cart_media {
                li {
                    .buttons {
                        .checkout {
                            float: left;
                        }
                    }

                    .media {
                        .media-body {
                            text-align: right;
                        }
                    }
                }

                .close-circle {
                    left: 0;
                    right: unset;
                }
            }

            .cart_top {
                .close-cart {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
        }

        &.top,
        &.bottom {
            .cart_media {
                .cart_product {
                    li {
                        margin-left: 18px;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // .timer {
    //     padding-left: 0;
    //     padding-right: 40px;
    //     text-align: right;

    //     span {
    //         .padding-l {
    //             padding-left: 0;
    //             padding-right: 22px;
    //         }
    //     }
    // }

    .tab-product,
    .product-full-tab {
        .tab-content {
            &.nav-material {
                p {
                    text-align: right;
                }
            }
        }
    }

    .product-related {
        h2 {
            text-align: right;
        }
    }

    .border-product {
        text-align: right;

        .bundle {
            .bundle-image-box {
                .bundle-box {
                    .form-check {
                        right: unset;
                        left: 12px;
                    }
                }
            }
        }
    }

    .theme_checkbox {
        label {
            padding-left: 0;
            padding-right: 30px;
        }

        .checkmark {
            left: unset;
            right: 0;
        }
    }

    .element-detail {
        text-align: right;
    }

    .dashboard-section {
        .radio_animated {
            margin: 0 0 0 1rem;
        }

        .dashboard-box {
            .dashboard-title {
                span {
                    margin-left: 0;
                    margin-right: 30px;
                }
            }
        }

        .top-sec {
            .btn {
                margin-left: unset;
                margin-right: auto;
            }
        }

        .counter-section {
            .box-account {
                .box {
                    .box-content {
                        padding-left: unset;
                        padding-right: 22px;
                    }
                }
            }

            .counter-box {
                text-align: right;
                border-left: none;
                border-right: 3px solid var(--theme-color);

                img {
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
        }

        .faq-content {
            .card {
                text-align: right;
            }
        }

        .dashboard-sidebar {
            .faq-tab {
                .nav-tabs {
                    text-align: right;
                }
            }
        }
    }

    .offer-box {
        display: none;
    }

    .theme-modal-2 {
        .modal-body {
            .form-box {
                .form-select {
                    padding-right: calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
                    padding-left: 2.25rem;
                }
            }
        }
    }

    .user-dashboard-section {
        .address-book-section {
            .select-box {
                .address-box {
                    .top {
                        h6 {
                            span {
                                float: left;
                            }
                        }
                    }
                }
            }
        }
    }

    .faq-tab {
        .nav-tabs {
            .nav-item {
                .nav-link {

                    // &.active,
                    // &:hover {
                    //     border-right: none;
                    //     border-left: 2px solid var(--theme-color);
                    // }
                }
            }
        }
    }

    .modal-header {
        .close {
            margin: -1rem auto -1rem -1rem;
        }
    }

    .modal-dialog {
        .modal-content {
            .modal-body {
                text-align: right;
            }
        }
    }

    .vendor-profile {
        .profile-left {

            .profile-detail,
            .vendor-contact {
                border-left: none;
                padding-left: 0;
                margin-left: 0;
                border-right: 1px solid $grey3;
                padding-right: 20px;
                margin-right: 20px;
            }

            .profile-detail {
                text-align: right;
            }

            .profile-image {
                img {
                    margin: 0 auto;
                }
            }
        }
    }

    .about-page {
        text-align: right;
    }

    .become-vendor {
        text-align: right;
    }

    .start-selling {
        text-align: right;
    }

    .wishlist-section,
    .cart-section {
        tbody {
            tr {
                td {
                    text-align: center;
                }
            }
        }
    }

    .cart-section,
    .wishlist-section {
        .wishlist-buttons {
            text-align: left;
        }

        tfoot {
            tr {
                td {
                    text-align: left;
                    padding-right: 0;
                    padding-left: 63px;

                    &:first-child {
                        padding-right: 0;
                        padding-left: 10px;
                    }
                }
            }
        }

        .cart-buttons {
            >div {
                &:first-child {
                    text-align: right;
                }

                &:nth-child(2) {
                    text-align: left;
                }
            }
        }
    }

    .dashboard-right {
        text-align: right;
    }

    .dashboard {
        .box {
            .box-title {
                >a {
                    right: unset;
                    left: 0;
                }
            }
        }
    }

    .dashboard-left {
        .block-content {
            ul {
                li {
                    justify-content: end;
                }
            }
        }
    }

    .review-people {
        padding-right: 23px;
        padding-left: unset;
        border-left: unset;
        border-right: 1px solid #eee;
    }

    .login-page {
        text-align: right;
    }

    .register-page {
        text-align: right;
    }

    .contact-page {
        .theme-form {
            text-align: right;
        }

        .contact-right {
            text-align: right;

            ul {
                li {
                    padding-left: 0;
                    padding-right: 150px;

                    .contact-icon {
                        right: 0;
                        left: unset;
                        border-right: none;
                        border-left: 1px solid $round-border;
                    }
                }
            }
        }
    }

    .checkout-page {
        text-align: right;
    }

    .order-box {
        .title-box {
            span {
                float: left;
            }
        }

        .qty {
            li {
                span {
                    float: left;
                }
            }
        }

        .sub-total {
            .shipping {
                float: left;
            }

            li {
                &:last-child {
                    .count {
                        text-align: left;
                    }
                }

                .check-it {
                    margin-left: unset;
                    margin-right: 14px;
                }

                .count {
                    float: left;
                    text-align: left;
                }
            }

            .shopping-option {
                label {
                    padding-right: 10px;
                    padding-left: 0;
                }
            }
        }

        .total {
            li {
                span {
                    float: left;
                    text-align: left;
                }
            }
        }
    }

    .payment-box {
        .payment-options {
            li {
                .radio-option {
                    input[type="radio"] {
                        left: unset;
                        right: 0;
                    }

                    label {
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .about-page {
        text-align: right;
    }

    .testimonial {
        .testimonial-slider {
            .slick-track {
                .slick-slide {
                    &:nth-child(even) {
                        .media {
                            padding-left: 0;
                            padding-right: 50px;

                            .media {
                                padding-right: 0;
                            }
                        }
                    }

                    &:nth-child(odd) {
                        .media {
                            padding-right: 50px;

                            .media {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            .media {
                direction: rtl;

                .media-body {
                    padding: 30px 30px 30px 75px;
                    text-align: right;
                }
            }
        }
    }

    .typography_section {
        text-align: right;

        .typography-box {
            .typo-content {
                &.input_button {
                    label {
                        padding-left: 0;
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    .review-page {
        .comment-section {
            .media {
                text-align: right;
            }
        }

        .comnt-sec {
            li {
                margin-right: 0;
                margin-left: 15px;
            }
        }
    }

    .comnt-sec {
        li {
            a {
                i {
                    margin-right: 0;
                    margin-left: 5px;
                }
            }
        }
    }

    .modern-box {
        .modern-content {
            .modern-bottom {
                .right {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        .absolute-img {
            img {
                margin-left: unset;
                margin-right: 30px;
            }
        }
    }

    footer {
        .subscribe-form {
            &.rounded-input {
                .form-control {
                    border-radius: 0 25px 25px 0;
                }

                .btn-solid {
                    border-radius: 25px 0 0 25px;
                }
            }
        }
    }

    .subscribe-form {
        &.classic-form {
            .form-control {
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }

    .blog-detail-page {
        .comment-section {
            li {
                img {
                    margin-right: 0;
                    margin-left: 20px;
                }

                h6 {
                    span {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
            }
        }

        .blog-detail-contain {
            p {
                color: #ddd;
            }
        }
    }

    .product-order {
        text-align: right;

        .product-order-table {
            tfoot {
                tr {
                    td {
                        text-align: right;

                        &:nth-child(2) {
                            text-align: left;
                        }
                    }
                }
            }
        }

        .total-sec {
            ul {
                li {
                    span {
                        float: left;
                    }
                }
            }
        }

        .final-total {
            h3 {
                span {
                    float: left;
                }
            }
        }
    }

    .order-success-sec {
        text-align: right;
    }

    .compare-section {
        .compare-part {
            text-align: right;

            .close-btn {
                right: unset;
                left: 0;
            }
        }
    }

    .sitemap_page {
        text-align: right;

        ul {
            padding-left: unset;
            padding-right: 10px;
        }
    }

    .faq-section {
        text-align: right;

        .theme-accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        &::after {
                            margin-left: unset;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }

    .blog-sidebar {
        text-align: right;

        .theme-card {
            .recent-blog {
                li {
                    .media {
                        img {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .blog-page {
        .blog-media {
            .blog-right {
                text-align: right;

                ul {
                    li {
                        +li {
                            padding-left: 0;
                            margin-left: 0;
                            border-left: none;
                            padding-right: 15px;
                            margin-right: 15px;
                            border-right: 1px solid $round-border;
                        }
                    }
                }
            }
        }

        .blog-sidebar {
            .theme-card {
                .popular-blog {
                    li {
                        .blog-date {
                            margin: 0 0 0 15px;
                        }
                    }
                }
            }
        }

        .blog-box {
            .blog-contain {
                .blog-label {
                    >span {
                        +span {
                            margin-left: unset;
                            padding-left: unset;
                            border-left: unset;
                            margin-right: 10px;
                            padding-right: 10px;
                            border-right: 1px solid #eee;
                        }
                    }
                }
            }
        }
    }

    .blog-detail-page {
        text-align: right;

        .blog-detail {
            .post-social {
                text-align: right;

                li {
                    +li {
                        padding-left: 0;
                        margin-left: 0;
                        border-left: none;
                        padding-right: 15px;
                        margin-right: 15px;
                        border-right: 1px solid $round-border;
                    }
                }
            }
        }
    }

    .product-christmas {
        .product-box {
            .cart-wrap {
                width: 100%;
            }
        }
    }

    .main-menu {
        .menu-right {
            .icon-nav {
                .onhover-div {
                    .show-div {
                        &.shopping-cart {
                            li {
                                .close-circle {
                                    left: 0;
                                    right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    footer {
        &.footer-black {
            &.footer-light {
                .subscribe {
                    border-right: none;
                    border-left: 1px solid rgba(0, 0, 0, 0.8);
                }
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-detail {
                    padding-right: 15px;
                    padding-left: 0;
                    text-align: right !important;
                }
            }
        }
    }

    .dashboard-section {
        .apexcharts-svg {
            direction: ltr;
        }
    }

    .theme-settings {
        left: 0;
        right: unset;

        ul {
            li {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-right: auto;
                margin-left: 0;
            }
        }
    }

    .search-section {
        select {
            &.form-control {
                background: url(../images/dropdown.png) no-repeat 5%;
            }
        }
    }

    .cycle-box {
        direction: rtl;

        .product-detail {
            .add-wish {
                right: unset;
                left: 22px;
            }

            .details {
                li {
                    +li {
                        margin-left: 0;
                        margin-right: 12px;
                    }
                }
            }
        }

        .cart-detail {
            li {
                svg {
                    margin-right: 0;
                    margin-left: 6px;
                }

                +li {
                    border-left: none;
                    border-right: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    .filter-bottom-title {
        &:after {
            right: unset;
            left: -3px;
        }
    }

    .pixelstrap {
        .link-section {
            color: $white;
        }
    }

    .color-w-name {
        ul {
            li {
                span {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }

    .checkout-page {
        .checkout-form {
            select {
                background: url(../images/dropdown.png) no-repeat 5%;
            }
        }
    }

    &.christmas {
        overflow-x: hidden !important;
    }

    .compare-page {
        .table-wrapper {
            .table {
                thead {
                    .th-compare {
                        th {
                            .remove-compare {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .sale-box {
        right: -2px;
        left: unset;

        .heading-right {
            h3 {
                left: -250px;
                right: unset;
                transform: translate(50%, -50%) rotate(90deg);
            }
        }
    }

    &.modal-open {
        overflow-x: hidden !important;
    }

    .blackfriday-modal {
        .btn-close {
            left: 20px;
            right: unset;
        }
    }

    &.dark {
        .service-block {
            +.service-block {
                border-color: $dark-border;
            }
        }
    }

    .cookie-bar {
        .btn {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    .product-box,
    .product-wrap {
        .img-wrapper {
            .lable-block {
                .lable3 {
                    right: 7px;
                    left: unset;
                }

                .lable4 {
                    left: 7px;
                    right: unset;
                }
            }
        }
    }

    .j-box {
        .product-box {
            direction: rtl;

            .cart-info {
                a {
                    border-right: 1px solid $round-border;
                    border-left: unset;
                }
            }
        }
    }

    .service-w-bg {
        .service-block {
            +.service-block {
                border: none;
            }
        }
    }

    .service-style-border {
        .service-block {
            .media-body {
                border-right: 1px solid $round-border;
                padding-right: 20px;
                border-left: none;
                padding-left: 0;
            }

            +.service-block {
                border-right: none;
            }
        }
    }

    .ajax-search {
        .typeahead {
            text-align: right;
        }
    }

    .product-vertical {
        .theme-card {
            &.center-align {
                .offer-slider {
                    .product-box2 {
                        .media {
                            .media-body {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-style-1 {
        .footer-social {
            li {
                padding-left: 15px;
            }
        }
    }

    .title-basic {
        .timer {
            margin-right: 15px;
            margin-left: 0;
        }
    }

    .tracking-page {
        .wrapper {
            margin-left: 0;
            margin-right: -5px;

            .arrow-steps {
                .step {
                    padding: 12px 30px 12px 10px;
                    float: right;

                    span {
                        &:before {
                            left: unset;
                            right: -20px;
                        }
                    }

                    &:before,
                    &:after {
                        right: unset;
                        left: -17px;
                        border-left: 0;
                        border-right: 17px solid $top-header;
                    }

                    &:before {
                        right: 0;
                        left: auto;
                        border-left: 0;
                        border-right: 17px solid $white;
                    }

                    &.current {
                        &:after {
                            border-left: 0;
                            border-right: 17px solid var(--theme-color);
                        }
                    }
                }
            }
        }
    }

    .theme-tab {

        .tab-title,
        .tab-title2 {
            &.border-title {
                li {
                    border-left: 0;
                    border-right: 1px solid #bbbbbb;

                    &:first-child {
                        border-right: 0;
                    }
                }
            }
        }
    }

    .product-left-title {
        &.right-content {
            .slick-custom-arrow {
                justify-content: flex-start;
            }
        }
    }

    .added-notification {
        right: unset;
        left: 30px;
    }

    .image-360 {
        right: unset;
        left: 20px;
    }

    .deal-section .sidenav nav>.pixelstrap>li>.has-submenu .sub-arrow,
    .deal-section .sidenav nav>.pixelstrap>li>a .sub-arrow {
        margin-left: unset;
        margin-right: auto;
    }

    .tab-border {
        .nav-border {
            border-left: 1px solid $round-border;
            border-right: none;
        }
    }

    .search-block {
        .form-header {
            .input-group {
                input {
                    border-color: #404040;
                }
            }
        }
    }

    .space_sm {
        padding-left: 0;
        padding-right: 240px;
    }

    .lable-gradient {
        right: 7px;
        left: unset;
    }

    .detail-inline {
        h4 {
            margin-left: unset;
            margin-right: auto;
        }
    }

    .gradient-slider {
        .product-box {
            direction: rtl;
        }
    }

    .variation-modal {
        .btn-close {
            right: unset;
            left: 10px;
            margin: 0;
        }
    }

    .quick-view-modal {
        .modal-body {
            .right-sidebar-modal {

                .price-text {
                    h3 {
                        .discounted-price {
                            margin-left: unset;
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
    }

    .offcanvas {
        &.cart-offcanvas {
            right: unset;
            left: 0;
            transform: translateX(-100%);

            &.show:not(.hiding),
            &.showing {
                transform: none;
            }

            .offcanvas-header {
                .btn-close {
                    margin-left: unset;
                    margin-right: auto;
                }
            }

            .offcanvas-body {
                .sidebar-title {
                    text-align: left;
                }

                .cart-product {
                    .media {
                        .media-body {

                            .close-circle,
                            .qty-box {
                                right: unset;
                                left: 0;
                            }
                        }
                    }
                }

                .pre-text-box {
                    .progress {
                        .progress-bar {
                            i {
                                right: unset;
                                left: -16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .alert {
        .btn-close {
            margin-left: unset;
            margin-right: auto;
        }
    }

    .loader_skeleton {
        .theme-card {
            .product-box {
                .img-wrapper {
                    margin-right: unset;
                    margin-left: 15px;
                }
            }
        }

        .blog-page {
            .order-sec {
                .product-box {
                    .img-wrapper {
                        margin-right: unset;
                        margin-left: 25px;
                    }
                }
            }
        }
    }
}

// admin rtl
.custom-theme {
    position: fixed;
    right: 0;
    width: 40px;
    height: 38px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $grey-about;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    top: calc(50% + 34px);
    cursor: pointer;
}

body {
    &.rtl {
        .m-r-15 {
            margin-left: 15px;
            margin-right: unset;
        }

        .me-2,
        .mx-2 {
            margin-left: 0.5rem !important;
            margin-right: unset !important;
        }

        .custom-select {
            padding: 0.375rem 0.75rem 0.375rem 1.75rem;
        }

        .ps-0,
        .px-0 {
            padding-right: 0 !important;
            padding-left: unset !important;
        }

        .m-r-10 {
            margin-left: 10px;
            margin-right: unset;
        }

        .radio_animated,
        .checkbox_animated {
            margin: 0 0 0 1rem;
        }

        .digital-add {
            .form-group {
                .radio_animated {
                    margin-left: 8px;
                    margin-right: unset;
                }
            }
        }

        .needs-validation {
            .form-group {
                text-align: right;

                .checkbox {
                    padding-right: 0;
                    padding-left: unset;
                }
            }

            .permission-block {
                .attribute-blocks {
                    padding-right: 15px;
                    padding-left: unset;
                    text-align: right;

                    .row {
                        padding-right: 20px;
                        padding-left: unset;
                    }
                }
            }

            .checkbox {
                right: 0;
                margin-left: -16px;

                label {
                    padding-right: 16px;
                    padding-left: unset;

                    &::before {
                        right: 0;
                        left: unset;
                        margin-left: unset;
                        margin-right: -16px;
                    }
                }
            }

            .radio-animated {
                label {
                    margin-left: 20px;
                    margin-right: unset;
                }
            }
        }

        .modal-footer {
            > :not(:last-child) {
                margin-left: 0.25rem;
                margin-right: unset;
            }
        }

        .jsgrid-button {
            +.jsgrid-button {
                margin-right: 5px;
                margin-left: unset;
            }
        }

        .custom-theme {
            left: 0;
            right: unset;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .text-right {
            text-align: left !important;
        }

        .br-wrapper {
            text-align: right;
        }

        .card {
            .card-header {
                .card-header-right {
                    left: 20px;
                    right: unset;

                    .card-option {
                        text-align: left;

                        &.list-unstyled {
                            padding-right: 0;
                            padding-left: unset;
                        }
                    }
                }
            }
        }

        code[class*="language-"],
        pre[class*="language-"] {
            text-align: right;
        }

        .code-box-copy__btn {
            right: unset;
            left: 11px;
        }

        table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        text-align: right;
                    }
                }
            }
        }

        .pull-right {
            float: left;
        }

        .status-circle {
            right: 40px;
            left: unset;
        }

        .right-sidebar {
            &.show {
                left: 0;
                right: unset;
            }

            .friend-list-search {
                .fa {
                    left: 35px;
                    right: unset;
                }
            }

            .chat-box {
                .about {
                    float: right;
                    padding-right: 10px;
                    padding-left: unset;
                    text-align: right;
                }

                .friend-list {
                    text-align: right;
                }

                .user-image {
                    float: right;
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }

        .nav-menus {
            .search-form {
                input {
                    padding: 10px 70px 10px 10px;
                }
            }
        }

        ul {
            &.notification-dropdown {
                &.onhover-show-div {
                    li {
                        text-align: right;
                    }
                }
            }
        }

        // sidebar main page
        .page-wrapper {
            .page-body-wrapper {
                .page-sidebar {
                    .sidebar-user {

                        h6,
                        p {
                            text-align: center;
                        }
                    }

                    .sidebar-menu {
                        li {
                            text-align: right;

                            &.active {
                                >.sidebar-submenu {
                                    margin-right: 15px;
                                    margin-left: unset;
                                }
                            }
                        }

                        .sidebar-submenu {
                            >li {
                                >a {
                                    padding-right: 10px;
                                    padding-left: unset;

                                    >.fa-circle {
                                        right: 0;
                                        left: unset;
                                    }
                                }
                            }
                        }

                        .sidebar-header {
                            svg {
                                margin-right: unset;
                                margin-left: 14px;
                            }
                        }
                    }

                    ~.page-body {
                        margin-right: 255px;
                        margin-left: unset;
                    }

                    &.open {
                        margin-right: calc(-260px);
                        margin-left: unset;

                        ~.page-body,
                        ~footer {
                            margin-right: 0;
                        }
                    }
                }

                .page-header {
                    .row {
                        h3 {
                            text-align: right;
                        }
                    }
                }

                footer {
                    margin-right: 255px;
                    margin-left: unset;

                    p {
                        i {
                            margin-right: 5px;
                            margin-left: unset;
                        }
                    }
                }
            }
        }

        // page main header
        .page-main-header {
            margin-right: 255px;
            margin-left: unset;

            .main-header-right {
                .nav-right {
                    text-align: left;

                    .profile-dropdown {
                        li {
                            svg {
                                margin-right: unset;
                                margin-left: 10px;
                            }
                        }
                    }

                    >ul {
                        >li {
                            text-align: right;

                            &:before {
                                right: 0;
                                left: unset;
                            }

                            .dot {
                                left: 17px;
                                right: unset;
                            }

                            .media {
                                .dotted-animation {
                                    left: -3px;
                                    right: unset;

                                    .main-circle {
                                        left: 6px;
                                        right: unset;
                                    }

                                    .animate-circle {
                                        left: -4px;
                                        right: unset;
                                    }
                                }
                            }

                            &.onhover-dropdown {
                                &:hover {
                                    .onhover-show-div {
                                        &:before {
                                            right: 10px;
                                            left: unset;
                                        }

                                        &:after {
                                            right: 10px;
                                            left: unset;
                                        }
                                    }
                                }

                                &:last-child {
                                    padding-left: 0;
                                    padding-right: 20px;
                                    border-right: none;
                                }
                            }

                            &:first-child {
                                border-right: none;

                                .search-form {
                                    .form-group {
                                        &:after {
                                            right: 22px;
                                            left: unset;
                                        }

                                        &:before {
                                            right: 51px;
                                            left: unset;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                padding-right: 20px;
                                padding-left: 0;
                            }

                            i {
                                &.ml-2 {
                                    margin-right: 0.5rem !important;
                                    margin-left: unset !important;
                                }
                            }
                        }
                    }

                    .language-dropdown {
                        text-align: right;

                        li {
                            a {
                                i {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .profile-dropdown {
                        li {
                            a {
                                svg {
                                    margin-right: unset;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }

                .mobile-sidebar {
                    padding-right: 20px;
                }
            }

            &.open {
                margin-right: 0;
            }
        }

        ul {
            &.notification-dropdown {
                &.onhover-show-div {
                    right: initial;
                    left: -30px;
                    padding: 0;

                    &:before {
                        right: inherit !important;
                        left: 35px !important;
                    }

                    &:after {
                        right: inherit !important;
                        left: 35px !important;
                    }

                    li {
                        margin-right: 0 !important;

                        span {
                            &.badge {
                                margin-left: unset !important;
                            }

                            svg {
                                margin-right: unset;
                                margin-left: 10px;
                            }
                        }

                        .notification-icon {
                            margin-left: 20px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }

        // default page
        .media {
            .media-body {
                text-align: right;
            }
        }

        .order-graph {
            .order-graph-bottom {
                h6 {
                    margin-right: 15px !important;
                    margin-left: 30px;
                }
            }
        }

        .latest-order-table {
            .btn {
                float: right;
            }
        }

        // product
        .product-physical {
            table {
                tr {

                    th,
                    td {
                        &:nth-child(2) {
                            text-align: right;
                        }
                    }
                }
            }
        }

        .product-page-details {
            .shipping-info {
                padding-left: unset;
                padding-right: 20px;
            }

            // .buy-box {
            //     a {
            //         color: #ddd;
            //     }
            // }

            .size-delivery-info {
                a {
                    i {
                        margin-right: unset;
                        margin-left: 10px;
                    }
                }
            }

            .price-text {
                .discounted-price {
                    margin-left: unset;
                    margin-right: 12px;
                }

                // span,
                // del {
                //     color: #ddd;
                // }
            }
        }

        .product-page-details,
        .product-right {

            .color-variant,
            .size-box {
                text-align: right;
            }
        }

        .add-product-form {
            .qty-box {
                .input-group {
                    .input-group-append {
                        .btn {
                            border-right: none !important;
                            border-left: 1px solid $round-border !important;
                        }
                    }

                    .btn-primary {
                        &.bootstrap-touchspin-down {
                            border-left: none !important;
                            border-right: 1px solid $round-border !important;
                        }
                    }
                }
            }

            .offset-sm-4 {
                text-align: right;
            }
        }

        // .timer {
        //     ~.m-t-15 {
        //         clear: both;
        //         text-align: right;
        //         padding-top: 15px;
        //     }

        //     span {
        //         .padding-l {
        //             right: 35px;
        //             left: unset;
        //             padding-right: 0;
        //         }
        //     }
        // }

        .product-list,
        .report-table {
            table {
                tr {

                    td,
                    th {
                        text-align: right !important;

                        &:last-child {
                            text-align: center !important;
                        }
                    }
                }
            }
        }

        .dropzone {
            .dz-preview {

                .dz-error-mark,
                .dz-success-mark {
                    left: unset;
                    right: 48%;
                    margin-right: -27px;
                    margin-left: unset;
                }
            }

            &.dz-clickable {
                .dz-message {
                    * {
                        text-align: center;
                    }
                }
            }
        }

        .digital-add {
            .col-form-label {
                display: block;
                text-align: right;
            }
        }

        // datatables
        .dataTables_wrapper {
            .dataTables_filter {
                input[type="search"] {
                    margin-right: 10px;
                    margin-left: unset;
                }
            }

            table {
                &.dataTable {
                    .vendor-list {
                        img {
                            margin-left: 20px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }

        .category-table {
            table {
                tr {

                    td,
                    th {
                        &:nth-child(3) {
                            text-align: right;
                        }
                    }
                }
            }
        }

        .media-table {
            table {
                tr {

                    td,
                    th {
                        &:last-child {
                            text-align: right !important;
                        }
                    }
                }
            }
        }

        // profile page
        .profile-details {
            h5 {
                text-align: center;
            }
        }

        .account-setting {
            text-align: right;
        }

        @media only screen and (max-width: 991px) {
            .page-wrapper {
                .page-main-header {
                    margin-right: 0;
                }

                .page-body-wrapper {
                    .page-sidebar {

                        ~.page-body,
                        ~footer {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 767px) {
            .needs-validation {
                .form-group {
                    .checkbox {
                        padding-right: 15px;
                        padding-left: unset;
                    }
                }
            }

            .page-wrapper {
                .page-body-wrapper {
                    footer {
                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }

        @media (min-width: 1200px) {
            .offset-xl-3 {
                margin-right: 25%;
                margin-left: unset;
            }
        }
    }
}

@media (min-width: 576px) {
    .rtl {
        .me-sm-3 {
            margin-left: 1rem !important;
            margin-right: 0 !important;
        }
    }
}

@media (min-width: 768px) {
    .rtl {
        .offset-md-2 {
            margin-left: 0;
            margin-right: 16.66667%;
        }
    }
}

@media (min-width: 992px) {
    .rtl {
        .offset-lg-2 {
            margin-right: 16.66667%;
            margin-left: unset;
        }

        .offset-lg-4 {
            margin-left: unset;
            margin-right: 33.3333333333%;
        }

        .offset-lg-3 {
            margin-right: 25%;
            margin-left: 0;
        }

        .service_slide {
            .service-home {
                .offset-lg-2 {
                    margin-right: 16.6666666667%;
                    margin-left: 0;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .rtl {
        .offset-xl-1 {
            margin-right: 8.33333%;
            margin-left: 0;
        }

        header {
            .main-navbar {
                .nav-menu {
                    >li {
                        .nav-submenu {
                            li {
                                a {
                                    &:before {
                                        right: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .sm-vertical {
            a {
                text-align: right;
            }
        }

        .layout3-menu {
            .pixelstrap {
                >li {

                    >a,
                    >a:hover,
                    >a:focus {
                        padding-left: 28px;
                    }
                }

                .sub-arrow {
                    left: -6px;
                }

                >li {
                    ul {
                        a {
                            padding-right: 35px;
                            padding-left: 35px;
                        }
                    }
                }

                .full-mega-menu {
                    li {
                        a {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        .sm-horizontal {
            >li {
                float: right;

                >a,
                >a:hover,
                >a:active {
                    padding-left: 45px;
                    padding-right: 0;
                }
            }
        }

        .pixelstrap {
            ul {
                a {
                    &:before {
                        right: 35px;
                    }
                }
            }

            .full-mega-menu {
                .mega-box {
                    text-align: right;
                }
            }

            .mega {
                ul {
                    margin-left: auto !important;
                }
            }

            &.sm-vertical {
                .mega-menu {
                    ul {
                        a {
                            &:before {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }

        .center-margin {
            margin-left: -30px;
            margin-right: 0;
        }
    }
}

@media (min-width: 1367px) {
    .rtl {
        .layout3-menu {
            .pixelstrap {
                >li {

                    >a,
                    >a:hover,
                    >a:focus {
                        padding-left: 34px;
                    }
                }

                .sub-arrow {
                    left: 0px;
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    .offset-xxl-1 {
        margin-left: 0;
        margin-right: 8.33%;
    }
}

@media (max-width: 1430px) and (min-width: 1200px) {
    .rtl {
        .layout3-menu {
            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 40px 0 40px 35px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1430px) {
    .rtl {
        .left-sidebar_space {
            padding-right: 0;
        }

        header {
            &.left-header {
                .top-header {
                    padding-right: 0;
                }

                .sidenav {
                    left: unset;
                    right: -300px;

                    &.open-side {
                        right: 0;
                    }
                }

                &.left-header-sm {
                    .sidenav {
                        right: 0;
                    }
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .product-filter-content {
                    .collection-view {
                        border-right: 1px solid $round-border;
                        border-left: none;
                        padding-right: 20px !important;
                        padding-left: 0 !important;
                        text-align: right;
                    }

                    .search-count {
                        border-right: 1px solid $round-border;
                    }

                    .product-page-filter {
                        border-left: 1px solid $round-border;
                        border-right: none;
                    }
                }

                .product-filter-content,
                .popup-filter {
                    .collection-grid-view {
                        ul {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }

        .product-right {
            .product-icon {
                .product-social {
                    li {
                        padding-left: 10px;
                    }
                }
            }
        }

        .product-form-box {
            .timer {
                padding-right: 17px;
            }
        }

        .cart-section {
            tfoot {
                tr {
                    td {
                        padding-left: 41px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .rtl {
        .theme-card {
            .offer-slider {
                img {
                    padding: 8px;
                }
            }
        }
    }
}

@media (max-width: 1367px) {
    .rtl {
        .timer {
            span {
                .padding-l {
                    padding-right: 5px;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .rtl {
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 30px 0 30px 25px;
                }
            }
        }

        .pixelstrap {
            li {
                ul {
                    li {
                        a {
                            &:hover {
                                color: var(--theme-color);
                            }
                        }
                    }
                }

                >ul {
                    >li {
                        >ul {
                            margin-right: 27px;
                            margin-left: unset;

                            >li {
                                a {
                                    padding-right: 13px;
                                    padding-left: unset;
                                    margin-right: 11px;
                                    margin-left: unset;

                                    &::before {
                                        left: unset;
                                        right: -4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .link-section {
                .menu-title {
                    .according-menu {
                        &:before {
                            left: -18px;
                            right: unset;
                        }
                    }

                    &.active {
                        .according-menu {
                            &:before {
                                left: -18px;
                                right: unset;
                            }
                        }
                    }
                }

                .menu-content {
                    a {
                        padding-right: 13px;
                        padding-left: unset;
                        margin-right: 11px;
                        margin-left: unset;

                        &::before {
                            left: unset;
                            right: -4px;
                        }
                    }
                }
            }

            li {
                a {
                    text-align: right;
                }

                .lable-nav {
                    right: 15px;
                    left: unset;
                }
            }

            .full-mega-menu {
                .mega-box {
                    text-align: right;
                }
            }

            .clothing-menu {
                padding: 0 18px 0 30px !important;

                .link-section {
                    >ul {
                        >li {
                            >a {
                                margin-left: unset;
                                margin-right: 11px;
                                padding-left: unset !important;
                                padding-right: 13px !important;

                                &:before {
                                    left: unset;
                                    right: -4px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .sidenav {
            &.marketplace-sidebar {
                right: -300px;

                &.open-side {
                    right: 0;
                }
            }

            .pixelstrap {
                li {
                    >ul {
                        >li {
                            >a {
                                padding-right: 13px !important;
                                padding-left: 35px !important;
                                margin-right: 11px;
                                margin-left: unset;
                            }

                            >ul {
                                margin-left: unset;
                                margin-right: 14px;

                                >li {
                                    a {
                                        &::before {
                                            left: unset;
                                            right: -4px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .space_sm {
            padding-right: 0;
        }

        header {
            .main-navbar {
                .nav-menu {
                    li {
                        text-align: right;
                    }

                    >li {
                        a {
                            .sub-arrow {
                                left: 0;
                                right: unset;
                            }
                        }

                        .nav-submenu {
                            li {
                                .nav-sub-childmenu {
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.left-header {
                .sidenav {
                    .pixelstrap {
                        li {
                            .nav-submenu {
                                margin-right: 0;

                                li {
                                    .nav-sub-childmenu {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                &.left-header-relative {
                    .sidenav {
                        .left-sidebar_center {
                            padding: 0 0 25px 25px;
                        }
                    }
                }

                &.left-header-sm {
                    .sidenav {
                        right: -300px;

                        .left-sidebar_center {
                            padding: 0;

                            .pixelstrap {
                                >li {
                                    >a {
                                        img {
                                            margin-left: 12px !important;
                                            margin-right: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.open-side {
                        .sidenav {
                            right: 0;
                        }
                    }
                }
            }

            &.video-header {
                #main-nav {
                    right: unset;
                    left: 0;
                }

                .main-menu {
                    .menu-right {
                        .icon-nav {
                            padding-left: 40px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .sidenav {
            .sidebar-menu {
                li {
                    direction: rtl;
                    text-align: right;

                    a {
                        .sub-arrow {
                            right: auto;
                            left: 15px;
                        }
                    }
                }
            }
        }

        .service-block {
            +.service-block {
                border: none;
            }
        }

        .main-nav-center {
            .toggle-nav {
                left: 175px;
                right: unset;
            }
        }

        .product-right {
            .product-icon {
                .product-social {
                    li {
                        padding-left: 4px;
                    }
                }

                .wishlist-btn {
                    span {
                        padding-right: 4px;
                    }
                }
            }
        }

        .cart-section {
            tfoot {
                tr {
                    td {
                        padding-left: 25px;
                    }
                }
            }
        }

        .full-banner {
            &.feature-banner {

                .feature-object,
                .feature-object-right {
                    li {
                        margin-left: 0;
                    }
                }
            }
        }

        .add_to_cart {

            &.top,
            &.bottom {
                .cart_media {
                    .cart_product {
                        li {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .cart-section,
        .wishlist-section {
            .cart-buttons {
                >div {
                    &:last-child {
                        padding-left: 17px;
                    }
                }
            }
        }

        .tab-border {
            .nav-border {
                border-left: none;
            }
        }

        .collection-product-wrapper {
            .product-filter-content {
                .collection-view {
                    padding-right: 20px !important;
                }
            }
        }

        .footer-social {
            li {
                padding-right: 0;
                padding-left: 38px;
            }
        }

        .footer-theme2 {
            .social-white {
                li {
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }
        }

        .tab-left {
            .theme-tab {
                .left-side {
                    .tab-title {
                        text-align: center;
                        margin-left: 0;
                    }
                }
            }
        }

        .product-form-box {
            &.product-right {
                .timer {
                    padding-left: 0;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .rtl {
        .sidebar-shop-footer {
            margin-right: 0;
        }

        .footer-light {
            .subscribe {
                border: none;
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .product-filter-content {
                    .product-page-per-view {
                        select {
                            border-right: 1px solid #dddddd !important;
                        }
                    }
                }
            }
        }

        .filter-main-btn {
            text-align: right;
        }

        .product-right {
            text-align: center;

            .size-text {
                text-align: right;
            }

            .timer {
                text-align: right;
            }
        }

        .product-right {
            .product-icon {
                .product-social {
                    li {
                        padding-left: 20px;
                    }
                }
            }
        }

        .border-product {
            text-align: center;
        }

        .faq-tab {
            text-align: right;

            .nav-tabs {
                .nav-item {
                    .nav-link {

                        &.active,
                        &:hover {
                            border-left: none;
                        }
                    }
                }
            }
        }

        .vendor-profile {
            .profile-left {

                .profile-detail,
                .vendor-contact {
                    margin-right: 0;
                    padding-right: 0;
                    border-right: none;
                }

                .profile-detail {
                    text-align: center;
                }
            }
        }

        .cart-section {
            tfoot {
                tr {
                    td {
                        padding-left: 0;
                    }
                }
            }
        }

        .contact-page {
            .contact-right {
                ul {
                    li {
                        padding-right: 0;

                        .contact-icon {
                            border-left: none;
                        }
                    }
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .popup-filter {
                    .sidebar-popup {
                        text-align: center;
                        background: none var(--theme-color);
                        border: none;
                    }
                }
            }
        }

        .testimonial {
            .testimonial-slider {
                .media {
                    .media-body {
                        padding: 0 30px;
                    }
                }

                .slick-track {
                    .slick-slide {
                        &:nth-child(even) {
                            .media {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .beauty-about {
            .about-text {
                p {
                    text-align: center;
                }
            }
        }

        .full-banner {
            &.feature-banner {

                .feature-object,
                .feature-object-right {
                    li {
                        &:nth-child(2) {
                            padding-right: 0;
                            padding-left: 0;
                        }

                        &:nth-child(3) {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }

                .feature-object-right {
                    margin-right: 0;
                }
            }
        }

        footer {
            &.footer-black {
                &.footer-light {
                    .subscribe {
                        border-left: none;
                    }
                }
            }
        }

        .product-left-title {
            &.right-content {
                text-align: right;
            }
        }

        .shop-sidebar-demo {
            .shop-sidebar {
                left: unset;
                right: -300px;

                &.show {
                    right: 0;
                }
            }

            .shop-main {
                margin-right: 0;
            }
        }

        .theme-card {
            .offer-slider {
                img {
                    padding: 10px 0 10px 10px;
                }
            }
        }

        .collection-filter {
            right: -350px;
            left: unset;

            &.show {
                left: unset;
                right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .rtl {
        header {
            &.left-header {
                .main-menu {
                    .menu-right {
                        .icon-nav {
                            li {
                                padding-left: 0;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
        }

        .footer-title {
            h4 {
                text-align: right;
            }

            .according-menu {
                &:before {
                    left: 2px;
                    right: unset;
                }
            }

            &.active {
                .according-menu {
                    &:before {
                        left: 2px;
                        right: unset;
                    }
                }
            }
        }

        .footer-content {
            text-align: right;
        }

        .footer-theme {
            .sub-title {
                li {
                    text-align: right;
                }

                .contact-list {
                    li {
                        padding-right: 0;
                    }

                    i {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
        }

        .service-block {
            .media {
                .media-body {
                    text-align: center;
                }
            }

            +.service-block {
                border: none;
            }
        }

        .footer-theme2 {
            .contact-details {
                li {
                    text-align: right;
                }
            }
        }

        .social-white {
            li {
                &:first-child {
                    padding-right: 0;
                }
            }
        }

        .product-box,
        .product-wrap {
            .cart-detail {
                left: 10px;
                right: unset;
            }
        }

        .footer-theme2 {

            .footer-link,
            .footer-link-b {
                li {
                    padding-left: 0;
                    padding-right: 20px;
                }
            }
        }

        .layout2-logo {
            padding-left: 70px;
            padding-right: 0;
        }

        .testimonial {
            .testimonial-slider {
                .media {
                    .media-body {
                        padding: 30px 30px 30px 30px;
                    }
                }
            }
        }

        .footer-social {
            li {
                padding: 0 15px;
            }
        }

        .service-block {
            svg {
                margin-left: 0;
                margin-bottom: 20px;
            }
        }

        .service-style-border {
            .service-block {
                .media-body {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }

        .tracking-page {
            .wrapper {
                .arrow-steps {
                    .step {
                        &:after {
                            right: unset;
                            left: 50%;
                            border-right: 17px solid $top-header;
                            transform: rotate(-90deg) translateY(-50%);
                        }
                    }
                }
            }
        }

        .sticky-bottom-cart {
            .selection-section {
                .form-group {
                    &:nth-child(2) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 577px) {
    .rtl {
        header {
            &.left-header {
                .main-menu {
                    .menu-left {
                        .mobile-logo {
                            left: unset;
                            right: 15px;
                        }

                        .navbar {
                            right: unset;
                            left: 15px;
                        }
                    }
                }
            }

            &.header-5 {
                .main-menu {
                    .brand-logo {
                        left: unset;
                        right: 0;
                    }
                }

                &.left-sidebar-header {
                    .main-menu {
                        .menu-left {
                            .navbar {
                                left: 0px;
                                right: unset;
                            }
                        }
                    }
                }
            }

            &.header-6 {
                .main-menu {
                    .brand-logo {
                        margin-right: 0;
                    }
                }
            }

            &.header-style {
                .brand-logo {
                    right: 0;
                    left: unset;
                }
            }

            &.header-christmas {
                .main-menu {
                    .brand-logo {
                        margin-right: 0;
                    }
                }
            }

            &.header-gym {
                .main-menu {
                    .brand-logo {
                        left: unset;
                        right: 0;
                        margin: 0;
                    }
                }
            }

            &.left-header {
                .main-menu {
                    .menu-right {
                        .icon-nav {
                            li {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .header-style-7 {
            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 25px 0 25px 15px !important;
                    }
                }

                .menu-right {
                    .icon-nav {
                        li {
                            padding-right: 10px;
                        }

                        .onhover-div {
                            .show-div {
                                &.shopping-cart {
                                    right: unset;
                                    left: 0 !important;
                                }
                            }
                        }

                        .mobile-setting {
                            .setting {
                                right: unset;
                                left: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        .top-header {
            .header-dropdown {

                .mobile-account,
                .mobile-wishlist {
                    padding: 0;
                }

                >li {
                    &:nth-child(2) {
                        padding: 0;
                    }
                }

                li {
                    padding: 0 !important;
                }
            }
        }

        .main-menu {
            .menu-left {
                .navbar {
                    padding: 30px 0 20px 45px !important;
                }
            }

            .menu-right {
                .icon-nav {
                    li {
                        padding-right: 0;
                    }

                    .mobile-cart {
                        right: unset;
                        left: 48%;
                    }
                }
            }
        }

        .main-nav-center {
            .toggle-nav {
                right: unset;
                left: 15px;
            }
        }

        .layout2-logo {
            padding-left: 0;
        }

        .layout3-menu {
            .main-menu {
                .menu-left {
                    .menu-right {
                        .toggle-nav {
                            left: 15px;
                            right: unset;
                        }
                    }

                    .main-menu-right {
                        .toggle-nav {
                            top: 3px;
                            left: 0;
                            right: unset;
                        }
                    }
                }
            }
        }

        .collection-product-wrapper {
            .product-top-filter {
                .product-filter-content {
                    .product-page-per-view {
                        border-right: none !important;
                    }

                    .product-page-filter {
                        border-right: 1px solid #dddddd !important;
                    }
                }
            }
        }

        .blog-detail-page {
            .blog-detail {
                .post-social {
                    li {
                        +li {
                            padding-right: 0;
                            margin-right: 0;
                            border-right: none;
                        }
                    }
                }
            }
        }

        .full-banner {
            &.feature-banner {
                .feature-object {
                    text-align: right;

                    li {
                        .media {
                            img {
                                margin-left: 15px;
                                margin-right: 0;
                            }

                            .media-body {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        footer {
            .subscribe-form {
                &.rounded-input {
                    .form-control {
                        border-radius: 20px;
                    }

                    .btn-solid {
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .rtl {
        .testimonial {
            .testimonial-slider {
                .media {
                    .media-body {
                        text-align: center;
                        padding: 6px;
                    }
                }
            }
        }

        .review-page {
            .comment-section {
                .media {
                    text-align: center;
                }
            }
        }

        .blog-detail-page {
            .comment-section {
                li {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }

        .blog-page {
            .blog-media {
                .blog-right {
                    ul {
                        li {
                            +li {
                                padding-right: 0;
                                margin-right: 0;
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }

        .cart_counter {
            .cart_checkout {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 420px) {
    .rtl {
        .full-box {
            .center-slider {
                .offer-slider {
                    .product-box {
                        direction: rtl;

                        .img-wrapper {
                            img {
                                padding: 0 0 0 15px;
                            }
                        }
                    }
                }
            }
        }

        .product-right {
            .product-icon {
                .product-social {
                    li {
                        padding-left: 5px;
                    }
                }
            }
        }

        .timer {
            span {
                .padding-l {
                    padding-right: 5px;
                }
            }
        }

        .order-box {
            .sub-total {
                .shipping {
                    .shopping-option {
                        &:last-child {
                            padding-left: 0;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }

        .center-slider {
            .offer-slider {
                .product-box {
                    .product-detail {
                        text-align: right !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .rtl {
        .timer {
            span {
                .padding-l {
                    padding-right: 10px;
                }
            }
        }
    }
}
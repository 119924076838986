/**=====================
    tap to top css start
==========================**/

.tap-top {
    width: 42px;
    height: 42px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 1;
    display: none;
    color: $white;
    text-align: center;
    background: var(--theme-color);
    border: none;
    font-size: 22px;
    padding: 4px;
    cursor: pointer;

    &.top-cls {
        bottom: 70px;
    }

    &.green-top {
        background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2));
        border: 1px solid rgba($white, 0.5);
    }

    &.gradient-top {
        background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 99%);
    }
}
/**=====================
  Form css
==========================**/
input[type="tel"i] {
  direction: unset;
}

.custom-check-box {
  display: flex;
  align-items: center;
  gap: 10px;

  .form-check-label {
    margin: 0;
  }
}

.checkbox_animated {
  margin: 0;
  width: 20px;
  height: 20px;
  border: unset;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0);
    left: 4px;
    top: 5px;
    z-index: 1;
    border: 2px solid var(--theme-color);
    border-top-style: none;
    border-right-style: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border: 2px solid #ececec;
    cursor: pointer;
  }

  &:checked {
    &::before {
      transform: rotate(-45deg) scale(1);
    }
  }
}

textarea.form-control {
  border-color: $white-dark;
}

.form-box {
  position: relative;

  .form-label,
  label {
    font-weight: 500;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 4px;
    color: #777;
  }

  .form-control {
    height: auto;
    padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border-radius: 0;
    border: 1px solid $white-dark;
    font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1920 - 320)));

    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  .custom-select {
    .select2 {
      &.select2-container--focus {
        .select2-focused {
          .select2-selection--single {
            border: 1px solid $white-dark;
          }
        }
      }
    }

    .select2-selection {
      &.select2-selection--single {
        border: 1px solid $white-dark;
        padding: calc(10px + 2 * (100vw - 320px) / 1600) calc(13px + 6 * (100vw - 320px) / 1600);
        height: auto;
        background-color: $white;
        border-radius: 0;

        .select2-selection__rendered {
          padding: 0 !important;
          line-height: 1.6;
        }

        .select2-selection__placeholder {
          font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
          color: #777;
          font-weight: 500;
        }

        .select2-selection__arrow {
          &::before {
            content: "\ea4e";
            @include font;
            border: unset;
            width: auto;
            height: auto;
            color: #777;
            font-size: 17px;
          }
        }
      }
    }

    .select2-dropdown {
      margin-top: -1px;
      border: 1px solid $white-dark;
      border-radius: 0;

      .select2-search {
        .select2-search__field {
          border: 1px solid $white-dark;
        }
      }
    }

    .select2-results {
      .select2-results__options {
        &::-webkit-scrollbar-track {
          background-color: rgba($color: var(--theme-color), $alpha: 0.14);
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--theme-color);
        }

        .select2-results__option {
          background-color: $white;
          display: block;

          .country {
            .dial-code {
              color: #777;
              font-weight: 500;
            }
          }

          &--highlighted {
            background-color: var(--theme-color);
            font-weight: 600;

            .country {
              .dial-code {
                color: $white-dark;
              }
            }
          }
        }
      }
    }
  }
}

.phone-field {
  .form-box {
    position: relative;

    .form-control {
      padding-left: calc(111px + (132 - 111) * ((100vw - 320px) / (1920 - 320)));
    }

    .form-label {
      ~.custom-select {
        top: 29px;
        left: 1px;
      }
    }
  }

  .custom-select {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(42px + (48 - 42) * ((100vw - 320px) / (1920 - 320)));
    width: calc(100px + (120 - 100) * ((100vw - 320px) / (1920 - 320)));

    .select2 {
      height: 100%;

      .selection {
        height: calc(100% - 2px);
        border-right: 1px solid $white-dark;

        .select2-selection {
          height: 100%;
          border: unset;
          border-radius: 0;
          gap: 5px;
          background-color: transparent;
          align-items: center;

          &.select2-selection--single {
            border: unset;
          }

          .select2-selection__rendered {
            display: flex !important;
            line-height: 1 !important;
            padding: 0;
            align-items: center !important;
          }

          .select2-selection__arrow {
            width: auto !important;

            &::before {
              content: "\ea4e" !important;
              @include font;
              border: unset !important;
              width: auto !important;
              height: auto !important;
              color: #777;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
